import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TextInput,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  aboutAuthor,
  pageContent,
  input,
  Colors,
  bannerStyles,
  Messages,
  FontFamily,
  FontWeight,
  button,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import TypeWriter from 'react-native-typewriter'

// const banner = getImagePath('img/banner-slide.png');
const bannerDark = getImagePath("img/banner-slide-dark.png");
const circleLeft = getImagePath("img/banner-left-circle.png");
const circleRight = getImagePath("img/banner-right-circle.png");
const circleRightDark = getImagePath("img/banner-right-circle-dark.png");

const bannerTextArr = ["Citizens", "Students", "Researchers", "Indians", "Academicians"];

export class HomeBanner extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      btnDisable: true,
      email: "",
      referralMsg: {
        enable: false,
        type: "", // error / success
        msg: "",
      },
      token: token == undefined || token == "" ? false : token,
      bannerTextIdx: 0,
      bannerTextDirection: 1
    };
  }

  componentDidMount = () => {};

  signUp = () => {
    const { email } = this.state;
    this.setState({
      btnLoader: true,
      referralMsg: {
        enable: false,
        type: "",
        msg: "",
      },
    });

    // if (helpers.validateEmail(email)) {
    //     this.setState({
    //         btnLoader: false
    //     });
    //     try {
    //         if (this.props.trackSignUp) {
    //             this.props.trackSignUp(email, 'top banner');
    //         }
    //     }
    //     catch (error) {
    //         console.log("mix panel track error ==>", error);
    //     }
    if (helpers.isWeb()) {
      this.props.navigateWeb(`/sign-up?redirect=/&email=${email}`);
    } else {
      this.props.navigateApp("/sign-up", {
        redirect: "/",
        verifyRedirect: "/",
        email: email,
      });
    }
    // }
    // else {
    //     this.setState({
    //         btnLoader: false,
    //         referralMsg: {
    //             enable: true,
    //             type: 'error',
    //             msg: 'Please enter a valid email'
    //         }
    //     });
    // }
  };

  pushSubscribeEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "subscribe_initiate",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      header_title: "our journalism",
      banner_name: "the morning context makes you smarter with original, accurate and independent journalism",
      CTA_text: "subscribe",
      CTA_position: "middle",
      scroll_depth: helpers.getScrollDepth(),
      category_type: "NA",
    });
  }

  goToPricing = () => {
    try {
      this.pushSubscribeEventToDataLayer();
      console.log("Click Subscribe");
      window.webengage.track("Click Subscribe", {
        "Type of User": this.props.token ? "Free" : "non-logged in",
        "Component Name": "HomeBanner",
      });
      console.log({
        "Type of User": this.props.token ? "Free" : "non-logged in",
        "Component Name": "HomeBanner",
      });
    } catch (error) {}
    this.props.navigateWeb(`/pricing?header=true`);
  };

  validateInput = (value) => {
    if (value) {
      this.setState({ btnDisable: false, email: value });
    } else {
      this.setState({ email: value, btnDisable: true });
    }
  };

  handleBannerAnimation = () => {
    if(this.state.bannerTextDirection === 1){
      this.setState({bannerTextDirection: 0})
      setTimeout(()=>this.setState({bannerTextDirection: -1}),1000)
    }else if(this.state.bannerTextDirection === -1){
      this.setState({bannerTextDirection: 1, bannerTextIdx: (this.state.bannerTextIdx + 1) % 5})
    }
  }

  render() {
    let { email, btnDisable, referralMsg, token } = this.state;
    let webView = helpers.isWeb();
    let { darkMode, textColor } = this.props;

    const banner = helpers.isDesktop()
      ? require("../../../../assets/img/banner-new.png")
      : require("../../../../assets/img/banner-mobile.png");
    let signInProps;
    if (webView) {
      signInProps = {
        to: "/sign-in?redirect=/",
      };
    } else {
      signInProps = {
        onPress: () =>
          this.props.navigateApp("/sign-in", {
            redirect: "/",
            verifyRedirect: "/",
          }),
      };
    }

    return (
      <View
        style={{
          background: "linear-gradient(90.97deg, #6C7AF7 0%, #A97DF1 99.9%)",
        }}
      >
        <CustomImage
          source={darkMode ? bannerDark : banner}
          require={true}
          style={[
            Utility.banner,
            { width: "100%", height: helpers.isDesktop() ? 376 : 400 },
          ]}
          webStyle={{
            width: "100%",
            height: helpers.isDesktop() ? 376 : 270,
            paddingTop: helpers.isDesktop ? 0 : 0,
          }}
          altText={"The Morning Context"}
        />
        <View
          style={[
            {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: helpers.isDesktop ? 0 : 20,
              paddingRight: helpers.isDesktop ? 0 : 20,
            },
            {
              maxWidth: helpers.isDesktop() ? 1216 : "calc(100% - 40px)",
              width: "calc(100% - 40px)",
            },
            !helpers.isDesktop() && {
              width: "100%",
              alignItems: "center",
            },
          ]}
        >
          <View style={Utility.bannerBox}>
            <View style={Utility.bannerTop}>
              <View
                style={{
                  width: helpers.isDesktop()
                    ? "calc((100% / 2) - 120px)"
                    : "calc((335px - 169px) / 2)",
                  height: "1px",
                  background: "#ffffff",
                }}
              ></View>
              <Text
                style={{
                  fontSize: "14px",
                  lineHeight: "17px",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  color: "#ffffff",
                  display: "block",
                  textAlign: "center",
                  width: "100%",
                  fontFamily: FontFamily.playfairDisplayB,
                }}
              >
                our journalism
              </Text>
              <View
                style={{
                  width: helpers.isDesktop()
                    ? "calc((100% / 2) - 120px)"
                    : "calc((335px - 169px) / 2)",
                  height: "1px",
                  background: "#ffffff",
                }}
              ></View>
            </View>
            <View
              style={{
                paddingHorizontal: helpers.isDesktop() ? 25 : 16,
              }}
            >
              <Text
                style={[
                  Utility.heading,
                  {
                    fontSize: helpers.isDesktop() ? 30 : 18,
                    fontFamily: "PlayfairDisplay-Bold",
                    marginBottom: 24,
                    lineHeight: "150%",
                  },
                  helpers.isWeb() && { fontWeight: 900 },
                  !helpers.isWeb() && {
                    fontFamily: "PlayfairDisplay-Bold",
                  },
                ]}
              >
                <View style={{ width: helpers.isDesktop() ? 350 : 300}}>
                  <Text style={{color: 'white', textAlign: helpers.isDesktop() ? 'right' : 'center'}}>Global <TypeWriter onTypingEnd={this.handleBannerAnimation} typing={this.state.bannerTextDirection}>
                    {bannerTextArr[this.state.bannerTextIdx]}
                  </TypeWriter> </Text>
                  </View>
                  {' '}trust The Morning Context’s journalism on 
                 technology, business and current affairs to be on top of India
                {/* Reported stories, analysis and comment from The Morning Context, a daily business publication for smart people who value their time. */}
                {/* The Morning Context makes you smarter with original, accurate
                and independent journalism. */}
              </Text>
            </View>
            {this.props.freeUser || !token ? (
              <View
                style={{
                  maxWidth: helpers.isDesktop() ? 155 : 128,
                  display: Flex,
                  alignSelf: "center",
                }}
              >
                <ButtonGradient
                  title={"subscribe"}
                  rootStyle={{
                    // btnWrapper: [{ paddingLeft: helpers.isDesktop() ? 20 : 10, paddingRight: helpers.isDesktop() ? 20 : 10, paddingTop: helpers.isDesktop() ? 20 : 12, paddingBottom: 10, height: helpers.isDesktop() ? 60 : 42 }, darkMode && { backgroundColor: Colors.brandColor1 }, { marginLeft: helpers.isDesktop() ? 0 : 0 }],
                    // btnText: [bannerStyles.text, Helpers.textUppercase, { fontWeight: '600', letterSpacing: 0.3, fontSize: helpers.isDesktop() ? 18 : 16, textAlign: helpers.isDesktop() ? 'left' : 'center', paddingLeft: helpers.isDesktop() ? 25 : 0, paddingRight: helpers.isDesktop() ? 25 : 0, color: 'white' }]
                    btnWrapper: [
                      button.primaryGradient,
                      {
                        borderRadius: 0,
                        height: helpers.isDesktop() ? 48 : 36,
                        width: 150
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 13,
                        fontWeight: helpers.isDesktop() ? 600 : 700,
                        fontFamily: FontFamily.medium,
                        textAlign: "center",
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: helpers.isDesktop() ? 14 : 10,
                        paddingBottom: helpers.isDesktop() ? 14 : 10,
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",
                      },
                    ],
                  }}
                  onClick={this.goToPricing}
                  // disable={btnDisable}
                  textWhite={true}
                  isNewGradiant={true}
                  rounded={true}
                />
              </View>
            ) : null}
          </View>

          {referralMsg.enable && (
            <View style={[{ marginTop: 5 }]}>
              <Text
                style={[
                  referralMsg.type == "success"
                    ? Messages.successText
                    : Messages.errorText,
                  { color: darkMode ? "#F4A886" : "#FF525E" },
                ]}
              >
                {referralMsg.msg}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default HomeBanner;