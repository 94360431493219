import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem,FontFamily,articleLists,FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import AuthenticationWrapper from '../authentication-wrapper';

const viewMore = getImagePath('icons/view-more.png');
const cookies = new Cookies();
const windowWidth = Dimensions.get('window').width;

export class MoreNewsletterPosts extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount = () => {
    }

    viewMoreArticles = () => {
        const { viewAllArticles } = this.props;
        viewAllArticles()
    }

    viewArticle = (catSlug, slug, postData) => {
        const { viewArticle } = this.props;
        viewArticle(catSlug, slug, postData)
    }

    viewAuthor = (slug) => {
        const { viewAuthor } = this.props;
        viewAuthor(slug)
    }
    viewCategory = (slug) => {
        const { viewCategory } = this.props;
        viewCategory(slug)
    }

    trackCateoryClick = (item) => {
        // try {
        //     Mixpanel.track('category', {category_slug: item.category.slug, source_section_type: 'single_post_releted_newsletter_posts', section_value: { title : item.name, slug: item.slug } });
        // }
        // catch(error) {
        //     console.log("mix panel error =>", error);
        // }
    }

    render() {
        const { data, newsletter, viewCategory,darkMode } = this.props;
        let btnClick,btnClickCategory;
        let isWeb = helpers.isWeb();
        if (helpers.isWeb()) {
            btnClick = {
                to: "/newsletters/" + newsletter.slug
            };
         
        } else {
            btnClick = {
                onPress: () => this.props.getNewsletterClick(newsletter.slug)
            }
        }
        return (
            
            <View style={[helpers.isDesktop() && Padding.ph_2,Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && {marginTop: 70 }]}>
                <View style={[article.headerWrapper,  !helpers.isDesktop() && {position: 'absolute'}, !helpers.isDesktop() && {left: 0}, !helpers.isDesktop() && {right: 0} , !helpers.isDesktop() && {top: -15}, { width: '100%' , marginHorizontal: 'auto'}]}>
                    <View style={[Flex.alignCenter, Margin.mb_5, !helpers.isDesktop() && Margin.mb_4, !helpers.isDesktop() && {marginLeft: -20 , marginRight: -20}]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap, !helpers.isDesktop() && {backgroundColor: 'white'}, darkMode && {backgroundColor: Colors.darkBlackColorBg }]}>
                            <View style={[Helpers.borderBottom , darkMode && {borderBottomColor: 'rgba(255, 255, 255, 0.6)'}]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, {fontSize: 18}, {fontFamily:FontFamily.abrilFatface} ,{fontSize: helpers.isDesktop() ? 30 : 18, color: '#000'}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>More from “{newsletter.name}”</Text>
                            </View>
                            {!helpers.isDesktop() && !darkMode && <View style={{backgroundColor: '#f8f8f8', height: 2, position: 'absolute', top: 13, right: 0 , left: 0, width: '100%', zIndex: -1}}></View>}
                        </View>
                        <View style={[Helpers.titleLine]}></View>
                    </View>                
                </View>
                <View style={[Margin.mt_3, Margin.mb_4]}>
                    { helpers.isDesktop() ?
                    <View style={[Flex.row]}>
                        {data && data.map((item, index) => {
                            if (helpers.isWeb()) {
                                btnClickCategory = {
                                    to: "/category/" + item.category.slug
                                }
                            } else {
                                btnClickCategory={
                                   onPress: () => viewCategory(item.category.slug)
                                }
                            }
                            return (
                                <View style={[articleItem.col3, helpers.isDesktop() && articleItem.col3Desktop, Margin.mr_2]}>
                                    {/* <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)}>
                                        <View>
                                            <Text style={[articleLists.badge, { width: 150, marginBottom: 15, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff' }, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>{item.category.name}</Text>
                                        </View>
                                    </TouchableOpacityLink> */}
                                    <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} data={item} single={index == 0 ? true : false} home={true} darkMode={darkMode} sectionName={`More from ${newsletter.name}`}/>
                                </View>
                            )
                        })}
                    </View>
                    :
                    <ScrollContainerView horizontal={true}   showsHorizontalScrollIndicator={false}>
                        <View style={[Flex.row, Margin.mb_4, !process.browser && {overflow: 'hidden'}]}>
                            {data && data.map((item, index) => {
                                if (helpers.isWeb()) {
                                    btnClickCategory = {
                                        to: "/category/" + item.category.slug
                                    }
                                } else {
                                    btnClickCategory={
                                        onPress: () => viewCategory(item.category.slug)
                                    } 
                                }
                                return (
                                    <View style={[articleItem.col3, helpers.isDesktop() && articleItem.col3Desktop, !helpers.isDesktop() && { marginTop: 50}, !isWeb && {width: windowWidth - 100, marginRight: -20}, (!isWeb && (index != 0 )) && {marginLeft: 40}]}>
                                        {/* <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)}>
                                            <View>
                                                <Text style={[articleLists.badge, { width: 150, marginBottom: 15, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff' }, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>{item.category.name}</Text>
                                            </View>
                                        </TouchableOpacityLink> */}
                                        <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} data={item} single={index == 0 ? true : false} home={true} darkMode={darkMode}  sectionName={`More from ${newsletter.name}`}/>
                                    </View>
                                )
                            })}
                            <TouchableOpacityLink
                                {...btnClick}
                            >
                                <View style={[Flex.column, Flex.alignCenter,{width: 155, position: helpers.isWeb() ? 'absolute' : 'relative' , top: helpers.isWeb() ? 160 : 200, marginLeft: helpers.isWeb() ? -15 : 15}]}>
                                    <CustomImage
                                        source={viewMore}
                                        require={true}
                                        style={{ width: 36, height: 36, margin: 'auto' }}
                                        webStyle={{ width: 36, height: 36, margin: 'auto'}}
                                    />
                                    <Text style={[button.secondaryOutlineText, Margin.mr_0, {fontWeight: '500'}]}>See all</Text>
                                </View>
                            </TouchableOpacityLink>
                        </View>
                    </ScrollContainerView>
                }
                    {helpers.isDesktop() &&
                        <View style={[Flex.alignCenter, Margin.mt_4, { marginTop: helpers.isDesktop() ? 70 : 40 }, helpers.isDesktop() && { marginBottom: 30}]}>
                            <TouchableOpacityLink
                                {...btnClick}
                            >
                                <View style={[Flex.row, Flex.alignCenter]}>
                                    <Text style={[button.secondaryOutline, helpers.isDesktop() && button.secondaryOutlineDesktop ,Margin.mr_0, {fontWeight: FontWeight.light}]}>See all</Text>
                                </View>
                            </TouchableOpacityLink>
                        </View>
                    }
                </View>
            </View>
        );
    }
}

export default AuthenticationWrapper(MoreNewsletterPosts);
