import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  TextInput,
  TouchableOpacityLink,
  StyleSheet,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  FontFamily,
  input,
  activenews,
  bannerStyles,
  FontWeight,
  Widgets,
  pageCover,
  button,
  article,
  Colors,
  newsletter,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import HomeNewsLetters from "../../components/home-news-letters";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../../components/button-gradient";
import ButtonGradientNews from "../../components/button-gradientnews";
import NewsletterListing from "../../components/newsletter-listing";
import RecentNewsletterListing from "../../components/newsletter-listing/index-recent.js";
import ActiveNewsletterListing from "../../components/activenewsletters";
import PastNewsletterListing from "../../components/pastnewsletters";
import PageCover from "../../components/page-cover";
import ContentLoader from "react-native-easy-content-loader";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import WriteForus from "../../components/writeforus";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import PremiumSlidernews from "../../components/home-news-letters/PremiumSlidernews";
import WritersSlider from "../../components/home-news-letters/WritersSlider";
import SignupFormTextComponentWeb from "../../components/sign-up-form-text-component-web";
import SignupFormTextComponentMobile from "../../components/sign-up-form-text-component-mobile";
const cookies = new Cookies();
const newsletters = getImagePath("img/newslettersIcon.png");
const newslettersDark = getImagePath("img/newslettersIcon-darkc.png");
const bannerDark = getImagePath("img/banner-slide-dark.png");
const banner = helpers.isDesktop()
  ? require("../../../../assets/img/newsletter-banner.png")
  : require("../../../../assets/img/newsmob.png");

const checkIcon = getImagePath("icons/check.png");
export class Newsletter extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let newsLetters, showSubscribeBtn, activeNewletters, pastNewletters;
    if (this.props.postData) {
      newsLetters = this.props.postData.newsletters;
      activeNewletters = this.props.postData.newsletters.filter((item) => {
        return item.available !== "Completed";
      });
      pastNewletters = this.props.postData.newsletters.filter((item) => {
        return item.available == "Completed";
      });
      showSubscribeBtn = this.props.postData.showSubscribeBtn;
    }
    this.state = {
      pageLoader: newsLetters ? false : true,
      showErrorModal: false,
      activeNewletters: activeNewletters ? activeNewletters : null,
      pastNewletters: pastNewletters ? pastNewletters : null,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      newsLetters: newsLetters ? newsLetters : null,
      token: token,
      showSubscribeBtn: showSubscribeBtn ? showSubscribeBtn : false,
      readerQuotes: [],
      recentAdditions: [],
      newsletterSubscriptionStatus: [],
      writers: false,
      custom_user_id: false
    };
  }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = () => {
    this.getWriters();
    if (!this.state.newsLetters) {
      // this.getStaticContent()

      Promise.all([this.getStaticContent(), this.getUserInfo()]).then(
        (response) => {
          if (response[0]) {
            let subscription = response[1].subscription;
            let teamSubscription = response[1].team_subscription;
            let newsletters = response[0].newsletters,
              showSubscribeBtn = true;
            let activeNewletters = response[0].newsletters.filter((item) => {
              return item.available !== "Completed";
            });
            let pastNewletters = response[0].newsletters.filter((item) => {
              return item.available == "Completed";
            });
            if (
              (subscription &&
                Object.keys(subscription).length != 0 &&
                subscription.status == "active") ||
              (teamSubscription &&
                Object.keys(teamSubscription).length != 0 &&
                teamSubscription.status == "active")
            ) {
              showSubscribeBtn = false;
            }
            this.setState(
              {
                newsLetters: newsletters,
                pastNewletters: pastNewletters,
                activeNewletters: activeNewletters,
                pageLoader: false,
                showSubscribeBtn,
              },
              () => {
                console.log(this.state.newsLetters, "kil");
              }
            );
          } else {
            this.setState({ pageLoader: false, showErrorModal: true });
          }
        }
      );
    } else {
      this.getStaticContent();
    }
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };
  getNewsletterStories = () => {
    let { token, newsletterSlug, newsLetterDetails } = this.state;

    let endpoint = `/stories`;
    // let exclude_arr = [newsLetterDetails.featured_post.id]
    let body = {
      limit: 10,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: exclude_arr,
      filters: [
        {
          key: "newsletter",
          value: ["include"],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let recentAdditions = res.data.story.filter((item) => {
            return item.newsletter;
          });
          console.log(recentAdditions);
          this.setState(
            {
              recentAdditions: [...recentAdditions],
              pageLoader: false,
            },
            () => {
              // this.getNewsletterStories1();
            }
          );
        } else if (res.code == "no_story_found") {
          // this.setState({
          //     storiesData: null,
          //     storyList: [],
          //     pageLoader: false
          // });
        } else {
          // this.setState({
          //     showErrorModal: true,
          //     pageLoader: false
          // });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  getNewsletterStories1 = () => {
    let { token, newsletterSlug, newsLetterDetails } = this.state;

    let endpoint = `/stories`;
    // let exclude_arr = [newsLetterDetails.featured_post.id]
    let body = {
      limit: 6,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: exclude_arr,
      filters: [
        {
          key: "newsletter",
          value: ["oversize"],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState(
            {
              recentAdditions: [
                res.data.story[0],
                ...this.state.recentAdditions,
              ],
            },
            () => {
              this.getNewsletterStories2();
              console.log(this.state.recentAdditions, "sparsh");
            }
          );
        } else if (res.code == "no_story_found") {
          // this.setState({
          //     storiesData: null,
          //     storyList: [],
          //     pageLoader: false
          // });
        } else {
          // this.setState({
          //     showErrorModal: true,
          //     pageLoader: false
          // });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  getNewsletterStories2 = () => {
    let { token, newsletterSlug, newsLetterDetails } = this.state;

    let endpoint = `/stories`;
    // let exclude_arr = [newsLetterDetails.featured_post.id]
    let body = {
      limit: 6,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: exclude_arr,
      filters: [
        {
          key: "newsletter",
          value: ["things-change"],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState(
            {
              recentAdditions: [
                res.data.story[0],
                ...this.state.recentAdditions,
              ],
            },
            () => {
              console.log(this.state.recentAdditions, "sparsh");
            }
          );
        } else if (res.code == "no_story_found") {
          // this.setState({
          //     storiesData: null,
          //     storyList: [],
          //     pageLoader: false
          // });
        } else {
          // this.setState({
          //     showErrorModal: true,
          //     pageLoader: false
          // });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  getStaticContent = () => {
    return new Promise((resolve, reject) => {
      const { token } = this.state;
      this.setState({ pageLoader: true });
      let endpoint = `/static-content`;
      let body = {
        content: ["newsletters"],
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, false)
        .then((res) => {
          if (res.success && res.code == "content_found") {
            resolve(res.data);

            this.getNewsletterStories();

            this.setState({
              newsLetters: res.data.newsletters,
              pageLoader: false,
            });
          } else {
            // this.setState({ pageLoader: false, showErrorModal: true })
            resolve(false);
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          resolve(false);
          // this.setState({ pageLoader: false, showErrorModal: true })
        });

      body = {
        content: ["readers_quotes"],
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, false)
        .then((res) => {
          if (res.success && res.code == "content_found") {
            this.setState({
              readerQuotes: [
                {
                  text: "The Morning Context offers substance and clarity. By focusing deeply on important stories, they avoid the pitfall of superficiality",
                  by: "Anirban Mahapatra",
                  designation: "Author and science columnist",
                  order: 1,
                },
                {
                  text: "There is enormous scope for quality fact-based long-form journalism in India. The Morning Context makes an excellent contribution to fill that gap.",
                  by: "R. Ramakumar",
                  designation:
                    "Economist and the NABARD Chair professor at Tata Institute of Social Sciences.",
                  order: 2,
                },
              ],
            });
          } else {
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error=>> down", JSON.stringify(error));
        });
    });
  };

  getUserInfo = () => {
    return new Promise((resolve, reject) => {
      const { token } = this.state;
      if (!token) {
        resolve(false);
      } else {
        let endpoint = `/profile`;
        let body = {
          request_group: ["subscription", "newsletters", "profile"],
        };
        apiHelpers
          .apiRequest(endpoint, body, "POST", true, token)
          .then((res) => {
            if (res.success) {
              this.setState({
                newsletterSubscriptionStatus: res.data.newsletters,
                userProfile: res.data
              });
              resolve(res.data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("Error=>>", error);
            resolve(false);
          });
      }
    });
  };

  navigateToNewsletter = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/newsletters/" + slug);
    } else {
      this.props.navigation.navigate("single-newsletter", {
        newsletterSlug: slug,
      });
    }
  };
  signUp = () => {
    const { email } = this.state;
    this.setState({
      btnLoader: true,
      referralMsg: {
        enable: false,
        type: "",
        msg: "",
      },
    });

    // if (helpers.validateEmail(email)) {
    this.setState({
      btnLoader: false,
    });
    try {
      if (this.props.trackSignUp) {
        this.props.trackSignUp(email, "mid banner");
      }
    } catch (error) {
      console.log("mix panel track error ==>", error);
    }
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        `/sign-up?redirect=/free-reads&email=${email}`
      );
    } else {
      this.props.navigateToScreen("/sign-up", {
        redirect: "/free-reads",
        verifyRedirect: "/free-reads",
        email: email,
      });
    }
    // }
    // else {
    //     this.setState({
    //         btnLoader: false,
    //         referralMsg: {
    //             enable: true,
    //             type: 'error',
    //             msg: 'Please enter a valid email'
    //         }
    //     });
    // }
  };
  navigatetoaccount = () => {
    if (this.state.token) {
      // this.props.navigateToScreen("/pricing");
      this.props.history.push("#accounts");
    } else {
      this.signUp();
    }
  };
  segregateWriters = (writerList) => {
    let writers = [],
      contributors = [];
    writerList.map((writer) => {
      if (writer.roles.includes("contributor")) {
        contributors.push(writer);
      } else {
        writers.push(writer);
      }
    });
    return { writers: writers, contributors: contributors };
  };
  getWriters = () => {
    this.setState({
      showErrorModal: false,
      pageLoader: true,
    });
    const { token } = this.state;
    let endpoint = `/authors`;
    let body = {
      sort_on: "name",
      sort_by: "asc",
      filters: [{ key: "roles", value: ["editor"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          console.log("writers data", res.data.author);
          // let writerData = this.sortWriter(res.data.author);
          let allWriters = this.segregateWriters(res.data.author);
          this.setState({
            writers: allWriters["writers"],

            pageLoader: false,
          });
        } else {
          this.setState({
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  goToSignUp = (email) => {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "signup_initiate",
        custom_user_id: "NA",
        user_status: "guest",
        CTA_text: "sign me up",
        header_title: "straight into your inbox",
        CTA_position: "top",
        article_name: "NA",
        signup_method: "NA",
      });
      this.props.navigateToScreen(
        "/sign-in?redirect=/" +
          "newsletters" +
          "&providerHint=tmc-passwordless" +
          (email ? "&emailHint=" + email : "")
      );
  };
  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "straight into your inbox",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "/newsletters" +
        "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "straight into your inbox",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "newsletters" +
        "&providerHint=google"
    );
  };

  renderPageHeader = () => {
    let { showSubscribeBtn } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={{
          marginTop: 0,
        }}
      >
        <CustomImage
          source={darkMode ? bannerDark : banner}
          require={true}
          style={{ width: "100%", height: helpers.isDesktop() ? 376 : 355 }}
          webStyle={{
            width: "100%",
            height: helpers.isDesktop() ? 376 : 370,
            objectFit: "cover",
            objectPosition: helpers.isDesktop() ? "center" : "100%",
          }}
          altText={"The Morning Context"}
        />
        <View
          style={[
            {
              position: "absolute",
              top: "50%",
              left: helpers.isDesktop() ? "14%" : 0,
              transform: "translateY(-50%)",
              zIndex: 2,
              paddingTop: helpers.isDesktop() ? 0 : "30px",
            },
            { maxWidth: helpers.isDesktop() ? 1216 : "100%" },
            !helpers.isDesktop() && {
              width: "100%",
              paddingHorizontal: helpers.isDesktop() ? 16 : 20,
            },
          ]}
        >
          <Text
            style={[
              bannerStyles.heading,
              {
                fontSize: helpers.isDesktop() ? 65 : 32,
                lineHeight: "1",
                marginBottom: 10,
                fontFamily: "PlayfairDisplay-Bold",
              },
              helpers.isWeb() && { fontWeight: "700", color: "#051462" },
              !helpers.isDesktop() && {
                textAlign: "left",
                margin: "auto",
                fontWeight: "bold",
                fontFamily: "PlayfairDisplay-Bold",
              },
            ]}
          >
            Straight into your inbox.
          </Text>
          {/* <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { fontSize: helpers.isDesktop() ? 45 : 20, marginBottom: 10 }, helpers.isWeb() && { fontWeight: FontWeight.bold}, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>Only read stories that matter.</Text> */}
          <Text
            accessibilityRole="header"
            aria-level="1"
            style={[
              bannerStyles.heading,
              {
                fontSize: helpers.isDesktop() ? 27 : 20,
                marginTop: 25,
                top: 5,
              },
              helpers.isDesktop() && {
                fontWeight: FontWeight.bold,
                color: "#051462",
                width: "60%",
              },
              !helpers.isDesktop() && {
                color: "#051462",
                textAlign: "left",
                margin: "auto",
                marginTop: 5,
                fontWeight: 600,
                fontFamily: "Matteo-regular",
              },
            ]}
          >
            A wealth of knowledge from India's top writers curated for you by
            The Morning Context.
          </Text>
          {!this.state.token &&
            (helpers.isDesktop() ? (
              <SignupFormTextComponentWeb
                textInputStyle={styles.textInputStyle}
                textInputStyleTextColor={"#051462"}
                orText={styles.orText}
                goToSignUp={this.goToSignUp}
                goToGoogleLoginWeb={this.goToGoogleLoginWeb}
              />
            ) : (
              <SignupFormTextComponentMobile
                textInputStyle={styles.textInputStylemobile}
                textInputStyleTextColor={"#051462"}
                orText={styles.orTextmobile}
                goToSignUp={this.goToSignUp}
                goToGoogleLoginMobile={this.goToGoogleLoginMobile}
              />
            ))}
          {/* <View style={[Margin.mt_1, Margin.mb_1, { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline' }]}>
                        <Text style={[bannerStyles.text, { fontSize: helpers.isDesktop() ? 18 : 14 }]}>Already have an account? </Text>
                    </View> */}
        </View>
        {/* <CustomImage
                    source={circleLeft}
                    require={true}
                    style={{ width: helpers.isDesktop() ? 150 : 70, height: helpers.isDesktop() ? 190 : 80, position: 'absolute', left: 0, bottom: 0 }}
                    webStyle={{ width: helpers.isDesktop() ? 150 : 70, height: helpers.isDesktop() ? 190 : 80, position: 'absolute', left: 0, bottom: 0 }}
                    altText={'The Morning Context'}
                />
                <CustomImage
                    source={darkMode ? circleRightDark : circleRight}
                    require={true}
                    style={{ width: helpers.isDesktop() ? 150 : 75, height: helpers.isDesktop() ? 240 : 130, position: 'absolute', right: 0, top: '25%' }}
                    webStyle={{ width: helpers.isDesktop() ? 150 : 75, height: helpers.isDesktop() ? 240 : 130, position: 'absolute', right: 0, top: '25%' }}
                    altText={'The Morning Context'}
                /> */}
      </View>
    );
  };

  navigateTo = (screen) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(screen);
    } else {
      this.props.navigation.navigate("/pricing");
    }
  };

  // showPageLoader = () => {
  //     const webView = helpers.isWeb();
  //     return (
  //         <View style={[Flex.justfiyCenter, Flex.alignCenter,
  //         {
  //             position: webView ? 'fixed' : 'absolute',
  //             left: 0,
  //             right: 0,
  //             top: 0,
  //             bottom: 0,
  //             backgroundColor: '#ffffff',
  //         }]}
  //         >
  //             <ActivityIndicator size={25} color={'#000000'} />
  //         </View>
  //     )
  // }

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };
  renderCarousal = () => {
    let { newsLetters } = this.state;

    let data = newsLetters !== null ? newsLetters : [];
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [];
    let data5 = [];
    Array.from(data)
      .slice(0, 2)
      .map((item, index) => {
        data1.push(item);
      });
    Array.from(data)
      .slice(2, 4)
      .map((item, index) => {
        data2.push(item);
      });
    Array.from(data)
      .slice(4, 6)
      .map((item, index) => {
        data3.push(item);
      });
    Array.from(data)
      .slice(6, 8)
      .map((item, index) => {
        data4.push(item);
      });
    Array.from(data)
      .slice(8, 9)
      .map((item, index) => {
        data5.push(item);
        // For replace apostrophe to empty
        // let _d = JSON.stringify(item).replace(/'/g, "");
        // _d = _d.replace(/’/g, "");
        // data5.push(JSON.parse(_d));
      });
    if (this.state.newsLetters !== null && this.state.newsLetters.length) {
      return (
        <PremiumSlidernews
          categorySlug={"newsletter"}
          token={this.state.token}
          data={this.state.newsLetters}
          data1={data1}
          data2={data2}
          data3={data3}
          data4={data4}
          data5={data5}
          viewArticle={(catSlug, slug, postData) =>
            this.viewArticle(catSlug, slug, postData)
          }
          viewAuthor={(slug) => this.viewAuthor(slug)}
          viewCategory={(slug) => this.viewCategory(slug)}
          getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
          home={true}
        />
      );
    } else {
      return <></>;
    }
  };
  renderWriters = () => {
    let { homeContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let data = this.state.writers;
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [],
      data5 = [],
      data6 = [];
    Array.from(data)
      .slice(0, 3)
      .map((item, index) => {
        data1.push(item);
      });
    Array.from(data)
      .slice(3, 6)
      .map((item, index) => {
        data2.push(item);
      });
    Array.from(data)
      .slice(6, 9)
      .map((item, index) => {
        data3.push(item);
      });
    Array.from(data)
      .slice(9, 12)
      .map((item, index) => {
        data4.push(item);
      });
    Array.from(data)
      .slice(12, 15)
      .map((item, index) => {
        data5.push(item);
      });
    Array.from(data)
      .slice(15, 18)
      .map((item, index) => {
        data6.push(item);
      });
    return (
      <WritersSlider
        categorySlug={"writers"}
        data={data}
        data1={data1}
        data2={data2}
        data3={data3}
        data4={data4}
        data5={data5}
        data6={data6}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        getNewsletterClick={(slug) => this.viewAuthor(slug)}
        home={true}
        darkMode={darkMode}
        custom_user_id={this.state.custom_user_id}
      />
    );
  };
  updateSubscription = (value) => {
    let { token } = this.state;
    let endpoint = `/save-newsletter-type`;
    let body = {
      type: value.replace(/\s+/g, "-").toLowerCase(),
      value: true,
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({ res: res });
          this.getUserInfo();
        } else {
          this.setState({ res: res });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  pushThemeClickToDataLayer = (themeName, newsletter_category) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "CTA_theme_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_section: "explore newsletters on",
      newsletter_category: newsletter_category,
      newsletter_section:'Active newsletters',
      author_name:'NA',
      theme_name: themeName.toLowerCase(),
      article_category:'NA',
      scroll_depth: helpers.getScrollDepth(),
    });
  }
  renderPageContent = () => {
    let {
      newsLetters,
      activeNewletters,
      pastNewletters,
      newsletterSubscriptionStatus,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View style={!darkMode && { backgroundColor: "#FAFAFA" }}>
          <View
            style={[
              Helpers.conatinerWeb,
              // helpers.isDesktop() && Helpers.conatinerWeb1200,
              { marginBottom: 40, width: helpers.isDesktop() ? 1000 : "auto" },
            ]}
          >
            <View
              style={[
                Flex.alignCenter,
                helpers.isDesktop() && Margin.mb_7,
                !helpers.isDesktop() && Margin.mb_3,
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1200,
                { marginTop: helpers.isDesktop() ? 30 : 40 },
              ]}
            >
              <View
                style={[
                  Helpers.newslettertitleBgWrap,
                  Padding.pl_2,
                  Padding.pr_2,
                  darkMode && { backgroundColor: Colors.darkBlackColorBg },
                ]}
              >
                <View
                  style={[
                    helpers.isDesktop() && Helpers.borderBottom,
                    helpers.isDesktop() &&
                      darkMode && {
                        borderBottomColor: "rgba(255,255,255,0.6)",
                      },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      { fontSize: helpers.isDesktop() ? 34 : 24 },
                      { fontFamily: FontFamily.abrilFatface },
                      {
                        fontSize: helpers.isDesktop() ? 34 : 34,
                        fontWeight: 600,
                        lineHeight: helpers.isDesktop() ? 51 : 51,
                        color: "#292929",
                      },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    Recent Editions
                  </Text>
                </View>
              </View>
              <View
                style={[
                  helpers.isDesktop() && Helpers.titleLine,
                  helpers.isDesktop() && {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.6)"
                      : "rgba(102, 102, 102, 0.6)",
                  },
                ]}
              ></View>
            </View>
            {this.state.recentAdditions.length != 0 &&
              this.state.recentAdditions.map((item) => {
                return (
                  <>
                    <RecentNewsletterListing
                      newsLetter={item}
                      getNewsletterClick={(slug) =>
                        this.navigateToNewsletter(slug)
                      }
                      darkMode={darkMode}
                      token={this.state.token}
                    />
                  </>
                );
              })}
          </View>
        </View>
        <View
          style={
            !darkMode && {
              backgroundColor: this.state.token ? "#FFFBF1" : "#FFFBF1",
            }
          }
        >
          <View
            style={[
              Flex.alignCenter,
              helpers.isDesktop() && Padding.pb_7,
              helpers.isDesktop() && Helpers.conatinerWeb1000,
              !helpers.isDesktop() && Padding.pb_2,
              {},
              !darkMode && {
                backgroundColor: this.state.token ? "#FFFBF1" : "#FFFBF1",
              },
              { alignSelf: "center", width: "100%" },
              { paddingTop: helpers.isDesktop() ? 30 : 40 },
            ]}
          >
            <View
              style={[
                {
                  backgroundColor: this.state.token ? "#FFFBF1" : "#FFFBF1",
                  zIndex: 1,
                },
                Padding.pl_2,
                Padding.pr_2,
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() &&
                    darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: helpers.isDesktop() ? 34 : 24 },
                    { fontFamily: FontFamily.abrilFatface },
                    {
                      fontSize: helpers.isDesktop() ? 34 : 34,
                      fontWeight: 600,
                      lineHeight: helpers.isDesktop() ? 51 : 51,
                      color: "#292929",
                    },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                  ]}
                >
                  Active Newsletters
                </Text>
              </View>
            </View>
            <View
              style={[
                helpers.isDesktop() && Helpers.titleLineNews,
                helpers.isDesktop() && { width: "100%" },
                helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? "rgba(255,255,255,0.6)"
                    : "rgba(102, 102, 102, 0.6)",
                },
              ]}
            ></View>
          </View>

          <View
            style={[
              helpers.isDesktop() && activenews.gridcontainer,
              { width: helpers.isDesktop() ? 1000 : "auto" },
              !darkMode && {
                backgroundColor: this.state.token ? "#FFFBF1" : "#FFFBF1",
              },
            ]}
          >
            <View style={[helpers.isDesktop() && { width: "50%" }]}>
              {activeNewletters &&
                activeNewletters.length != 0 &&
                activeNewletters.map((item) => {
                  return (
                    <>
                      <ActiveNewsletterListing
                        newsLetter={item}
                        token={this.state.token}
                        getNewsletterClick={(slug) =>
                          this.navigateToNewsletter(slug)
                        }
                        darkMode={darkMode}
                        status={newsletterSubscriptionStatus}
                        updateSubscription={(value) => {
                          this.updateSubscription(value);
                        }}
                      />
                    </>
                  );
                })}
            </View>

            {/* {helpers.isDesktop() && ( */}
            <View
              style={[
                helpers.isDesktop() && {
                  marginLeft: 60,
                  width: helpers.isDesktop() ? 448 : "90%",
                },
              ]}
            >
              {!helpers.isDesktop() && (
                <View
                  style={{
                    marginTop: helpers.isDesktop() ? 80 : 0,
                    marginBottom: 40,
                  }}
                >
                  <Text
                    style={[
                      postSingle.authorName,
                      Margin.mb_3,
                      helpers.isDesktop() && postSingle.authorNameDesktop,
                      !helpers.isDesktop() && {
                        fontSize: 24,
                        lineHeight: 36,
                        color: "#051462",
                        textAlign: "center",
                        marginBottom: 16,
                      },
                      helpers.isDesktop() && {
                        fontWeight: "400",
                        fontSize: 24,
                        lineHeight: 36,
                      },
                      {
                        fontFamily: FontFamily.abrilFatface,
                        fontWeight: FontWeight.semiBold,
                      },
                      darkMode && {
                        color: "rgba(255,255,255,0.87)",
                      },
                    ]}
                  >
                    {"Explore Newsletters On"}
                  </Text>

                  <View
                    className="sidebar-widget-tag mt-25"
                    style={[
                      !helpers.isDesktop() && {
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <View
                      style={{
                        disply: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {[
                        {
                          tag: "Startups",
                          link: "/newsletters/things-change",
                          category: "things-change"
                        },
                        {
                          tag: "Public Markets",
                          link: "/newsletters/street-smart",
                          category: "street-smart"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "World Affairs",
                          link: "/newsletters/antiloquy",
                          category: "antiloquy"
                        },
                        {
                          tag: "Love & Family",
                          link: "/newsletters/notes-from-natasha",
                          category: "notes-from-natasha"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 24 : 14,
                                      lineHeight: helpers.isDesktop() ? 30 : 16,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>{" "}
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        ,
                        {
                          tag: "Science",
                          link: "/newsletters/the-world-revealed",
                          category: "the-world-revealed"
                        },
                        {
                          tag: "Sports",
                          link: "/newsletters/game-point",
                          category: "game-point"
                        },
                        {
                          tag: "Economy",
                          link: "/newsletters/peoples-economics",
                          category: "peoples-economics"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 24 : 14,
                                      lineHeight: helpers.isDesktop() ? 30 : 16,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        ,
                        {
                          tag: "Technology",
                          link: "/newsletters/oversize",
                          category: "oversize"
                        },
                        {
                          tag: "Agriculture",
                          link: "/newsletters/from-fork-to-farm",
                          category: "from-fork-to-farm"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 24 : 14,
                                      lineHeight: helpers.isDesktop() ? 30 : 16,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Environment",
                          link: "/newsletters/the-crucial-years",
                          category: "the-crucial-years"
                        },
                        {
                          tag: "Public Policy",
                          link: "/newsletters/creative-destruction",
                          category: "creative-destruction"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 24 : 14,
                                      lineHeight: helpers.isDesktop() ? 30 : 16,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Personal Finance",
                          link: "/newsletters/bookkeeping",
                          category: "bookkeeping"
                        },
                        {
                          tag: "Conflict",
                          link: "/newsletters/thirty-six",
                          category: "thirty-six"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  !helpers.isDesktop() && {
                                    marginLeft: 8,
                                    marginRight: 8,
                                    marginBottom: 12,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 24 : 14,
                                      lineHeight: helpers.isDesktop() ? 30 : 16,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                  </View>
                </View>
              )}

              {this.state.token ? null : (
                <View
                  style={{
                    backgroundColor: "#ffffff",
                    padding: 24,
                    marginLeft: helpers.isDesktop() ? "auto" : 20,
                    marginRight: helpers.isDesktop() ? "auto" : 20,
                    marginBottom: helpers.isDesktop() ? 0 : 40,
                    borderColor: "#6C7AF6",
                    borderWidth: helpers.isDesktop() ? 0 : 1,
                    marginBottom: helpers.isDesktop() ? 80 : 0,
                  }}
                >
                  <Text
                    style={[
                      Padding.pb_2,
                      Padding.pt_15,
                      {
                        fontSize: helpers.isDesktop() ? 24 : 22,
                        lineHeight: helpers.isDesktop() ? 36 : 33,
                        paddingBottom: helpers.isDesktop() ? 36 : 33,
                        fontFamily: FontFamily.abrilFatface,
                        fontWeight: FontWeight.semiBold,
                        color: "#051462",
                      },
                      darkMode && {
                        fontWeight: FontWeight.medium,
                        letterSpacing: 0.7,
                      },
                    ]}
                  >
                    Sign Up to Subscribe
                  </Text>
                  <View
                    style={[
                      Flex.row,
                      Flex.alignStart,
                      Margin.mr_2,
                      {
                        paddingBottom: helpers.isDesktop() ? 0 : 20,
                      },
                    ]}
                  >
                    <View style={{ minWidth: 14 }}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 19,
                          resizeMode: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                        webStyle={{
                          width: 20,
                          height: 19,
                          objectFit: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: 27,
                        marginBottom: helpers.isDesktop() ? 10 : 0,
                        letterSpacing: 0.3,
                        flex: 1,
                        color: darkMode ? Colors.lightWhite : "#000",
                      }}
                    >
                      Get FREE {helpers.starterCredits} Starter Credits
                    </Text>
                  </View>

                  <View
                    style={[
                      Flex.row,
                      Flex.alignStart,
                      Margin.mr_2,
                      {
                        paddingBottom: helpers.isDesktop() ? 0 : 20,
                      },
                    ]}
                  >
                    <View style={{ minWidth: 14 }}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 19,
                          resizeMode: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                        webStyle={{
                          width: 20,
                          height: 19,
                          objectFit: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: 27,
                        marginBottom: helpers.isDesktop() ? 10 : 0,
                        letterSpacing: 0.3,
                        flex: 1,
                        color: darkMode ? Colors.lightWhite : "#000",
                      }}
                    >
                      Use Starter Credits to unlock some of the best free
                      longreads and newsletters from our Starter Collection
                      {/* Use Starter Credits to unlock free stories, newsletters
                      and long reads */}
                    </Text>
                  </View>

                  <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                    <View style={{ minWidth: 14 }}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 19,
                          resizeMode: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                        webStyle={{
                          width: 20,
                          height: 19,
                          objectFit: "contain",
                          marginTop: 3,
                          marginRight: 15,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: 27,
                        marginBottom: helpers.isDesktop() ? 10 : 0,
                        letterSpacing: 0.3,
                        flex: 1,
                        color: darkMode ? Colors.lightWhite : "#000",
                      }}
                    >
                      Starter Credits{" "}
                      <Text
                        style={{
                          fontFamily: FontFamily.bold,
                          fontSize: helpers.isDesktop() ? 18 : 16,
                          lineHeight: 27,
                          marginBottom: helpers.isDesktop() ? 10 : 0,
                          letterSpacing: 0.3,
                          flex: 1,
                          color: darkMode ? Colors.lightWhite : "#000",
                        }}
                      >
                        do not expire
                      </Text>
                      {/* {" "} */}
                      {/* – use them as on stories of your choice */}
                    </Text>
                  </View>
                  <View
                    style={[
                      Margin.mt_3,
                      helpers.isDesktop() && Flex.row,
                      !helpers.isDesktop() && { width: "100%" },
                      { alignSelf: "flex-start", width: "100%" },
                    ]}
                  >
                    <ButtonGradient
                      title={"Get Started"}
                      rounded={true}
                      rootStyle={{
                        btnWrapper: [
                          button.primaryGradientBtn,
                          {
                            height: 46,
                            borderRadius: 0,
                            flex: 1,
                            position: "relative",
                            right: helpers.isDesktop() ? 1.5 : 0,
                          },
                        ],
                        btnText: [
                          button.primaryGradientText,
                          {
                            fontSize: helpers.isDesktop() ? 17 : 16,
                            fontWeight: "400",
                            paddingLeft: 35,
                            paddingRight: 35,
                            paddingTop: helpers.isDesktop() ? 0 : 15,
                            paddingBottom: helpers.isDesktop() ? 0 : 15,
                          },
                        ],
                      }}
                      onClick={this.signUp}
                      disable={false}
                      darkMode={darkMode}
                    />
                  </View>
                </View>
              )}

              {helpers.isDesktop() && (
                <View>
                  <Text
                    style={[
                      postSingle.authorName,
                      Margin.mb_3,
                      helpers.isDesktop() && postSingle.authorNameDesktop,
                      !helpers.isDesktop() && {
                        fontSize: 24,
                        lineHeight: 36,
                        color: "#051462",
                        textAlign: "center",
                        marginBottom: 16,
                      },
                      helpers.isDesktop() && {
                        fontWeight: "400",
                        fontSize: 24,
                        lineHeight: 36,
                      },
                      darkMode && {
                        color: "rgba(255,255,255,0.87)",
                      },
                    ]}
                  >
                    {"Explore Newsletters On"}
                  </Text>

                  <View
                    className="sidebar-widget-tag mt-25"
                    style={[
                      !helpers.isDesktop() && {
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <View
                      style={{
                        disply: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {[
                        {
                          tag: "Startups",
                          link: "/newsletters/things-change",
                          category: "things-change"
                        },
                        {
                          tag: "Public Markets",
                          link: "/newsletters/street-smart",
                          category: "street-smart"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "World Affairs",
                          link: "/newsletters/antiloquy",
                          category: "antiloquy"
                        },
                        {
                          tag: "Love & Family",
                          link: "/newsletters/notes-from-natasha",
                          category: "notes-from-natasha"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>{" "}
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Technology",
                          link: "/newsletters/oversize",
                          category: "oversize"
                        },
                        {
                          tag: "Agriculture",
                          link: "/newsletters/from-fork-to-farm",
                          category: "from-fork-to-farm"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Economy",
                          link: "/newsletters/peoples-economics",
                          category: "peoples-economics"
                        },
                        {
                          tag: "Science",
                          link: "/newsletters/the-world-revealed",
                          category: "the-world-revealed"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)} >
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Public Policy",
                          link: "/newsletters/creative-destruction",
                          category: "creative-destruction"
                        },
                        {
                          tag: "Environment",
                          link: "/newsletters/the-crucial-years",
                          category: "the-crucial-years"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Sports",
                          link: "/newsletters/game-point",
                          category: "game-point"
                        },
                        {
                          tag: "Personal Finance",
                          link: "/newsletters/bookkeeping",
                          category: "bookkeeping"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                    <View style={{ disply: "flex", flexDirection: "row" }}>
                      {[
                        {
                          tag: "Conflict",
                          link: "/newsletters/thirty-six",
                          category: "thirty-six"
                        },
                      ].map((item, key) => {
                        return (
                          <a href={item.link} onClick={() => this.pushThemeClickToDataLayer(item.tag, item.category)}>
                            <View key={key} style={[Widgets.buttonli]}>
                              <View
                                style={[
                                  Widgets.button,
                                  helpers.isDesktop() && {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: helpers.isDesktop() ? 25 : 16,
                                    paddingRight: helpers.isDesktop() ? 25 : 16,
                                  },
                                  {
                                    marginRight: 24,
                                    marginBottom: 24,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    newsletter.title,
                                    !helpers.isDesktop() && {
                                      paddingBottom: helpers.isDesktop()
                                        ? 10
                                        : 0,
                                      fontSize: 16,
                                      lineHeight: 34,
                                      color: "#ECB731",
                                    },
                                    helpers.isDesktop() && {
                                      fontSize: helpers.isDesktop() ? 20 : 16,
                                      lineHeight: helpers.isDesktop() ? 41 : 35,
                                      color: "#ECB731",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  {item.tag}
                                </Text>
                              </View>
                            </View>
                          </a>
                        );
                      })}
                    </View>
                  </View>
                </View>
              )}
            </View>
            {/* // )} */}
            {!helpers.isDesktop() && (
              <View style={[!helpers.isDesktop() && { margin: "auto" }]}>
                {/* <View >
 
      <Text style={[postSingle.authorName,Margin.mb_3, helpers.isDesktop() && postSingle.authorNameDesktop, ( !helpers.isDesktop()) && {fontSize: 15, fontFamily: FontFamily.abrilFatface},( !helpers.isDesktop()) && {fontWeight: '400',fontSize:24,alignSelf:'center',color: '#051462'},darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{'Explore Newsletters On'}</Text>
      <View className="sidebar-widget-tag mt-25" style={{margin:'auto'}}>
          <View style={{disply:'flex',flexDirection:'row'}}>
            {[{tag:'Internet',link:"/newsletters/oversize"},{tag:'Business',link:"/newsletters/street-smart"},{tag:'Chaos',link:"/newsletters/thirty-six"}].map((item, key) => {
              return (
                  <a href={item.link}>
                <View 
                key={key}
                style={[Widgets.buttonli]}
                >
                  <View
                   style={[Widgets.buttonmobn]}
                    
                  >
                <Text style={[newsletter.title, !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16,color:'#ECB731' }, helpers.isDesktop() && { fontSize: 24, lineHeight: 30,color:'#ECB731' }, darkMode && { color: Colors.lightWhite }]}>{item.tag}</Text>
                  </View>
                </View>
                </a>
               );
            })} 
          </View>
           <View style={{disply:'flex',flexDirection:'row'}}>
            {[{tag:'Economics',link:"/category/chaos"},{tag:'Legal',link:"/category/business"},{tag:'Sports',link:"/newsletters/game-point"}].map((item, key) => {
              return (
                   <a href={item.link}>
                <View 
                key={key}
                style={[Widgets.buttonli]}
                >
                  <View
                   style={[Widgets.buttonmobn]}
                  >
                <Text style={[newsletter.title, !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 ,color:'#ECB731'}, helpers.isDesktop() && { fontSize: 24, lineHeight: 30,color:'#ECB731' }, darkMode && { color: Colors.lightWhite }]}>{item.tag}</Text>
                  </View>
                </View>
                </a>
               );
            })} 
          </View>
          <View style={{disply:'flex',flexDirection:'row',alignItems:'center',margin:'auto'}}>
            {[{tag:'Science',link:"/category/chaos"}].map((item, key) => {
              return (
                   <a href={item.link}>
                <View 
                key={key}
                style={[Widgets.buttonli]}
                >
                  <View
                   style={[Widgets.buttonmobn]}
                  >
                <Text style={[newsletter.title, !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 ,color:'#ECB731'}, helpers.isDesktop() && { fontSize: 24, lineHeight: 30,color:'#ECB731' }, darkMode && { color: Colors.lightWhite }]}>{item.tag}</Text>
                  </View>
                </View>
                </a>
               );
            })} 
          </View>
      </View>
    </View> */}
              </View>
            )}
          </View>
        </View>
        <View
          style={[
            { height: helpers.isWeb() ? 600 : 500 },
            !darkMode && {
              backgroundColor: this.state.token ? "#fafafa" : "#ffffff",
            },
          ]}
        >
          {this.renderCarousal()}

          {/* {this.renderCarousal()} */}
        </View>
        <View style={!darkMode && { backgroundColor: "#FFFFFF" }}>
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              { marginBottom: 10 },
            ]}
          >
            <View
              style={[
                Flex.alignCenter,
                helpers.isDesktop() && Margin.mb_3,
                !helpers.isDesktop() && Margin.mb_1,
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1200,
                { marginTop: helpers.isDesktop() ? 30 : 40 },
              ]}
            >
              <View
                style={[
                  {
                    backgroundColor: this.state.token ? "#FFFFFF" : "#FFFFFF",
                    zIndex: 1,
                  },
                  Padding.pl_2,
                  Padding.pr_2,
                  darkMode && { backgroundColor: Colors.darkBlackColorBg },
                ]}
              >
                <View
                  style={[
                    helpers.isDesktop() && Helpers.borderBottom,
                    helpers.isDesktop() &&
                      darkMode && {
                        borderBottomColor: "rgba(255,255,255,0.6)",
                      },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      { fontSize: helpers.isDesktop() ? 34 : 24 },
                      { fontFamily: FontFamily.abrilFatface },
                      {
                        fontSize: helpers.isDesktop() ? 34 : 34,
                        lineHeight: helpers.isDesktop() ? 51 : 51,
                        color: "#292929",
                      },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    Past Newsletters
                  </Text>
                </View>
              </View>
              <View
                style={[
                  helpers.isDesktop() && Helpers.titleLine,
                  helpers.isDesktop() && {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.6)"
                      : "rgba(102, 102, 102, 0.6)",
                  },
                ]}
              ></View>
            </View>
          </View>
          <View
            style={[
              helpers.isDesktop() && {
                flexDirection: "row",
                display: "flex",
                // marginHorizontal: "auto",
                // maxWidth: "1200px",
              },
            ]}
          >
            {!helpers.isDesktop() && (
              <Text
                style={[
                  newsletter.title,
                  !helpers.isDesktop() && {
                    paddingBottom: 10,
                    fontSize: 16,
                    lineHeight: 24,
                    marginHorizontal: 5,
                    textAlign: "center",
                  },
                  helpers.isDesktop() && {
                    fontSize: 18,
                    lineHeight: 30,
                    width: "90%",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Sharp analysis by our writers and expert contributors, from big
                tech to corp governance
              </Text>
            )}
            {pastNewletters && pastNewletters.length != 0 ? (
              <PastNewsletterListing
                //   newsLetter={item}
                token={this.state.token}
                getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
                darkMode={darkMode}
                pastNewletters={pastNewletters}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
        <View style={{ height: helpers.isWeb() ? 600 : 500 }}>
          {this.state.writers && this.renderWriters()}

          {/* {this.renderCarousal()} */}
        </View>
        <View
          style={[
            { backgroundColor: darkMode ? "#121212" : "#FFFFFF" },
            helpers.isDesktop() && { maxWidth: "1200px", margin: "auto" },
          ]}
        >
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              { marginBottom: 10 },
            ]}
          >
            {helpers.isDesktop() && (
              <View
                style={[
                  Flex.alignCenter,
                  Margin.mb_3,
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb1000,
                  { marginTop: helpers.isDesktop() ? 30 : 40 },
                ]}
              ></View>
            )}
          </View>
          <WriteForus
            home={true}
            darkMode={darkMode}
            data={this.state.readerQuotes}
          />
        </View>
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/newsletters",
      native: { redirect: "/newsletters", verifyRedirect: "/newsletters" },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          ,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["newsletters"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/newsletters"
        />

        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => {
            console.log("olo");
            this.props.navigateToScreen(slug);
          }}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
              ? this.renderWebPage()
              : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? "fixed" : "absolute",
                  bottom: 20,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{ paddingHorizontal: 10, paddingVertical: 10 }}
                ></TouchableOpacity>
              </View>
            )}
          </>
        )}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Newsletter));

const styles = StyleSheet.create({

  textInputStyle: {
    borderBottomColor: "#051462",
    color: "#051462",
    borderBottomWidth: 2,
    marginBottom: 20,
    width: "100%",
    marginRight: 0,
    paddingTop: 8,
    paddingBottom: 8, 
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
    height: 50,
  },
  orText: {
    color: "#051462",
    marginHorizontal: 10,
    padding: 8,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
  },
  orTextmobile: {
    color: '#051462',
    padding: 6,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
  },
  textInputStylemobile: {
    marginBottom: 20,
    width: "100%",
    borderBottomWidth: 2,
    borderBottomColor: '#051462',
    color: '#051462',
    marginRight: 0,
    paddingTop: 8,
    paddingBottom: 0, 
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
    height: 50,
  },
 
});

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Newsletters",
  },
];
