import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Badges,
  Helpers,
  postContentTags,
  postContentClasses,
  postTags,
  modalStyle,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import moment from "moment";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import PostStrapline from "../post-strapline";
import getImagePath from "../../utils/image-helper";
import ContentLoader from "react-native-easy-content-loader";
import Button from "../button";
import ZoomImage from "../zoom-image";
import PostActionBar from "../../components/post-actionbar";

const mostViewUpIcon = getImagePath("icons/most-viewed.png");
const goldCoin = getImagePath("icons/goldCoin.png");
const silverCoin = getImagePath("icons/silverCoinSmall.png");
const unlockIconGreen = getImagePath("icons/unlockIconGreen.png");
const closeIcon = getImagePath("icons/close-icon-white.png");

import { ProgressCircle } from "react-native-material-indicators";

export class PostSummaryStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFont: false,
      subscriptionComplete: false,
      showImageModal: false,
    };
  }

  componentDidMount = () => {
    if (
      helpers.isWeb() &&
      window.location.search.includes("titleFontOverride=")
    ) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("titleFontOverride=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("titleFontOverride=", "");
        if (search != "") {
          this.setState({ titleFont: search });
        }
      }
    }
  };

  renderNewsletterCategory(newsletter) {
    let newsletterBtnClick;
    if (helpers.isWeb()) {
      newsletterBtnClick = {
        to: "/newsletters/" + newsletter.slug,
      };
    } else {
      newsletterBtnClick = {
        onPress: () => this.props.getNewsletterClick(newsletter.slug),
      };
    }
    return (
      <View style={[Flex.alignCenter]}>
        <TouchableOpacityLink {...newsletterBtnClick}>
          <CustomImage
            webStyle={{ maxWidth: 140, width: "100%", height: "auto" }}
            style={{ width: 180, height: 85 }}
            source={newsletter.logo}
            resizeMode={"contain"}
            altText={newsletter.name}
          />
        </TouchableOpacityLink>
      </View>
    );
  }

  render() {
    const {
      data,
      getAuthorClick,
      getCategoryClick,
      unlockLoader,
      postUnlocked,
      insufficientCredits,
      storyActions,
      applyStoryActions,
      sharePost,
      actionsLoader,
      darkMode,
    } = this.props;
    const { titleFont, showImageModal } = this.state;
    const webView = helpers.isWeb();
    let catBtnClick;
    if (data.category) {
      if (webView) {
        catBtnClick = {
          to: "/category/" + data.category.slug,
        };
      } else {
        catBtnClick = {
          onPress: () => getCategoryClick(data.category.slug),
        };
      }
    }

    return (
      <View
        style={[
          !helpers.isDesktop() && showImageModal && { zIndex: 99999 },
          darkMode && {
            backgroundColor: Colors.darkBlackColorBg,
            color: "rgba(255, 255, 255, 0.89)",
          },
        ]}
      >
        <ZoomImage
          featuredImage={data.featured_image}
          featuredTitle={data.postTitle}
          lazyLoad={data.lazyLoad}
          darkMode={darkMode}
          showImageModal={showImageModal}
          setShowImageModal={(value) => {
            this.setState({ showImageModal: value });
            this.props.setScrollEnabled(!value);
          }}
        />
        <View
          style={[
            postSingle.summaryWrapper,
            helpers.isDesktop() && postSingle.summaryWrapperDesktop,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1240,
          ]}
        >
          <View
            style={[
              postSingle.summaryInnerWrapper,
              darkMode && {
                backgroundColor: Colors.darkBlackColorBg,
                color: "rgba(255, 255, 255, 0.89)",
              },
            ]}
          >
            {data.statsLoader ? (
              <View
                style={[
                  {
                    backgroundColor: "#ffffff",
                    maxWidth: helpers.isDesktop() ? "100%" : "90%",
                    width: "100%",
                    height: 16,
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  darkMode && { backgroundColor: Colors.darkBlackColorBg },
                ]}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: helpers.isDesktop() ? "140px" : "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <ContentLoader
                    containerStyles={{ marginBottom: 0 }}
                    active={true}
                    pHeight={[10]}
                    title={false}
                    pRows={1}
                    pWidth={["100%"]}
                    animationDuration={400}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                </View>
              </View>
            ) : (
              <View
                style={[
                  postSingle.mostViewedWrap,
                  { marginBottom: 10, marginTop: 10 },
                ]}
              >
                {data.storyStats && (
                  <>
                    <CustomImage
                      webStyle={{ width: 16, height: 16 }}
                      style={{ width: 16, height: 16 }}
                      source={mostViewUpIcon}
                      require={true}
                    />
                    <Text
                      style={[
                        postSingle.mostViewText,
                        darkMode && { color: "#55C79A" },
                      ]}
                    >
                      {data.storyStats}
                    </Text>
                  </>
                )}
              </View>
            )}
            {data.newsletter ? (
              <View style={[Margin.mt_2]}>
                {this.renderNewsletterCategory(data.newsletter)}
              </View>
            ) : (
              <>
                {data.category && (
                  <View style={[Flex.alignCenter, Margin.mt_2]}>
                    <TouchableOpacityLink {...catBtnClick}>
                      <Text
                        style={[
                          Badges.infoBage,
                          helpers.isDesktop() && Badges.infoBageDesktop,
                          Badges.infoBagePurple,
                          helpers.isDesktop() && Badges.infoBagePurpleDesktop,
                          {
                            marginRight: 0,
                            fontSize: helpers.isDesktop() ? 16 : 14,
                          },
                          darkMode && { backgroundColor: "#5263F4" },
                        ]}
                      >
                        {data.category.name}
                      </Text>
                    </TouchableOpacityLink>
                  </View>
                )}
              </>
            )}

            {data.postTitle && (
              <Text
                accessibilityRole="header"
                aria-level="1"
                style={[
                  postSingle.postTitle,
                  helpers.isDesktop() && postSingle.postTitleDesktop,
                  Margin.mt_2,
                  { color: "#000" },
                  titleFont && { fontFamily: titleFont },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                {data.postTitle}
              </Text>
            )}

            {data?.story?.strapline != undefined && data.story.strapline != "" && (
              <View style={[Margin.mt_2, Margin.mb_2]}>
                <PostStrapline
                  data={data.story.strapline}
                  darkMode={darkMode}
                />
              </View>
            )}
          </View>
        </View>

        <View
          style={[
            Padding.ph_2,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb800,
          ]}
        >
          {data.author && data.author.length > 1 ? (
            <View>
              <View
                style={[
                  postSingle.postInfoWrapper,
                  helpers.isDesktop() && Flex.justfiyCenter,
                  !helpers.isDesktop() && { justifyContent: "center" },
                ]}
              >
                {data.author &&
                  data.author.map((item, index) => {
                    let authorBtnClick;
                    if (webView) {
                      authorBtnClick = {
                        to: "/writers/" + item.slug,
                      };
                    } else {
                      authorBtnClick = {
                        onPress: () => getAuthorClick(item.slug),
                      };
                    }
                    return (
                      <View
                        key={`postSumm_${index}`}
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <View
                          style={[
                            postSingle.authorDetailsWrap,
                            !helpers.isDesktop() && Margin.mb_1,
                            index == 1 && Margin.ml_2,
                          ]}
                        >
                          <View
                            key={`author_${index}`}
                            style={[postSingle.authorPicWrap]}
                          >
                            <TouchableOpacityLink {...authorBtnClick}>
                              <CustomImage
                                webStyle={{
                                  width: 38,
                                  height: 38,
                                  borderRadius: 100,
                                  marginTop: 4,
                                }}
                                resizeMode={"contain"}
                                style={postSingle.authorThumbPic}
                                altText={item.name}
                                source={item.profile_image}
                              />
                            </TouchableOpacityLink>
                          </View>
                          <View style={[Padding.pl_1]}>
                            <TouchableOpacityLink {...authorBtnClick}>
                              <Text
                                style={[
                                  postSingle.authorName,
                                  helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {item.name}
                              </Text>
                            </TouchableOpacityLink>
                            {!!item.location && (
                              <Text
                                style={[
                                  postSingle.authorLocation,
                                  helpers.isDesktop() &&
                                    postSingle.authorLocationDesktop,
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {item.location}
                              </Text>
                            )}
                          </View>
                        </View>
                        {/* {helpers.isDesktop() &&
                                                <View style={[postSingle.dotSeprator, { marginLeft: 0, marginRight: 15 }]} />
                                            } */}
                      </View>
                    );
                  })}
              </View>
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  helpers.isDesktop() ? Margin.mt_2 : Margin.mt_1,
                  helpers.isDesktop() && Flex.justfiyCenter,
                ]}
              ></View>
              {
                <View
                  style={[
                    helpers.isDesktop() && Flex.row,
                    Margin.mt_0,
                    Helpers.conatinerWeb,
                    helpers.isDesktop() && Helpers.conatinerWeb680,
                    { alignItems: "center", justifyContent: "center" },
                    helpers.isDesktop() && Margin.mt_1,
                  ]}
                >
                  {!helpers.isDesktop() && (
                    <View
                      style={[
                        {
                          height: 0.3,
                          width: "100%",
                          borderTopWidth: 0.3,
                          borderTopColor: "rgba(102, 102, 102, 0.6)",
                          marginVertical: 15,
                          alignItems: "center",
                          justifyContent: "center",
                          width: 50,
                        },
                      ]}
                    ></View>
                  )}
                  {(!actionsLoader || !helpers.isDesktop()) && (
                    <Text
                      style={[
                        postSingle.postDate,
                        helpers.isDesktop() && { paddingRight: 10 },
                        helpers.isDesktop() && postSingle.postDateDesktop,
                        !helpers.isDesktop() && Padding.pv_05,
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format(
                        "DD MMMM, YYYY"
                      )}
                    </Text>
                  )}
                  {!actionsLoader && helpers.isWeb() && (
                    <PostActionBar
                      updateFontSize={() => this.changeFontSize()}
                      storyActions={storyActions}
                      applyStoryActions={(type, value) =>
                        applyStoryActions(type, value)
                      }
                      sharePost={(slug) => sharePost(slug)}
                      shareTitle={data.story.title}
                      darkMode={darkMode}
                    />
                  )}
                  {actionsLoader && (
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: helpers.isDesktop() ? 300 : 160,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginVertical: 15,
                      }}
                    >
                      <ContentLoader
                        containerStyles={{ marginBottom: 0 }}
                        active={true}
                        pHeight={[30]}
                        title={false}
                        pRows={1}
                        pWidth={["100%"]}
                        animationDuration={400}
                        primaryColor={
                          darkMode
                            ? "rgba(45, 48, 45,1)"
                            : "rgba(220, 220, 220, 1)"
                        }
                        secondaryColor={
                          darkMode
                            ? "rgba(45, 48, 45,0.8)"
                            : "rgba(200, 200, 200, 1)"
                        }
                      />
                    </View>
                  )}
                </View>
              }
              {data.loaderSummary != true && (
                <View
                  style={[
                    helpers.isDesktop() && {
                      flexDirection: "row",
                      marginRight: 0,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    { marginTop: 20 },
                    !helpers.isDesktop() && { alignItems: "center" },
                    !helpers.isWeb() && { marginTop: 40 },
                  ]}
                >
                  {data.story && data.story.credits && this.creditStory()}
                </View>
              )}
            </View>
          ) : (
            <View
              style={[
                postSingle.postInfoWrapper,
                Flex.justifyBetween,
                helpers.isDesktop() && { flexDirection: "column" },
                !helpers.isDesktop() && { justifyContent: "center" },
              ]}
            >
              {data.author &&
                data.author.map((item, index) => {
                  let authorBtnClick;
                  if (webView) {
                    authorBtnClick = {
                      to: "/writers/" + item.slug,
                    };
                  } else {
                    authorBtnClick = {
                      onPress: () => getAuthorClick(item.slug),
                    };
                  }
                  return (
                    <View style={[postSingle.authorDetailsWrap, Padding.pr_1]}>
                      <View
                        key={`author_${index}`}
                        style={[postSingle.authorPicWrap]}
                      >
                        <TouchableOpacityLink
                          key={`postSumm_${index}`}
                          {...authorBtnClick}
                        >
                          <CustomImage
                            webStyle={{
                              width: 38,
                              height: 38,
                              borderRadius: 100,
                              marginTop: 4,
                            }}
                            resizeMode={"contain"}
                            style={postSingle.authorThumbPic}
                            altText={item.name}
                            source={item.profile_image}
                          />
                        </TouchableOpacityLink>
                      </View>
                      <View
                        style={[
                          Padding.pl_1,
                          helpers.isDesktop() && {
                            flexDirection: "row",
                            alignItems: "center",
                          },
                        ]}
                      >
                        <View style={[Flex.row, Flex.alignCenter]}>
                          <TouchableOpacityLink
                            key={`postSumm_${index}`}
                            {...authorBtnClick}
                          >
                            <Text
                              style={[
                                postSingle.authorName,
                                helpers.isDesktop() &&
                                  postSingle.authorNameDesktop,
                                darkMode && {
                                  color: "rgba(255, 255, 255, 0.89)",
                                },
                              ]}
                            >
                              {item.name}
                            </Text>
                          </TouchableOpacityLink>
                          {!!item.location && (
                            <>
                              <View style={[postSingle.dotSeprator]} />
                              <Text
                                style={[
                                  postSingle.authorLocation,
                                  helpers.isDesktop() &&
                                    postSingle.authorLocationDesktop,
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {item.location}
                              </Text>
                            </>
                          )}
                        </View>
                      </View>
                    </View>
                  );
                })}
              {
                <View
                  style={[
                    helpers.isDesktop() && Flex.row,
                    Margin.mt_0,
                    Helpers.conatinerWeb,
                    helpers.isDesktop() && Helpers.conatinerWeb680,
                    { alignItems: "center", justifyContent: "center" },
                    helpers.isDesktop() && Margin.mt_1,
                  ]}
                >
                  {!helpers.isDesktop() && (
                    <View
                      style={[
                        {
                          height: 0.3,
                          width: "100%",
                          borderTopWidth: 0.3,
                          borderTopColor: "rgba(102, 102, 102, 0.6)",
                          marginVertical: 15,
                          alignItems: "center",
                          justifyContent: "center",
                          width: 50,
                        },
                      ]}
                    ></View>
                  )}
                  {(!actionsLoader || !helpers.isDesktop()) && (
                    <Text
                      style={[
                        postSingle.postDate,
                        helpers.isDesktop() && { paddingRight: 10 },
                        helpers.isDesktop() && postSingle.postDateDesktop,
                        !helpers.isDesktop() && Padding.pv_05,
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format(
                        "DD MMMM, YYYY"
                      )}
                    </Text>
                  )}
                  {!actionsLoader && helpers.isWeb() && (
                    <PostActionBar
                      updateFontSize={() => this.changeFontSize()}
                      storyActions={storyActions}
                      applyStoryActions={(type, value) =>
                        applyStoryActions(type, value)
                      }
                      sharePost={(slug) => sharePost(slug)}
                      shareTitle={data.story.title}
                      darkMode={darkMode}
                    />
                  )}
                  {actionsLoader && (
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: helpers.isDesktop() ? 300 : 160,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginVertical: 15,
                      }}
                    >
                      <ContentLoader
                        containerStyles={{ marginBottom: 0 }}
                        active={true}
                        pHeight={[30]}
                        title={false}
                        pRows={1}
                        pWidth={["100%"]}
                        animationDuration={400}
                        primaryColor={
                          darkMode
                            ? "rgba(45, 48, 45,1)"
                            : "rgba(220, 220, 220, 1)"
                        }
                        secondaryColor={
                          darkMode
                            ? "rgba(45, 48, 45,0.8)"
                            : "rgba(200, 200, 200, 1)"
                        }
                      />
                    </View>
                  )}
                </View>
              }
              {data.loaderSummary != true && (
                <View
                  style={[
                    helpers.isDesktop() && {
                      flexDirection: "row",
                      marginRight: 0,
                      alignItems: "center",
                    },
                    { marginTop: 30 },
                    !helpers.isWeb() && { marginTop: 60 },
                  ]}
                >
                  {data.story && data.story.credits && this.creditStory()}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    );
  }

  creditStory = () => {
    const {
      data,
      getAuthorClick,
      getCategoryClick,
      unlockLoader,
      postUnlocked,
      insufficientCredits,
      darkMode,
      creditPlan,
    } = this.props;
    return (
      <>
        {postUnlocked ? (
          <View style={[postSingle.unlockedStory, Flex.row, Flex.alignCenter]}>
            <CustomImage
              webStyle={{ width: 15, height: 20, marginRight: 20 }}
              style={{ width: 15, height: 20, marginRight: 20 }}
              source={unlockIconGreen}
              require={true}
              resizeMode="contain"
            />
            <Text style={[postSingle.unlockedStoryText]}>
              {data.freeRead
                ? "Free Read"
                : data.newsletter
                ? "Unlocked newsletter"
                : "Unlocked story"}
            </Text>
          </View>
        ) : (
          <>
            {creditPlan && creditPlan.description && (
              <View style={[Margin.mv_1, Flex.row]}>
                {data.is_free != true && (
                  <View
                    style={[
                      postSingle.creditBoxBlue,
                      Flex.row,
                      Flex.alignCenter,
                      darkMode && { borderColor: Colors.purpleBorder },
                    ]}
                  >
                    <CustomImage
                      webStyle={{ width: 23, height: 23, marginRight: 5 }}
                      style={{ width: 23, height: 23, marginRight: 5 }}
                      source={
                        data.story.type === "privileged" ? goldCoin : silverCoin
                      }
                      require={true}
                      resizeMode="contain"
                      altText={"Credits"}
                    />
                    <Text
                      style={[
                        postSingle.creditText,
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {data.story.credits.unlock}
                    </Text>
                  </View>
                )}

                <Button
                  title={data.is_free ? "SIGN IN" : "UNLOCK NOW!"}
                  rootStyle={{
                    btnWrapper: [
                      darkMode
                        ? postSingle.creditButtonPurlple
                        : postSingle.creditButton,
                      Flex.alignCenter,
                      Flex.justfiyCenter,
                      { width: 160 },
                      data.is_free && { height: 46 },
                      !helpers.isWeb() && { width: 180 },
                    ],
                    btnText: [postSingle.creditButtonText],
                  }}
                  onClick={() => this.props.unlockPost()}
                  loader={unlockLoader}
                  disable={unlockLoader}
                  indicatorColor={"#ffffff"}
                />
              </View>
            )}
          </>
        )}
      </>
    );
  };
}

export default PostSummaryStory;
