import React, { Component, useEffect } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, Animated, Modal, TouchableWithoutFeedback } from 'react-native';
import { Flex, Padding, Margin, Helpers, FontFamily, postSingle, articleLists, Colors, postActionBar } from '../../styles/appStyles';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import Clipboard from '@react-native-clipboard/clipboard';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import apiHelpers from '../../utils/api-helpers';

const shareIconLight = getImagePath('icons/share-light.png');
const shareIconDark = getImagePath('icons/share-icon-dark.png');
const likeIcon = getImagePath('icons/like-icon.png');
const likeIconLight = getImagePath('icons/like-icon-light.png');
const likeIconSelected = getImagePath('icons/like-icon-selected.png');
const bookmarkIcon = getImagePath('icons/bookmark.png');
const bookmarkIconLight = getImagePath('icons/bookmark-white.png');
const bookmarkIconLightOutline = getImagePath('icons/bookmark-light.png');
const bookmarkIconSelected = getImagePath('icons/bookmark-selected.png');
const fbIcon = getImagePath('icons/facebook-dark.png');
const twitterIcon = getImagePath('icons/twitter-dark.png');
const linkedIcon = getImagePath('icons/linkedin-dark.png');
const fbIconLight = getImagePath('icons/facebook-light.png');
const twitterIconLight = getImagePath('icons/twitter-light.png');
const linkedIconLight = getImagePath('icons/linkedin-light.png');
const appLogo = getImagePath('icons/tmc-logo.png');

const giftLight = getImagePath('icons/gift-light.png');
const giftDark = getImagePath('icons/gift-dark.png');
const triangleIcon = getImagePath('icons/triangle-icon.png');
const copyIcon = getImagePath('icons/copy-icon.svg');
const whatsappIcon = getImagePath('icons/whatsapp-icon.svg');
const emailIcon = getImagePath("icons/email-icon.svg");

class PostShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleFont: false,
            bookmarkLoading: this.props.bookmarkLoading,
            bookmarkLoadingProp: this.props.bookmarkLoading,
            loadingLogo: new Animated.Value(0),
            animatedLike: new Animated.Value(0),
            animatedBookmark: new Animated.Value(0),
            copyButtonText: "Copy Link",
            openGiftModal: false,
            showGiftSubModal: false,
            subsLeft: this.props.subsLeft || 0
        }
    }



    componentDidUpdate() {
        if (this.state.bookmarkLoadingProp != this.props.bookmarkLoading) {
            this.setState({ bookmarkLoadingProp: this.props.bookmarkLoading, loadingLogo: new Animated.Value(0) }, () => {
                if (this.props.bookmarkLoading) {
                    this.setState({
                        bookmarkLoading: this.props.bookmarkLoading
                    }, () => this.animateLogo())
                }
            })
        }
    }

    animateLogo = () => {
        Animated.sequence([
            Animated.timing(this.state.loadingLogo, {
                toValue: -5,
                duration: 150
            }),
            Animated.timing(this.state.loadingLogo, {
                toValue: 5,
                duration: 300
            }),
            Animated.timing(this.state.loadingLogo, {
                toValue: 0,
                duration: 150
            })
        ]).start(() => {
            if (this.state.bookmarkLoading) {
                this.animateLogo();
            }
            if (!this.state.bookmarkLoadingProp) {
                setTimeout(() => {
                    this.setState({
                        bookmarkLoading: false
                    })
                }, 600)
            }
        });
    }

    animateLike = () => {
        this.setState({ animatedLike: new Animated.Value(0) }, () => {
            Animated.timing(this.state.animatedLike, {
                toValue: 47,
                duration: 800,
                useNativeDriver: true
            }).start();
            setTimeout(() => {
                Animated.timing(this.state.animatedLike, {
                    toValue: 0,
                    duration: 1,
                    useNativeDriver: true
                }).start();
            }, 800)
        })
    }

    animateBookmark = () => {
        this.setState({ animatedBookmark: new Animated.Value(0) }, () => {
            Animated.timing(this.state.animatedBookmark, {
                toValue: 47,
                duration: 800,
                useNativeDriver: true
            }).start();
            setTimeout(() => {
                Animated.timing(this.state.animatedBookmark, {
                    toValue: 0,
                    duration: 1,
                    useNativeDriver: true
                }).start();
            }, 800)
        })
    }

    onCopyButtonClick = async() => {
        if(this.state.copyButtonText === "Copied"){
            return;
        }
        let { token } = this.props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: this.props.data?.story?.id || this.props.data?.id,
            slug: this.props.data?.story?.slug || this.props.data?.slug
        }

        if (typeof ClipboardItem !== "undefined" && navigator.clipboard.write) {
            const dataPromise = apiHelpers.apiRequest(endpoint, data, "POST", true, token);
            const textPromise = dataPromise.then(res => `${this.props.profileInfo.name || this.props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode='+res.data.gift_code}`);
            
            Promise.all([dataPromise, textPromise])
              .then(([res, text]) => {
                const blob = new Blob([text], { type: "text/plain" });
                const clipboardItem = new ClipboardItem({ "text/plain": blob });
                return navigator.clipboard.write([clipboardItem]);
              })
              .then(() => {
                this.setState({ copyButtonText: "Copied" });
                setTimeout(() => {
                  this.setState({ copyButtonText: "Copy Link" });
                }, 1000);
              })
              .catch(error => {
                console.error("Error occurred:", error);
              });
          } else {
            apiHelpers.apiRequest(endpoint, data, "POST", true, token)
              .then(res => `${this.props.profileInfo.name || this.props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode='+res.data.gift_code}`)
              .then((text) => {
                navigator.clipboard.writeText(text);
                this.setState({ copyButtonText: "Copied" });
                setTimeout(() => {
                  this.setState({ copyButtonText: "Copy Link" });
                }, 1000);
              })
              .catch(error => {
                console.error("Error occurred:", error);
              });
          }          
    }
    handleWhatsappShare = () => {
        console.log("Clicked on gifting via Whatsapp option")
        let { token } = this.props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: this.props.data?.story?.id || this.props.data?.id,
            slug: this.props.data?.story?.slug || this.props.data?.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res)=>{
            console.log(res)
            if(res.success){
                const expression = /(iPhone|iPod|iPad)/i;

                if (expression.test(navigator.platform)) {
                    // console.log('Apple device detected!');
                    window.location.href = encodeURI(`https://wa.me/?text=${this.props.profileInfo.name || this.props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode='+res.data.gift_code}`)
                } else {
                    // console.log('Other device detected!');
                    window.open(encodeURI(`https://wa.me/?text=${this.props.profileInfo.name || this.props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode='+res.data.gift_code}`))
                }
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    handleEmailShare = () => {
        console.log("Clicked on gifting via Email option")
        let { token } = this.props;
        let endpoint = `/generate-gift-link`;
        let data = {};
                data.story = {
            id: this.props.data.story.id,
            slug: this.props.data.story.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res)=>{
            console.log(res)
            if(res.success){
                window.location.href = `mailto:?subject=Gift from ${this.props.profileInfo.name || this.props.profileInfo.email}&body=${this.props.profileInfo.name || this.props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.
                ${document.location.href + '?giftCode='+res.data.gift_code}`
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    handleGiftButtonClickMob = () => {
        if(this.state.openGiftModal){
            this.setState({openGiftModal: false})
        }else{
            this.setState({openGiftModal: true})
        }
    }

    render() {
        const { data, postTitle, category, postContent, storyActions, applyStoryActions, sharePost, shareTitle, hideStoryActions, hideBorder, likeLoading, darkMode, giftStory } = this.props;
        const { titleFont, bookmarkLoading, loadingLogo, animatedLike, animatedBookmark, copyButtonText } = this.state;
        const boxShadowStyle = {shadowColor: '#171717', shadowOffset: {width: -2, height: 4}, shadowOpacity: 0.2, shadowRadius: 3,}
        let btnClickBookmark, btnClickShare, btnClickFB, btnClickTwitter, btnClickLinkdin, btnClickLike, allowShare, btnClickGift, btnClickGiftSub;
        if (bookmarkLoading) {
            btnClickBookmark = {
                onPress: () => { }
            }
        }
        else {
            btnClickBookmark = {
                onPress: () => applyStoryActions("bookmark", (storyActions && storyActions.bookmark) ? false : true, () => this.animateBookmark())
            }
        }
        btnClickShare = {
            onPress: () => sharePost('open')
        }

        btnClickGift = {
            onPress: () => {
                this.props.openGiftCardModal()
                this.setState({openGiftModal: false})
            }
        }

        btnClickGiftSub = {
            onPress: () => {
                this.props.openGiftSubModal()
                this.setState({showGiftSubModal: false})
                this.setState({openGiftModal: false})
            }
        }

        if (likeLoading) {
            btnClickLike = {
                onPress: () => { }
            }
        }
        else {
            btnClickLike = {
                onPress: () => applyStoryActions("like", (storyActions && storyActions.like) ? false : true, () => this.animateLike())
            }
        }
        if (process.browser) {
            if (navigator.share) {
                allowShare = true;
            }
            let path = window.location.origin + window.location.pathname;
            btnClickFB = {
                href: `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${shareTitle}`
            }
            btnClickTwitter = {
                href: `https://twitter.com/intent/tweet?text=${shareTitle}&url=${path}`
            }
            btnClickLinkdin = {
                href: `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${shareTitle}`
            }
        } else {
            allowShare = true;
        }
        let showSaved = (((storyActions && storyActions.bookmark) || bookmarkLoading == 'off') && bookmarkLoading != 'on');
        return (
            <>
                <div className={process.browser ? "postsingle-share" : ""} style={{ opacity: 0, maxWidth: 170, position: 'absolute', height: '100%', paddingLeft: 4 }}>
                    <View style={[postContent && { marginTop: 20 }, { position: 'relative', height: '100%' }]}>
                        <View style={{ position: 'sticky', top: 120, left: 5 }}>
                            <View>
                                {/*data.category && <Text style={[articleLists.badge, { textAlign: 'left', fontFamily: FontFamily.regular, color: darkMode ? Colors.darkBlue : '#907cff', fontSize: 13, lineHeight: 29, letterSpacing: 0.5 }]}>{data.category.name}</Text>*/}
                                <Text style={[Margin.mb_15, { fontFamily: FontFamily.merriweatherB, fontSize: 14, lineHeight: 21, width: 132, paddingTop: 4, letterSpacing: 0.5, color: darkMode ? Colors.lightWhite : '#000' }]}>{postTitle}</Text>
                                <View style={[Flex.row, Flex.justifyStart, Flex.alignCenter]}>
                                    <TouchableOpacity {...btnClickBookmark}>
                                        <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, Margin.mr_2, { width: 75, height: 38, backgroundColor: darkMode ? (showSaved ? 'rgba(82, 99, 244, 0.1)' : 'rgba(243, 243, 244, 0.1)') : '#F3F3F4', borderColor: darkMode ? (showSaved ? 'rgba(82, 99, 244, 0.1)' : 'rgba(243, 243, 244, 0.1)') : '#F3F3F4', borderWidth: 1, borderRadius: 1 }]}>
                                            {showSaved ?
                                                <Text style={{ fontSize: 12, lineHeight: 32, fontFamily: FontFamily.merriweather, letterSpacing: 0.5, fontWeight: '400', color: '#5263F4' }}>Saved</Text>
                                                :
                                                <Text style={{ fontSize: 12, lineHeight: 32, fontFamily: FontFamily.merriweather, letterSpacing: 0.5, fontWeight: '400', color: darkMode ? Colors.lightWhite : '#2B2B2B' }}>Save</Text>
                                            }
                                            <>
                                                {
                                                    this.props.fromPage !== "yesterday" ? (
                                                        <>
                                                            {bookmarkLoading ?
                                                                <Animated.View style={[{ transform: [{ translateY: loadingLogo }] }]}>
                                                                    <CustomImage
                                                                        source={appLogo}
                                                                        require={true}
                                                                        style={{ width: 15, height: 15, resizeMode: 'contain', paddingLeft: 7 }}
                                                                        webStyle={{ width: 15, height: 15, objectFit: 'contain', paddingLeft: 7 }}
                                                                    />
                                                                </Animated.View>
                                                                :
                                                                <CustomImage
                                                                    source={(storyActions && storyActions.bookmark) ? bookmarkIconSelected : (darkMode ? bookmarkIconLightOutline : bookmarkIcon)}
                                                                    require={true}
                                                                    style={{ width: 21, height: 21, resizeMode: 'contain', paddingLeft: 7, marginLeft: -5, marginRight: -5 }}
                                                                    webStyle={{ width: 21, height: 21, objectFit: 'contain', paddingLeft: 7, marginLeft: -5, marginRight: -5 }}
                                                                />
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                            </>
                                        </View>
                                    </TouchableOpacity>
                                    {
                                        true ? (
                                            <TouchableOpacity {...btnClickLike}
                                                style={[postActionBar.actionBtn, { paddingRight: 0, marginRight: 0 }]}>
                                                <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween]}>
                                                    {/*<Animated.View style={{
                                                position: 'absolute',
                                                height: animatedLike.interpolate({
                                                    inputRange: [0, 47],
                                                    outputRange: [1, 47]
                                                }),
                                                width: animatedLike.interpolate({
                                                    inputRange: [0, 47],
                                                    outputRange: [1, 47]
                                                }),
                                                borderRadius: '50%',
                                                backgroundColor: '#C4C4C4',
                                                zIndex: -1,
                                                left: animatedLike.interpolate({
                                                    inputRange: [0, 47],
                                                    outputRange: [8, -15]
                                                }),
                                                opacity: animatedLike.interpolate({
                                                    inputRange: [0, 17, 45, 47],
                                                    outputRange: [0, 1, 1, 0]
                                                })
                                            }}></Animated.View>*/}
                                                    <CustomImage
                                                        source={(storyActions && storyActions.like) ? likeIconSelected : (darkMode ? likeIconLight : likeIcon)}
                                                        require={true}
                                                        style={{ width: 17, height: 17 }}
                                                        webStyle={{ width: 17, height: 17 }}
                                                    />
                                                    {/*<Text style={{ fontSize: 12, lineHeight: 28, fontFamily: FontFamily.merriweather, letterSpacing: 0.5, fontWeight: '400', color: (storyActions && storyActions.like) ? '#ED5E5E' : (darkMode ? Colors.lightWhite : '#2B2B2B'), paddingLeft: 5 }}>{(!likeLoading && storyActions.total_likes) ? helpers.convertLikeCountToString(storyActions.total_likes,1) : ""}</Text>*/}
                                                    <Text style={{ fontSize: 12, lineHeight: 28, fontFamily: FontFamily.merriweather, letterSpacing: 0.5, fontWeight: '400', color: (storyActions && storyActions.like) ? '#ED5E5E' : (darkMode ? Colors.lightWhite : '#2B2B2B'), paddingLeft: 5 }}>{""}</Text>
                                                </View>
                                            </TouchableOpacity>

                                        ) : null
                                    }
                                </View>
                            </View>
                            <View style={[Margin.mb_15, Margin.mt_2, { borderBottomWidth: 0.3, borderColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)' }]} />
                            <View>
                                <View>
                                    <Text style={{ fontSize: 12, lineHeight: 32, fontFamily: FontFamily.merriweatherB, letterSpacing: 0.3, marginBottom: 4, color: darkMode ? Colors.lineColor : '#707070', letterSpacing: 0.5 }}> Share:</Text>
                                </View>
                                <View style={[Flex.row, Flex.alignCenter]}>
                                    <TouchableOpacityLinkExternal {...btnClickFB} newTab={true}>
                                        <View style={[Flex.row]}>
                                            <CustomImage
                                                source={darkMode ? fbIconLight : fbIcon}
                                                require={true}
                                                style={{ width: 22, height: 26, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 22, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                                altText={'Facebook'}
                                            />
                                        </View>
                                    </TouchableOpacityLinkExternal>
                                    <TouchableOpacityLinkExternal {...btnClickTwitter} newTab={true}>
                                        <View style={[Flex.row]}>
                                            <CustomImage
                                                source={darkMode ? twitterIconLight : twitterIcon}
                                                require={true}
                                                style={{ width: 22, height: 26, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 22, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                                altText={'Twitter'}
                                            />
                                        </View>
                                    </TouchableOpacityLinkExternal>
                                    {giftStory &&
                                        <TouchableOpacity>
                                            <View style={[Flex.row]}>
                                                <CustomImage
                                                    source={darkMode ? giftLight : giftDark}
                                                    require={true}
                                                    style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                                    webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                                />
                                            </View>
                                        </TouchableOpacity>
                                    }

                                    {/*<TouchableOpacityLinkExternal {...btnClickLinkdin} newTab={true}>
                                        <View style={[Flex.row, Margin.mb_15]}>
                                            <CustomImage
                                                source={linkedIcon}
                                                require={true}
                                                style={{ width: 22, height: 26, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 22, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                                altText={'LinkedIn'}
                                            />
                                            <Text style={{fontSize: 18, lineHeight: 28, fontFamily: FontFamily.merriweather, letterSpacing: 0.3}}>Linkedin</Text>
                                        </View>
                                    </TouchableOpacityLinkExternal>*/}
                                </View>
                                {giftStory && <><View style={{alignSelf: 'flex-end', paddingRight: '37%'}}>
                                <CustomImage
                                    source={triangleIcon}
                                    require={true}
                                    style={{ width: 15, height: 15, resizeMode: 'contain', paddingRight: 15 }}
                                    webStyle={{ width: 15, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                />
                                </View>
                                <View style={[Flex.column, Flex.justfiyCenter, Flex.alignCenter, {backgroundColor: Colors.giftingBackground, borderRadius: 10, paddingTop: 15, paddingHorizontal: 8, width: '90%', alignSelf: 'flex-start', textAlign: 'center', marginRight: '10%'}, boxShadowStyle ]}>
                                    <Text style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.75rem', borderBottomColor: Colors.primary, borderBottomWidth: 1}}>Gift this Story</Text>
                                    <Text style={{fontSize: '0.65rem', marginTop: 5, width: '100%', fontFamily: FontFamily.lora, letterSpacing: 1}}>Give this subscriber-only story to a friend and help them read it for free.</Text>
                                    <View style={[Flex.column, {marginTop: 5, width: '100%'}]}>

                                    </View>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} onPress={this.onCopyButtonClick}  >
                                        <CustomImage
                                            source={copyIcon}
                                            require={true}
                                            style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                            webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>{this.state.copyButtonText}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} onPress={this.handleWhatsappShare}>
                                        <CustomImage
                                                source={whatsappIcon}
                                                require={true}
                                                style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>WhatsApp</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} {...btnClickGift}>
                                        <CustomImage
                                                source={emailIcon}
                                                require={true}
                                                style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>Email</Text>
                                    </TouchableOpacity>
                                    {this.state.subsLeft ? 
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingTop: 6, paddingBottom: 10}]} {...btnClickGiftSub}>
                                        <Text style={{fontWeight: '700', fontSize: '0.75rem'}}>GIFT A 30-DAY SUBSCRIPTION</Text>
                                    </TouchableOpacity> : null}
                                </View></>}
                            </View>
                        </View>
                    </View>
                </div>
                {!helpers.isDesktop() &&
                    <View style={[postSingle.wrapper, Flex.row, Flex.alignCenter, { backgroundColor: darkMode ? Colors.darkBlackColor1 : 'white', zIndex: 1 }]}>
                        <View style={[Flex.fill, Flex.row, Flex.alignCenter, postSingle.buttons, Padding.ph_2, { color: darkMode ? Colors.lightWhite : '#343434', backgroundColor: darkMode ? Colors.darkBlackColor1 : 'white', height: 50 }]}>
                            <Text style={[postSingle.buttons, { color: darkMode ? Colors.lightWhite : '#343434' }]} numberOfLines={2}>{postTitle}</Text>
                        </View>
                        {
                            this.props.fromPage !== "yesterday" ? (
                                <>
                                    <TouchableOpacity {...btnClickLike}
                                        style={[postActionBar.actionBtn, { paddingRight: 0, marginRight: 0 }]}>
                                        <Animated.View style={{
                                            position: 'absolute',
                                            height: animatedLike.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [1, 52]
                                            }),
                                            width: animatedLike.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [1, 52]
                                            }),
                                            borderRadius: '50%',
                                            backgroundColor: '#C4C4C4',
                                            zIndex: -1,
                                            left: animatedLike.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [12, -14]
                                            }),
                                            top: animatedLike.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [16, -9]
                                            }),
                                            opacity: animatedLike.interpolate({
                                                inputRange: [0, 24, 45, 47],
                                                outputRange: [0, 1, 1, 0]
                                            })
                                        }}></Animated.View>
                                        <CustomImage
                                            source={(storyActions && storyActions.like) ? likeIconSelected : (darkMode ? likeIconLight : likeIcon)}
                                            require={true}
                                            style={{ width: 24, height: 24 }}
                                            webStyle={{ width: 24, height: 24 }}
                                        />
                                    </TouchableOpacity>

                                    <TouchableOpacity {...btnClickBookmark}>
                                        <Animated.View style={{
                                            position: 'absolute',
                                            height: animatedBookmark.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [1, 52]
                                            }),
                                            width: animatedBookmark.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [1, 52]
                                            }),
                                            borderRadius: '50%',
                                            backgroundColor: '#C4C4C4',
                                            zIndex: -1,
                                            left: animatedBookmark.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [29, 4]
                                            }),
                                            top: animatedBookmark.interpolate({
                                                inputRange: [0, 47],
                                                outputRange: [10, -15]
                                            }),
                                            opacity: animatedBookmark.interpolate({
                                                inputRange: [0, 24, 45, 47],
                                                outputRange: [0, 1, 1, 0]
                                            })
                                        }}></Animated.View>
                                        <CustomImage
                                            source={(storyActions && storyActions.bookmark) ? bookmarkIconSelected : (darkMode ? bookmarkIconLightOutline : bookmarkIcon)}
                                            require={true}
                                            style={{ width: 30, height: 24, resizeMode: 'contain', paddingLeft: 20, paddingRight: 20, marginLeft: -5, marginRight: -5 }}
                                            webStyle={{ width: 30, height: 24, objectFit: 'contain', paddingLeft: 20, paddingRight: 20, marginLeft: -5, marginRight: -5 }}
                                        />
                                    </TouchableOpacity>
                                </>
                            ) : null
                        }
                        {giftStory &&
                            // <TouchableOpacity {...btnClickGift}>
                            <TouchableOpacity onPress={this.handleGiftButtonClickMob}>
                                <CustomImage
                                    source={darkMode ? giftLight : giftDark}
                                    require={true}
                                    style={{ width: 20, height: 24, resizeMode: 'contain', paddingRight: 20 }}
                                    webStyle={{ width: 20, height: 24, objectFit: 'contain', paddingRight: 20 }}
                                />
                            </TouchableOpacity>
                        }
                        {giftStory && this.state.openGiftModal && 
                            <Modal transparent onDismiss={this.handleGiftButtonClickMob}>
                                <TouchableWithoutFeedback onPress={this.handleGiftButtonClickMob}>
                                <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', flex: 1}}>
                                    <View style={{height: 'auto', width:140, backgroundColor: 'white', position: 'absolute', bottom: 50, right: 20, borderRadius: 10, display: 'flex'}}>
                                    <View style={[Flex.column, Flex.justfiyCenter, Flex.alignCenter, {backgroundColor: Colors.giftingBackground, borderRadius: 10, paddingTop: 15, paddingHorizontal: 8, width: '100%', alignSelf: 'flex-start', textAlign: 'center', marginRight: '10%'}, boxShadowStyle ]}>
                                    <Text style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.75rem', borderBottomColor: Colors.primary, borderBottomWidth: 1}}>Gift this Story</Text>
                                    <Text style={{fontSize: '0.65rem', marginTop: 5, width: '100%', fontFamily: FontFamily.lora, letterSpacing: 1}}>Give this subscriber-only story to a friend and help them read it for free.</Text>
                                    <View style={[Flex.column, {marginTop: 5, width: '100%'}]}>

                                    </View>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} onPress={this.onCopyButtonClick} >
                                        <CustomImage
                                            source={copyIcon}
                                            require={true}
                                            style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                            webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>{this.state.copyButtonText}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} onPress={this.handleWhatsappShare}>
                                        <CustomImage
                                                source={whatsappIcon}
                                                require={true}
                                                style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>WhatsApp</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%'}]} {...btnClickGift}>
                                        <CustomImage
                                                source={emailIcon}
                                                require={true}
                                                style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                                                webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                                        />
                                        <Text style={{fontWeight: '400', fontSize: '0.75rem'}}>Email</Text>
                                    </TouchableOpacity>
                                    {this.state.subsLeft ? <TouchableOpacity style={[Flex.row, {alignSelf: 'flex-start', paddingTop: 6, paddingBottom: 10}]} {...btnClickGiftSub}>
                                        <Text style={{fontWeight: '700', fontSize: '0.75rem'}}>GIFT A 30-DAY SUBSCRIPTION</Text>
                                    </TouchableOpacity> : null}
                                </View>
                                    </View>
                                </View>
                                </TouchableWithoutFeedback>
                            </Modal>
                        }
                        {(allowShare || this.props.allowShare) &&
                            <TouchableOpacity {...btnClickShare}>
                                <CustomImage
                                    source={darkMode ? shareIconLight : shareIconDark}
                                    require={true}
                                    style={{ width: 20, height: 24, resizeMode: 'contain', paddingRight: 20 }}
                                    webStyle={{ width: 20, height: 24, objectFit: 'contain', paddingRight: 20 }}
                                />
                            </TouchableOpacity>
                        }
                    </View>
                }
            </>
        );
    }
}

export default PostShare;