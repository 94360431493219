import React, { Component } from 'react';
import { Text, View, TouchableOpacity, ActivityIndicator, Dimensions, ScrollView, PermissionsAndroid, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Colors, FontFamily, FontWeight, button, modalStyle } from '../../styles/appStyles';
import Modal from '../modal';
import UploadFile from '../upload-file';
import FileCard from '../../components/file-card';
import { helpers } from '../../utils/helpers';
import docHelpers from '../../utils/download-doc';
import ButtonGradient from '../../components/button-gradient';
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import moment from 'moment';
import { ProgressCircle } from 'react-native-material-indicators';
import ImageViewer from '../image-viewer';
import PdfViewer from '../pdf-viewer';
import CustomCamera from '../custom-camera';

const windowHeight = Dimensions.get('window').height;

const stepOneActive = getImagePath('icons/stepOneActive.png');
const stepOneFailed = getImagePath('icons/stepOneFailed.png');
const stepTwo = getImagePath('icons/steptwo.png');
const stepTwoActive = getImagePath('icons/steptwoActive.png');
const stepThree = getImagePath('icons/stepThree.png');
const stepComplete = getImagePath('icons/stepComplete.png');
const closeIcon = getImagePath('icons/close-round.png');

class VerifyDocumentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            selectedFiles: this.props.submittedFiles,
            base64: '',
            btnDisabled: true,
            documentStatus: this.props.documentStatus,
            disableDownload: false,
            enableLoader: false,
            openStudentModalLocally: this.props.openStudentModal,
            documentUploadDate: this.props.documentUploadDate,
            filesSubmitted: false
        }
    }

    componentDidMount = () => {
    }

    componentDidUpdate = () => {

    }

    componentWillUnmount() {

    }

    closeModalNativeModal = () => {
        let { base64ImageData, base64PdfData } = this.state;
        if (base64ImageData || base64PdfData) {
            this.setState({ base64ImageData: false, base64PdfData: false })
        }
        else {
            this.closeModal()
        }
    }

    closeModal = () => {
        const { closeVerificationModal } = this.props;
        let { filesSubmitted } = this.state;
        this.setState({
            documentStatus: 'submitted'
        })
        closeVerificationModal(filesSubmitted);
    }

    selectFile = (file, base64 = '') => {
        let { selectedFiles } = this.state;
        if (file) {
            if (file.type == 'application/pdf' || file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
                if (file.size > 5 * 1000 * 1000) {
                    this.setState({
                        errorText: 'File size uploaded exceeds 5 MB'
                    });
                } else if (selectedFiles.length == 3) {
                    this.setState({
                        errorText: 'Max 3 files can be uploaded'
                    });
                } else {
                    let tempFiles = this.state.selectedFiles;
                    let tempFile = { detail: { name: file.name, type: file.type, size: file.size }, base64: base64 };
                    let duplicateTempFiles = tempFiles.find(({ detail }) => detail.name == file.name);
                    console.log(duplicateTempFiles);
                    if (!duplicateTempFiles) {
                        tempFiles.push(tempFile);
                    }
                    this.setState({
                        selectedFiles: tempFiles,
                        btnDisabled: false,
                        errorText: (duplicateTempFiles ? 'Cannot upload the same file' : '')
                    });
                }
            }
            else {
                this.setState({
                    errorText: 'File type not supported'
                });
            }
        }
        else {
            this.setState({
                selectedFiles: [],
                btnDisabled: true
            });
        }
    }

    renderTopSteps = (documentStatus) => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View>
                {
                    !helpers.isDesktop() &&
                    <View style={[Margin.mb_2, Flex.justfiyCenter]}>
                        {
                            ((documentStatus == 'rejected')) &&
                            <Text style={{ fontSize: 16, color: '#FF6B75', textAlign: 'center', fontFamily: FontFamily.merriweatherB }}>Upload documents</Text>
                        }
                        {
                            (!documentStatus || (documentStatus == 're-upload')) &&
                            <Text style={{ fontSize: 16, color: Colors.green, textAlign: 'center', fontFamily: FontFamily.merriweatherB }}>Upload documents</Text>
                        }
                        {
                            (documentStatus == 'submitted') &&
                            <Text style={{ fontSize: 16, color: Colors.green, textAlign: 'center', fontFamily: FontFamily.merriweatherB }}>Documents in Review</Text>
                        }
                        {
                            (documentStatus == 'approved') &&
                            <Text style={{ fontSize: 16, color: Colors.green, textAlign: 'center', fontFamily: FontFamily.merriweatherB }}>Documents Verified</Text>
                        }
                    </View>
                }

                <View style={[Flex.row, Flex.alignCenter, { justifyContent: helpers.isDesktop() ? 'flex-start' : 'center' }]}>
                    <View style={{ marginRight: 10 }}>
                        <CustomImage
                            require={true}
                            source={(!documentStatus || (documentStatus == 're-upload') || (documentStatus == 'rejected')) ? ((documentStatus == 'rejected') ? stepOneFailed : stepOneActive) : stepComplete}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {
                        helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontWeight: (documentStatus == 'rejected') ? '700' : '400', fontFamily: FontFamily.merriweather, color: (!documentStatus || (documentStatus == 're-upload') || (documentStatus == 'rejected')) ? ((documentStatus == 'rejected') ? '#FF6B75' : Colors.green) : darkMode ? Colors.lightWhite : '#000000' }}>Upload documents</Text>
                    }

                    <View style={{ width: helpers.isDesktop() ? 40 : 50, backgroundColor: '#BABABA', height: 1, marginLeft: 5, marginRight: 5 }}></View>

                    <View style={{ marginRight: 10 }}>
                        <CustomImage
                            require={true}
                            source={(documentStatus == 'submitted' || documentStatus == 'approved') ? (documentStatus == 'submitted' ? stepTwoActive : stepComplete) : stepTwo}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontFamily: FontFamily.merriweather, color: (documentStatus == 'submitted' || documentStatus == 'approved') ? (documentStatus == 'submitted' ? Colors.green : darkMode ? Colors.lightWhite : '#000000') : '#9E9E9E' }}>Documents in Review</Text>
                    }

                    <View style={{ width: helpers.isDesktop() ? 40 : 50, backgroundColor: '#BABABA', height: 1, marginLeft: 5, marginRight: 5 }}></View>

                    <View style={{ marginRight: helpers.isDesktop() ? 10 : 0 }}>
                        <CustomImage
                            require={true}
                            source={(documentStatus == 'approved') ? stepComplete : stepThree}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontFamily: FontFamily.merriweather, color: (documentStatus == 'approved') ? (darkMode ? Colors.lightWhite : '#000000') : '#9E9E9E' }}>Documents Verified</Text>
                    }
                </View>
            </View>
        )
    }

    removeCard = (name) => {
        console.log("namerrrrrrrrrrrrr", name)
        let { selectedFiles, btnDisabled } = this.state;
        let tempFiles = selectedFiles;
        tempFiles = tempFiles.filter((file) => {
            if ((file.detail ? file.detail.name : file.name) !== name) {
                return file
            }
        })
        this.setState({
            selectedFiles: tempFiles,
            errorText: '',
            btnDisabled: tempFiles.length == 0
        });
    }

    handleSubmitFiles = () => {
        const { token } = this.props;
        let endpoint = `/upload-documents`;
        let body = {
            files: this.state.selectedFiles
        }

        this.setState({
            disableDownload: true,
            enableLoader: true
        })
        // console.log("body  ==>", JSON.stringify(body));
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return false;
                }
                if (res.success) {
                    this.setState({
                        documentStatus: 'submitted',
                        selectedFiles: res.data.files,
                        documentUploadDate: res.data.uploaded_on,
                        disableDownload: false,
                        enableLoader: false,
                        filesSubmitted: true
                    })
                } else {
                    console.log('Error up   =>>', res);
                }
            })
            .catch((error) => {
                console.log('Error  down =>>', error);
            });
    }

    backToUpload = () => {
        this.setState({
            documentStatus: 're-upload',
            selectedFiles: [],
            btnDisabled: true
        })
    }

    handleViewStories = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/all-stories')
        }
        else {
            this.props.closeVerificationModal();
            this.props.navigateToScreen('/all-stories', {})
        }
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    downloadFile = async (name) => {
        const { token } = this.props;
        let endpoint = `/fetch-document`;
        let body = {
            file_name: name
        }

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return false;
                }
                if (res.success) {

                    if (!helpers.isWeb()) {
                        if (res.data.type == 'application/pdf') {
                            let base_64 = `data:application/pdf;base64,${res.data.file_string}`;
                            this.setState({ base64PdfData: base_64 })
                        }
                        else {
                            let base_64 = `data:${res.data.type};base64,${res.data.file_string}`
                            this.setState({ base64ImageData: base_64 })
                        }
                    }
                    else if(helpers.isWeb() && window && window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage( JSON.stringify(res.data));
                    }
                    else {
                        const blob = this.b64toBlob(res.data.file_string, res.data.type);
                        var url = URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = `${res.data.name}`;
                        a.click();
                    }

                } else {
                    console.log('Error=>>', res);
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
            });

    }

    closeCamera = () => {
        this.setState({ showCamera: false }, () => {
            setTimeout(() => {
                this.appscrollview.scrollTo({ y: 600, animated: true })
            }, 300)
        })
    }

    lauchCustomCamera = () => {
        this.setState({ showCamera: true })
    }

    renderUploadDoc = () => {
        let { errorText, selectedFiles, btnDisabled } = this.state;
        let { userProfile, token, logout } = this.props;
        let darkMode = this.props.darkMode;
        return (
            <View style={[Padding.pt_4, Padding.pb_1]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Verify your student identity</Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>In order to activate your subscription, we will need proof to validate that you are a student. <Text style={{ fontWeight: '600' }}>You will not be able to access stories until you submit your documents</Text></Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>Upload the below documents as proof (any one).</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#000000', lineHeight: 30 }}>ID proof or Admission letter</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={[{ marginBottom: 40 }, helpers.isDesktop() && { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
                    <View>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                        </View>
                        <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                            <View>
                                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userProfile.name}</Text></Text>
                            </View>
                            <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userProfile.email}</Text></Text>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweatherB }}>{moment(userProfile.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                        </View>
                    </View>
                    {
                        <View style={[Flex.row, Flex.alignEnd, !helpers.isDesktop() && Margin.mt_1]}>
                            <Text>
                                <Text style={{ fontSize: 16, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.6)' }}>Not you?</Text>
                                <Text style={{ fontSize: 18, fontFamily: FontFamily.regular, color: '#F02121', marginLeft: 7 }} onPress={() => logout()}> LOGOUT</Text>
                            </Text>
                        </View>
                    }
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        selectedFiles.length != 3 &&
                        <UploadFile uploadDoc={(file, base64) => this.selectFile(file, base64)} darkMode={darkMode} lauchCustomCamera={this.lauchCustomCamera} />
                    }
                    <Text style={{ textAlign: 'right', color: '#F02121', fontSize: 13, fontFamily: FontFamily.merriweatherB, marginTop: 10 }}>{errorText}</Text>
                    {
                        selectedFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {selectedFiles.map((file, index) => {
                                return (
                                    <FileCard key={index} name={file.detail ? file.detail.name : file.name} removeCard={(name) => { this.removeCard(name) }} darkMode={darkMode} />
                                )
                            })}
                        </View>
                    }
                </View>
                <View>
                    <ButtonGradient
                        title={'SUBMIT'}
                        rootStyle={{
                            btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 16 : 14, paddingLeft: 50, paddingRight: 50, paddingTop: 13, paddingBottom: 13, fontWeight: '400' }]
                        }}
                        onClick={() => { this.handleSubmitFiles() }}
                        disable={btnDisabled}
                        darkMode={darkMode}
                    />
                </View>
            </View>
        )
    }

    renderDocsInReview = (selectedFiles) => {
        let { userProfile } = this.props;

        let { documentUploadDate } = this.state;

        // let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let darkMode = this.props.darkMode;
        console.log("daadadadaddadad", darkMode)
        return (
            <View style={[Padding.pt_4, Padding.pb_1]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Documents in review</Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>Thank you for submitting your documents. We are currently verifying them and it will take a few days. You can however access our stories and newsletters.</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>Documents submitted for verification on {moment(documentUploadDate).format('Do MMMM YYYY')}</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userProfile.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userProfile.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweatherB }}>{moment(userProfile.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        selectedFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {selectedFiles.map((file) => {
                                return (
                                    <FileCard name={file.detail ? file.detail.name : file.name} removeCard={(name) => { this.removeCard(name) }} showDownload={true} downloadFile={(name) => { this.downloadFile(name) }} darkMode={darkMode} disableDownload={this.state.disableDownload} />
                                )
                            })}
                        </View>
                    }
                </View>
                <View>
                    <Text style={{ fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(45,45,45,0.89)' }}>If you want to re-upload your proof, <Text style={[{ textDecorationLine: 'underline', fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : 'rgba(45,45,45,0.89)' }]} onPress={() => this.backToUpload()}>click here</Text>. Doing this will submit fresh proof and verification process.</Text>
                </View>
                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>
                <View>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#2d2d2d', textAlign: 'center', marginBottom: 40 }]}>Get started by accessing our latest stories</Text>
                    <ButtonGradient
                        title={'VIEW STORIES'}
                        rootStyle={{
                            btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 16 : 14, paddingLeft: 10, paddingRight: 10, paddingTop: 13, paddingBottom: 13, fontWeight: '400' }]
                        }}
                        onClick={() => this.handleViewStories()}
                        darkMode={darkMode}
                        smallBtn={true}
                    />
                </View>
            </View>
        )
    }

    renderDocsVerified = (submittedFiles) => {
        let { userProfile } = this.props;
        let darkMode = this.props.darkMode;
        return (
            <View style={[Padding.pt_4, Padding.pb_1]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>You have been verified</Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>We have verified your documents and have activated your account.</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: darkMode ? Colors.lightWhite : '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>Documents verified on {moment(userProfile.document_verified_on).format('Do MMMM YYYY')}</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userProfile.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userProfile.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweatherB }}>{moment(userProfile.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Valid till: <Text style={{ fontFamily: FontFamily.merriweatherB }}>{moment(userProfile.subscription.end_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        submittedFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {submittedFiles.map((file) => {
                                return (
                                    <FileCard name={file.detail ? file.detail.name : file.name} removeCard={(name) => { this.removeCard(name) }} darkMode={darkMode} showDownload={true} downloadFile={(name) => { this.downloadFile(name) }} />
                                )
                            })}
                        </View>
                    }
                </View>
                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 10, marginBottom: 40 }}></View>
                <View>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#2d2d2d', textAlign: 'center', marginBottom: 40 }}>Continue to view and read all our latest stories</Text>
                    <ButtonGradient
                        title={'CONTINUE'}
                        rootStyle={{
                            btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 16 : 14, paddingLeft: 50, paddingRight: 50, paddingTop: 13, paddingBottom: 13, fontWeight: '400' }]
                        }}
                        onClick={() => this.handleViewStories()}
                        darkMode={darkMode}
                    />
                </View>
            </View>
        )
    }

    renderdocumentsRejected = () => {
        let { errorText, selectedFiles, btnDisabled } = this.state;
        let { userProfile } = this.props;
        let darkMode = this.props.darkMode;
        console.log("selectedFilesRRRRRR", selectedFiles)
        return (
            <View style={[Padding.pt_4, Padding.pb_1]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Your documents were rejected</Text>
                <View style={{ borderWidth: 1, borderColor: '#FF6B75', borderRadius: 4, paddingHorizontal: 20, paddingVertical: 15, marginBottom: 40, marginTop: 20 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', marginBottom: 8 }}>Your documents were rejected for the following reason:</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : '#000000' }}>{userProfile.document_reject_reason}</Text>
                </View>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>Please re-upload your proof for validation. <Text style={{ fontFamily: FontFamily.merriweatherB }}>Since your documents were rejected, you will not be able to access stories until we validate your documents.</Text></Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: helpers.isDesktop() ? 30 : 22 }]}>Upload the below documents as proof (any one).</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#000000', lineHeight: helpers.isDesktop() ? 30 : 22 }}>ID proof or Admission letter</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userProfile.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userProfile.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweatherB }}>{moment(userProfile.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        selectedFiles.length != 3 &&
                        <UploadFile uploadDoc={(file, base64) => this.selectFile(file, base64)} darkMode={darkMode} lauchCustomCamera={this.lauchCustomCamera} />
                    }
                    <Text style={{ textAlign: 'right', color: '#F02121', fontSize: 13, fontFamily: FontFamily.merriweatherB, marginTop: 10 }}>{errorText}</Text>
                    {
                        selectedFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {selectedFiles.map((file) => {
                                return (
                                    <FileCard name={file.detail ? file.detail.name : file.name} removeCard={(name) => { this.removeCard(name) }} darkMode={darkMode} />
                                )
                            })}
                        </View>
                    }
                </View>
                <View>
                    <ButtonGradient
                        title={'SUBMIT'}
                        rootStyle={{
                            btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 16 : 14, paddingLeft: 50, paddingRight: 50, paddingTop: 13, paddingBottom: 13, fontWeight: '400' }]
                        }}
                        onClick={() => { this.handleSubmitFiles() }}
                        disable={btnDisabled}
                        darkMode={darkMode}
                        smallBtn={true}
                    />
                </View>
            </View>
        )
    }

    renderSubscriptionModalContent = () => {

        let { documentStatus, selectedFiles, enableLoader } = this.state;
        let { openStudentModal } = this.props;
        let darkMode = this.props.darkMode;

        return (
            <>
                {enableLoader ?
                    <View style={{
                        position: 'relative',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: darkMode ? Colors.darkBlackColorBg1 : '#ffffff',
                        zIndex: 999
                    }}>
                        <View style={[Flex.justfiyCenter, Flex.alignCenter,
                        helpers.isWeb() && {
                            height: 'calc(100vh - 189px)',
                        },
                        {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: darkMode ? Colors.darkBlackColorBg1 : '#ffffff'
                        }, !helpers.isWeb() && { height: Dimensions.get('window').height }]}
                        >
                            <View style={{ marginTop: !helpers.isWeb() ? -20 : 0 }}>
                                <ProgressCircle color={'#907cff'} size={186} widthOfBorder={25} />
                            </View>
                        </View>
                    </View>
                    :
                    <View style={{ padding: 25 }}>
                        {this.renderTopSteps(documentStatus)}
                        {(!documentStatus || (documentStatus == 're-upload')) && this.renderUploadDoc()}
                        {(documentStatus == 'submitted' && openStudentModal && selectedFiles) && this.renderDocsInReview(selectedFiles)}
                        {(documentStatus == 'approved' && openStudentModal) && this.renderDocsVerified(selectedFiles)}
                        {documentStatus == 'rejected' && this.renderdocumentsRejected()}
                    </View>
                }
            </>
        )
    }

    render() {
        let { documentStatus, base64ImageData, base64PdfData, showCamera } = this.state;
        let darkMode = this.props.darkMode;
        return (
            <View style={[{ zIndex: 9999 }, !helpers.isWeb() && { height: '100%', backgroundColor: darkMode ? Colors.darkBlackColor1 : '#ffffff' }]}>
                {
                    helpers.isWeb() ?
                        <Modal renderContent={() => this.renderSubscriptionModalContent()} closeModal={() => this.closeModal()} paddingHorizontal={true} topPadding={false} verifyModal={true} subscription={(!documentStatus || documentStatus == 'rejected')} />
                        :
                        <>
                            {showCamera &&
                                <View style={{ flex: 1, backgroundColor: 'black' }}>
                                    <CustomCamera closeCamera={() => { this.closeCamera() }} uploadDoc={(file, base64) => this.selectFile(file, base64)} />
                                </View>
                            }
                            {(!(!documentStatus || documentStatus == 'rejected') && !showCamera) &&
                                <View style={[{ position: 'absolute', right: 0, top: 0, zIndex: 1 }]}>
                                    <TouchableOpacity
                                        activeOpacity={0.8}
                                        style={[modalStyle.closeButton]}
                                        onPress={() => this.closeModalNativeModal()}
                                    >
                                        <CustomImage
                                            source={closeIcon}
                                            style={{ width: 28, height: 28 }}
                                            webStyle={{ width: 28, height: 28 }}
                                            require={true}
                                        />
                                    </TouchableOpacity>
                                </View>
                            }
                            <ScrollView ref={ref => this.appscrollview = ref} style={[{ flex: 1, }, showCamera && { display: 'none' }]}>
                                {this.renderSubscriptionModalContent()}
                            </ScrollView>
                            {base64ImageData && <ImageViewer base64={base64ImageData} closeModalNativeModal={this.closeModalNativeModal}/>}
                            {base64PdfData && <PdfViewer base64={base64PdfData} closeModalNativeModal={this.closeModalNativeModal}/>}
                        </>
                }
            </View >

        );
    }
}

export default VerifyDocumentModal;