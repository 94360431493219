const imagePaths = {
  "icons/mdif-investor-logo.jpeg": require("../../../assets/icons/mdif-investor-logo.jpeg"),
  "icons/accesOrangeIcon.png": require("../../../assets/icons/accesOrangeIcon.png"),
  "icons/arrow-gradient.png": require("../../../assets/icons/arrow-gradient.png"),
  "icons/giftingIcon.png": require("../../../assets/icons/giftingIcon.png"),
  "icons/studentIcon.png": require("../../../assets/icons/studentIcon.png"),
  "icons/teamsIcon.png": require("../../../assets/icons/teamsIcon.png"),
  "icons/exclusivePlusicon.png": require("../../../assets/icons/exclusivePlusicon.png"),
  "icons/add-member.png": require("../../../assets/icons/add-member.png"),
  "icons/admin-dashboard.png": require("../../../assets/icons/admin-dashboard.png"),
  "icons/adminStepOne.png": require("../../../assets/icons/adminStepOne.png"),
  "icons/adminSteptwoActive.png": require("../../../assets/icons/adminSteptwoActive.png"),
  "icons/analysis-dark.png": require("../../../assets/icons/analysis-dark.png"),
  "icons/analysis.png": require("../../../assets/icons/analysis.png"),
  "icons/angle-icon.png": require("../../../assets/icons/angle-icon.png"),
  "icons/annualGiftSub.png": require("../../../assets/icons/annualGiftSub.png"),
  "icons/apple-icon.png": require("../../../assets/icons/apple-icon.png"),
  "icons/archive.png": require("../../../assets/icons/archive.png"),
  "icons/quote-bg.jpg": require("../../../assets/icons/quote-bg.jpg"),
  "icons/quote-homebg.png": require("../../../assets/icons/quote-homebg.png"),
  "icons/black-quote.png": require("../../../assets/icons/black-quote.png"),
  "icons/arrow-bottom-dark.png": require("../../../assets/icons/arrow-bottom-dark.png"),
  "icons/arrow-down-dark.png": require("../../../assets/icons/arrow-down-dark.png"),
  "icons/arrow-left-dark.png": require("../../../assets/icons/arrow-left-dark.png"),
  "icons/arrow-right-dark.png": require("../../../assets/icons/arrow-right-dark.png"),
  "icons/arrow-right-orange.png": require("../../../assets/icons/arrow-right-orange.png"),
  "icons/arrow-right-white.png": require("../../../assets/icons/arrow-right-white.png"),
  "icons/arrow-top-dark.png": require("../../../assets/icons/arrow-top-dark.png"),
  "icons/arrowGreenUp.png": require("../../../assets/icons/arrowGreenUp.png"),
  "icons/arrowOrangeDown.png": require("../../../assets/icons/arrowOrangeDown.png"),
  "icons/arrowOrangeUp.png": require("../../../assets/icons/arrowOrangeUp.png"),
  "icons/assign-credits.png": require("../../../assets/icons/assign-credits.png"),
  "icons/back-arrow-sm-dark.png": require("../../../assets/icons/back-arrow-sm-dark.png"),
  "icons/back-arrow-sm-light-mode.png": require("../../../assets/icons/back-arrow-sm-light-mode.png"),
  "icons/back-arrow-sm.png": require("../../../assets/icons/back-arrow-sm.png"),
  "icons/back-arrow-white.png": require("../../../assets/icons/back-arrow-white.png"),
  "icons/back-arrow.png": require("../../../assets/icons/back-arrow.png"),
  "icons/benefits.png": require("../../../assets/icons/benefits.png"),
  "icons/billing-info.png": require("../../../assets/icons/billing-info.png"),
  "icons/black-dots.png": require("../../../assets/icons/black-dots.png"),
  "icons/blue-dots.png": require("../../../assets/icons/blue-dots.png"),
  "icons/bookmark-light.png": require("../../../assets/icons/bookmark-light.png"),
  "icons/bookmark-multiple-light.png": require("../../../assets/icons/bookmark-multiple-light.png"),
  "icons/bookmark-multiple-white.png": require("../../../assets/icons/bookmark-multiple-white.png"),
  "icons/bookmark-multiple.png": require("../../../assets/icons/bookmark-multiple.png"),
  "icons/bookmark-selected.png": require("../../../assets/icons/bookmark-selected.png"),
  "icons/bookmark-white.png": require("../../../assets/icons/bookmark-white.png"),
  "icons/bookmark.png": require("../../../assets/icons/bookmark.png"),
  "icons/buttonBG.png": require("../../../assets/icons/buttonBG.png"),
  "icons/buttonBgDark.png": require("../../../assets/icons/buttonBgDark.png"),
  "icons/buttonRightArrow.png": require("../../../assets/icons/buttonRightArrow.png"),
  "icons/buttonTopArrow.png": require("../../../assets/icons/buttonTopArrow.png"),
  "icons/buy-plan.png": require("../../../assets/icons/buy-plan.png"),
  "icons/cameraIcon.png": require("../../../assets/icons/cameraIcon.png"),
  "icons/cameraIconDark.png": require("../../../assets/icons/cameraIconDark.png"),
  "icons/chat.png": require("../../../assets/icons/chat.png"),
  "icons/check-minus.png": require("../../../assets/icons/check-minus.png"),
  "icons/check.png": require("../../../assets/icons/check.png"),
  "icons/checked.png": require("../../../assets/icons/checked.png"),
  "icons/checkedBox-dark.png": require("../../../assets/icons/checkedBox-dark.png"),
  "icons/checkedBox.png": require("../../../assets/icons/checkedBox.png"),
  "icons/checkedIcon.png": require("../../../assets/icons/checkedIcon.png"),
  "icons/checkedIconSq.png": require("../../../assets/icons/checkedIconSq.png"),
  "icons/choose-credits.png": require("../../../assets/icons/choose-credits.png"),
  "icons/close-icon-white.png": require("../../../assets/icons/close-icon-white.png"),
  "icons/close-icon.png": require("../../../assets/icons/close-icon.png"),
  "icons/close-round.png": require("../../../assets/icons/close-round.png"),
  "icons/close.png": require("../../../assets/icons/close.png"),
  "icons/coin.png": require("../../../assets/icons/coin.png"),
  "icons/communityIcon.png": require("../../../assets/icons/communityIcon.png"),
  "icons/communityIconDark.png": require("../../../assets/icons/communityIconDark.png"),
  "icons/corporatesBlueIcon.svg": require("../../../assets/icons/corporatesBlueIcon.svg"),
  "icons/giftBlueIcon.svg": require("../../../assets/icons/giftBlueIcon.svg"),
  "icons/triangle.svg": require("../../../assets/icons/triangle.svg"),
  "icons/triangle-icon.png": require("../../../assets/icons/triangle-icon.png"),
  "icons/copy-icon.svg": require("../../../assets/icons/copy-icon.svg"),
  "icons/whatsapp-icon.svg": require("../../../assets/icons/whatsapp-icon.svg"),
  "icons/email-icon.svg": require("../../../assets/icons/email-icon.svg"),
  "img/Mobilebanner.png": require("../../../assets/img/Mobilebanner.png"),
  "img/amod-img.png": require("../../../assets/img/amod-img.png"),
  "img/Abhay-Pandey.png": require("../../../assets/img/Abhay-Pandey.png"),
  "img/Amod-Malviya.png": require("../../../assets/img/Amod-Malviya.png"),
  "img/Harsh-Mariwala.png": require("../../../assets/img/Harsh-Mariwala.png"),
  "img/Nikhil-Kamath.png": require("../../../assets/img/Nikhil-Kamath.png"),
  "img/Nilesh-Shah.png": require("../../../assets/img/Nilesh-Shah.png"),
  "img/Sanjay-Bakshi.png": require("../../../assets/img/Sanjay-Bakshi.png"),
  "img/Sherbir-Panag.png": require("../../../assets/img/Sherbir-Panag.png"),
  "img/Uday-Shankar.png": require("../../../assets/img/Uday-Shankar.png"),
  "img/Rohit-Chandra.png": require("../../../assets/img/Rohit-Chandra.png"),
  "img/Amarjit-Batra.png": require("../../../assets/img/Amarjit-Batra.png"),

  "icons/couponIcon.png": require("../../../assets/icons/couponIcon.png"),
  "icons/couponIconWhite.png": require("../../../assets/icons/couponIconWhite.png"),
  "icons/cross-icon.png": require("../../../assets/icons/cross-icon.png"),
  "icons/cross.png": require("../../../assets/icons/cross.png"),
  "icons/dark-dots.png": require("../../../assets/icons/dark-dots.png"),
  "icons/default-company-logo-white.png": require("../../../assets/icons/default-company-logo-white.png"),
  "icons/default-company-logo.png": require("../../../assets/icons/default-company-logo.png"),
  "icons/dialogues-dark.png": require("../../../assets/icons/dialogues-dark.png"),
  "icons/dialogues.png": require("../../../assets/icons/dialogues.png"),
  "icons/dots.png": require("../../../assets/icons/dots.png"),
  "icons/download.png": require("../../../assets/icons/download.png"),
  "icons/dual-circle.png": require("../../../assets/icons/dual-circle.png"),
  "icons/dualCircleBlue.png": require("../../../assets/icons/dualCircleBlue.png"),
  "icons/dummyEmail.png": require("../../../assets/icons/dummyEmail.png"),
  "icons/editProfile.png": require("../../../assets/icons/editProfile.png"),
  "icons/ellipse.png": require("../../../assets/icons/ellipse.png"),
  "icons/email-icon-light.png": require("../../../assets/icons/email-icon-light.png"),
  "icons/email-icon.png": require("../../../assets/icons/email-icon.png"),
  "icons/email-verifed.png": require("../../../assets/icons/email-verifed.png"),
  "icons/email-verify.png": require("../../../assets/icons/email-verify.png"),
  "icons/email-warning.png": require("../../../assets/icons/email-warning.png"),
  "icons/email.png": require("../../../assets/icons/email.png"),
  "icons/emailInbox.png": require("../../../assets/icons/emailInbox.png"),
  "icons/emailQuote.png": require("../../../assets/icons/emailQuote.png"),
  "icons/emailQuoteSecond.png": require("../../../assets/icons/emailQuoteSecond.png"),
  "icons/emailVerified.png": require("../../../assets/icons/emailVerified.png"),
  "icons/instagram.png": require("../../../assets/icons/instagram.png"),
  "icons/youtube.png": require("../../../assets/icons/youtube.png"),
  "icons/facebook-b.png": require("../../../assets/icons/facebook-b.png"),
  "icons/facebook-dark.png": require("../../../assets/icons/facebook-dark.png"),
  "icons/facebook-light.png": require("../../../assets/icons/facebook-light.png"),
  "icons/failure-icon.png": require("../../../assets/icons/failure-icon.png"),
  "icons/fb.png": require("../../../assets/icons/fb.png"),
  "icons/fileNameIcon.png": require("../../../assets/icons/fileNameIcon.png"),
  "icons/fileNameIconDark.png": require("../../../assets/icons/fileNameIconDark.png"),
  "icons/fileRemoveIcon.png": require("../../../assets/icons/fileRemoveIcon.png"),
  "icons/fileRemoveIconDark.png": require("../../../assets/icons/fileRemoveIconDark.png"),
  "icons/fileuploadicon.png": require("../../../assets/icons/fileuploadicon.png"),
  "icons/font-icon.png": require("../../../assets/icons/font-icon.png"),
  "icons/footerFB.png": require("../../../assets/icons/footerFB.png"),
  "icons/checkWhite.png": require("../../../assets/icons/check-white.png"),
  "icons/footerIn.png": require("../../../assets/icons/footerIn.png"),
  "icons/footerTwitter.png": require("../../../assets/icons/footerTwitter.png"),
  "icons/galleryicon.png": require("../../../assets/icons/galleryicon.png"),
  "icons/galleryiconDark.png": require("../../../assets/icons/galleryiconDark.png"),
  "icons/gift-dark.png": require("../../../assets/icons/gift-dark.png"),
  "icons/gift-light.png": require("../../../assets/icons/gift-light.png"),
  "icons/gift-plan-icon.png": require("../../../assets/icons/gift-plan-icon.png"),
  "icons/gift.png": require("../../../assets/icons/gift.png"),
  "icons/giftEmailBanner.png": require("../../../assets/icons/giftEmailBanner.png"),
  "img/yesterdayBG.png": require("../../../assets/img/yesterdayBG.png"),
  "img/yesterdayBGmobile.png": require("../../../assets/img/yesterdayBGmobile.png"),
  "img/yesterdayImage2.png": require("../../../assets/img/yesterdayImage2.png"),
  "img/yesterdayImage2ForArchive.png": require("../../../assets/img/yesterdayImage2ForArchive.png"),
  "img/yesterdayImageDesktop3.png": require("../../../assets/img/yesterdayImageDesktop3.png"),
  "img/yesterdayImageMobile3.png": require("../../../assets/img/yesterdayImageMobile3.png"),
  "img/YesterdayLogo1.png": require("../../../assets/img/Yesterday-logo.png"),
  "img/tmcSstoryPage1Thumbnail.png": require("../../../assets/img/tmc-story-page-1-thumbnail.png"),
  "img/bg2.png": require("../../../assets/img/bg2.png"),
  "img/YesterdayLogoWjote.png": require("../../../assets/img/YesterdayLogoWjote.png"),
  "img/yesterdayImage.png": require("../../../assets/img/yesterdayImage.png"),
  "img/yesterdayImageMobile.png": require("../../../assets/img/yesterdayImageMobile.png"),

  "icons/rightArrow.png": require("../../../assets/icons/rightArrow.png"),
  "icons/logoIcon.png": require("../../../assets/icons/logoIcon.png"),
  "icons/healthcare.png": require("../../../assets/icons/healthcare.png"),

  "icons/arrowDownYesterdayIcon.png": require("../../../assets/icons/arrowDownYesterdayIcon.png"),

  "icons/giftIcon.png": require("../../../assets/icons/giftIcon.png"),
  "icons/giftIconBlue.png": require("../../../assets/icons/giftIconBlue.png"),
  "icons/giftOrangeIcon.png": require("../../../assets/icons/giftOrangeIcon.png"),
  "icons/gift_annual_sub.png": require("../../../assets/icons/gift_annual_sub.png"),
  "icons/gift_pl_one.png": require("../../../assets/icons/gift_pl_one.png"),
  "icons/gift_pl_two.png": require("../../../assets/icons/gift_pl_two.png"),
  "icons/gold.png": require("../../../assets/icons/gold.png"),
  "icons/goldBgDark.png": require("../../../assets/icons/goldBgDark.png"),
  "icons/goldCoin.png": require("../../../assets/icons/goldCoin.png"),
  "icons/goldcoincredit.png": require("../../../assets/icons/goldcoincredit.png"),
  "icons/goldenIllustration.png": require("../../../assets/icons/goldenIllustration.png"),
  "icons/google-icon-new.png": require("../../../assets/icons/google-icon-new.png"),
  "icons/google-news-initiative.jpg": require("../../../assets/icons/google-news-initiative.jpg"),
  "icons/grey-close-in.png": require("../../../assets/icons/grey-close-in.png"),
  "icons/greyNegativeIcon.png": require("../../../assets/icons/greyNegativeIcon.png"),
  "icons/greyPlusIcon.png": require("../../../assets/icons/greyPlusIcon.png"),
  "icons/greyQuoteLeft.png": require("../../../assets/icons/greyQuoteLeft.png"),
  "icons/greyQuoteRight.png": require("../../../assets/icons/greyQuoteRight.png"),
  "icons/hamburger-menu-desk.png": require("../../../assets/icons/hamburger-menu-desk.png"),
  "icons/hamburger-menu-deskDark.png": require("../../../assets/icons/hamburger-menu-deskDark.png"),
  "icons/hamburger-menu-mob.png": require("../../../assets/icons/hamburger-menu-mob.png"),
  "icons/hamburger-menu-mobDark.png": require("../../../assets/icons/hamburger-menu-mobDark.png"),
  "icons/homeIcon.png": require("../../../assets/icons/homeIcon.png"),
  "icons/homescreen-icon.png": require("../../../assets/icons/homescreen-icon.png"),
  "icons/inbox-icon.png": require("../../../assets/icons/inbox-icon.png"),
  "icons/info-red.png": require("../../../assets/icons/info-red.png"),
  "icons/info.png": require("../../../assets/icons/info.png"),
  "icons/intro-screen-book-icon.png": require("../../../assets/icons/intro-screen-book-icon.png"),
  "icons/intro-screen-circle-icon.png": require("../../../assets/icons/intro-screen-circle-icon.png"),
  "icons/investorsDots.png": require("../../../assets/icons/investorsDots.png"),
  "icons/investorsleftIcon.png": require("../../../assets/icons/investorsleftIcon.png"),
  "icons/invitation-verified.png": require("../../../assets/icons/invitation-verified.png"),
  "icons/invite-members.png": require("../../../assets/icons/invite-members.png"),
  "icons/journalism-dark.png": require("../../../assets/icons/journalism-dark.png"),
  "icons/journalism.png": require("../../../assets/icons/journalism.png"),
  "icons/laptop.png": require("../../../assets/icons/laptop.png"),
  "icons/leadershipCircle.png": require("../../../assets/icons/leadershipCircle.png"),
  "icons/leadershipCross.png": require("../../../assets/icons/leadershipCross.png"),
  "icons/leadershipDualCircle.png": require("../../../assets/icons/leadershipDualCircle.png"),
  "icons/like-icon-light.png": require("../../../assets/icons/like-icon-light.png"),
  "icons/like-icon-selected.png": require("../../../assets/icons/like-icon-selected.png"),
  "icons/like-icon.png": require("../../../assets/icons/like-icon.png"),
  "icons/linkedin-b.png": require("../../../assets/icons/linkedin-b.png"),
  "icons/linkedin-dark.png": require("../../../assets/icons/linkedin-dark.png"),
  "icons/linkedin-light.png": require("../../../assets/icons/linkedin-light.png"),
  "icons/linkedin_icon.png": require("../../../assets/icons/linkedin_icon.png"),
  "icons/linkedlngrey.png": require("../../../assets/icons/linkedlngrey.png"),
  "icons/telegram-b.png": require("../../../assets/icons/telegram-b.png"),
  "icons/footerTelegram.png": require("../../../assets/icons/footerTelegram.png"),
  "icons/lock-icon-white.png": require("../../../assets/icons/lock-icon-white.png"),
  "icons/lockIcon.png": require("../../../assets/icons/lockIcon.png"),
  "icons/teamPlans.png": require("../../../assets/icons/teamPlans.png"),
  "icons/gifitingOption.png": require("../../../assets/icons/gifitingOption.png"),
  "icons/studentPlans.png": require("../../../assets/icons/studentPlans.png"),

  "icons/lockIconBlue.png": require("../../../assets/icons/lockIconBlue.png"),
  "icons/logout.png": require("../../../assets/icons/logout.png"),
  "icons/logoutIcon.png": require("../../../assets/icons/logoutIcon.png"),
  "icons/long_read.png": require("../../../assets/icons/long_read.png"),
  "icons/menu-icon.png": require("../../../assets/icons/menu-icon.png"),
  "icons/moon-dark.png": require("../../../assets/icons/moon-dark.png"),
  "icons/moon.png": require("../../../assets/icons/moon.png"),
  "icons/most-viewed.png": require("../../../assets/icons/most-viewed.png"),
  "icons/newsletter.png": require("../../../assets/icons/newsletter.png"),
  "icons/newsletters.png": require("../../../assets/icons/newsletters.png"),
  "icons/newsletter_icons.png": require("../../../assets/icons/newsletter_icons.png"),
  "icons/pdfIcon.png": require("../../../assets/icons/pdfIcon.png"),
  "icons/pdfIconDark.png": require("../../../assets/icons/pdfIconDark.png"),
  "icons/pink-dots.png": require("../../../assets/icons/pink-dots.png"),
  "icons/plCredits.png": require("../../../assets/icons/plCredits.png"),
  "icons/plCreditsDark.png": require("../../../assets/icons/plCreditsDark.png"),
  "icons/premium-newsltetters-dark.png": require("../../../assets/icons/premium-newsltetters-dark.png"),
  "icons/premium-newsltetters.png": require("../../../assets/icons/premium-newsltetters.png"),
  "icons/price-icon.png": require("../../../assets/icons/price-icon.png"),
  "icons/purple-dots-a.png": require("../../../assets/icons/purple-dots-a.png"),
  "icons/purple-dots-b.png": require("../../../assets/icons/purple-dots-b.png"),
  "icons/question.png": require("../../../assets/icons/question.png"),
  "icons/quotegreen.png": require("../../../assets/icons/quotegreen.png"),
  "icons/quotegreen1.png": require("../../../assets/icons/quotegreen1.png"),
  "icons/quoteImageL.png": require("../../../assets/icons/quoteImageL.png"),
  "icons/quoteImageR.png": require("../../../assets/icons/quoteImageR.png"),
  "icons/radio_circle.png": require("../../../assets/icons/radio_circle.png"),
  "icons/radio_circle_checked.png": require("../../../assets/icons/radio_circle_checked.png"),
  "icons/reading-list-icon.png": require("../../../assets/icons/reading-list-icon.png"),
  "icons/reading-list-light.png": require("../../../assets/icons/reading-list-light.png"),
  "icons/referfriend.png": require("../../../assets/icons/referfriend.png"),
  "icons/removeIcon.png": require("../../../assets/icons/removeIcon.png"),
  "icons/removeIconBlue.png": require("../../../assets/icons/removeIconBlue.png"),
  "icons/rightBlueIcon.png": require("../../../assets/icons/rightBlueIcon.png"),
  "icons/risk.png": require("../../../assets/icons/risk.png"),
  "icons/sCredits.png": require("../../../assets/icons/sCredits.png"),
  "icons/sCreditsDark.png": require("../../../assets/icons/sCreditsDark.png"),
  "icons/search.png": require("../../../assets/icons/search.png"),
  "icons/searchbar.png": require("../../../assets/icons/searchbar.png"),
  "icons/searchbox.png": require("../../../assets/icons/searchbox.png"),
  "icons/settings.png": require("../../../assets/icons/settings.png"),
  "icons/share-icon-dark.png": require("../../../assets/icons/share-icon-dark.png"),
  "icons/share-icon-light.png": require("../../../assets/icons/share-icon-light.png"),
  "icons/share-light.png": require("../../../assets/icons/share-light.png"),
  "icons/share.png": require("../../../assets/icons/share.png"),
  "icons/sharp-login.png": require("../../../assets/icons/sharp-login.png"),
  "icons/showPassowrd.png": require("../../../assets/icons/showPassowrd.png"),
  "icons/silver-coin.png": require("../../../assets/icons/silver-coin.png"),
  "icons/silver.png": require("../../../assets/icons/silver.png"),
  "icons/silverBgDark.png": require("../../../assets/icons/silverBgDark.png"),
  "icons/silverCoin.png": require("../../../assets/icons/silverCoin.png"),
  "icons/silverCoinSmall.png": require("../../../assets/icons/silverCoinSmall.png"),
  "icons/sorty-icon.png": require("../../../assets/icons/sorty-icon.png"),
  "icons/star.png": require("../../../assets/icons/star.png"),
  "icons/starter-cerdits.png": require("../../../assets/icons/starter-cerdits.png"),
  "icons/starter-dots.png": require("../../../assets/icons/starter-dots.png"),
  "icons/starter-semi-circle.png": require("../../../assets/icons/starter-semi-circle.png"),
  "icons/starterCollection.png": require("../../../assets/icons/starterCollection.png"),
  "icons/stepComplete.png": require("../../../assets/icons/stepComplete.png"),
  "icons/stepOneActive.png": require("../../../assets/icons/stepOneActive.png"),
  "icons/stepOneFailed.png": require("../../../assets/icons/stepOneFailed.png"),
  "icons/stepThree.png": require("../../../assets/icons/stepThree.png"),
  "icons/steptwo.png": require("../../../assets/icons/steptwo.png"),
  "icons/steptwoActive.png": require("../../../assets/icons/steptwoActive.png"),
  "icons/storyLocked.png": require("../../../assets/icons/storyLocked.png"),
  "icons/storyLockedWhite.png": require("../../../assets/icons/storyLockedWhite.png"),
  "icons/studenOrangeIcon.png": require("../../../assets/icons/studenOrangeIcon.png"),
  "icons/studentBlueIcon.svg": require("../../../assets/icons/studentBlueIcon.svg"),
  "icons/success.png": require("../../../assets/icons/success.png"),
  "icons/support-line.png": require("../../../assets/icons/support-line.png"),
  "icons/theme_icon_one.png": require("../../../assets/icons/theme_icon_one.png"),
  "icons/theme_icon_two.png": require("../../../assets/icons/theme_icon_two.png"),
  "icons/tick-icon-2.png": require("../../../assets/icons/tick-icon-2.png"),
  "icons/tick-icon.png": require("../../../assets/icons/tick-icon.png"),
  "icons/tmc-footer-logo.png": require("../../../assets/icons/tmc-footer-logo.png"),
  "icons/tmc-logo.png": require("../../../assets/icons/tmc-logo.png"),
  "icons/twitter-b.png": require("../../../assets/icons/twitter-b.png"),
  "icons/twitter-dark.png": require("../../../assets/icons/twitter-dark.png"),
  "icons/twitter-light.png": require("../../../assets/icons/twitter-light.png"),
  "icons/twitterGrey.png": require("../../../assets/icons/twitterGrey.png"),
  "icons/twitterIcon.png": require("../../../assets/icons/twitterIcon.png"),
  "icons/uncheck.png": require("../../../assets/icons/uncheck.png"),
  "icons/unCheckedIcon.png": require("../../../assets/icons/unCheckedIcon.png"),
  "icons/unCheckedIconSq.png": require("../../../assets/icons/unCheckedIconSq.png"),
  "icons/unlock-content.png": require("../../../assets/icons/unlock-content.png"),
  "icons/unlockIcon.png": require("../../../assets/icons/unlockIcon.png"),
  "icons/unlockIconGreen.png": require("../../../assets/icons/unlockIconGreen.png"),
  "icons/unlockIconWhite.png": require("../../../assets/icons/unlockIconWhite.png"),
  "icons/unlockNoteIcon.png": require("../../../assets/icons/unlockNoteIcon.png"),
  "icons/unrestricted-access.png": require("../../../assets/icons/unrestricted-access.png"),
  "icons/unsuccessful.png": require("../../../assets/icons/unsuccessful.png"),
  "icons/uparrow.png": require("../../../assets/icons/uparrow.png"),
  "icons/userIcon.png": require("../../../assets/icons/userIcon.png"),
  "icons/userIconFilledLight.png": require("../../../assets/icons/userIconFilledLight.png"),
  "icons/userIconlight.png": require("../../../assets/icons/userIconlight.png"),
  "icons/userIconOffWhite.png": require("../../../assets/icons/userIconOffWhite.png"),
  "icons/view-more.png": require("../../../assets/icons/view-more.png"),
  "icons/whiteClock.png": require("../../../assets/icons/whiteClock.png"),
  "icons/whiteTick.png": require("../../../assets/icons/whiteTick.png"),
  "icons/zig-zag.png": require("../../../assets/icons/zig-zag.png"),
  "img/404-page.png": require("../../../assets/img/404-page.png"),
  "img/aboutBg.png": require("../../../assets/img/aboutBg.png"),
  "img/tmcApp.png": require("../../../assets/img/tmc-app.png"),
  "img/accountItemBg.png": require("../../../assets/img/accountItemBg.png"),
  "img/NotificationsFlatline.png": require("../../../assets/img/NotificationsFlatline.png"),
  "img/notificationsFlatlineMobile.png": require("../../../assets/img/notificationsFlatlineMobile.png"),
  "icons/rightArrow.png": require("../../../assets/icons/rightArrow.png"),
  "icons/logoIcon.png": require("../../../assets/icons/logoIcon.png"),
  // Newly added image
  "icons/business_n.png": require("../../../assets/icons/business_n.png"),
  "icons/covid-19_n.png": require("../../../assets/icons/covid-19_n.png"),
  "icons/envoroment_n.png": require("../../../assets/icons/envoroment_n.png"),
  "icons/markets_n.png": require("../../../assets/icons/markets_n.png"),
  "icons/sports_n.png": require("../../../assets/icons/sports_n.png"),
  "icons/world_n.png": require("../../../assets/icons/world_n.png"),
  "icons/economy_n.png": require("../../../assets/icons/economy_n.png"),
  "icons/chaos_n.png": require("../../../assets/icons/chaos_n.png"),
  "icons/politics_n.png": require("../../../assets/icons/politics_n.png"),
  "icons/healthcare_n.png": require("../../../assets/icons/healthcare_n.png"),
  "icons/internet_n.png": require("../../../assets/icons/internet_n.png"),
  "icons/entertainment_n.png": require("../../../assets/icons/entertainment_n.png"),
  "icons/tech_n.png": require("../../../assets/icons/tech_n.png"),
  "icons/crypto_n.png": require("../../../assets/icons/crypto_n.png"),
  "icons/ipo_n.png": require("../../../assets/icons/ipo_n.png"),
  "icons/nation_n.png": require("../../../assets/icons/nation_n.png"),
  "icons/policy_n.png": require("../../../assets/icons/policy_n.png"),
  "icons/education_n.png": require("../../../assets/icons/education_n.png"),
  "icons/india_n.png": require("../../../assets/icons/india_n.png"),
  "icons/delhi_n.png": require("../../../assets/icons/delhi_n.png"),

  "icons/arrowDownYesterdayIcon.png": require("../../../assets/icons/arrowDownYesterdayIcon.png"),

  "icons/giftIcon.png": require("../../../assets/icons/giftIcon.png"),
  "icons/giftIconBlue.png": require("../../../assets/icons/giftIconBlue.png"),
  "icons/giftOrangeIcon.png": require("../../../assets/icons/giftOrangeIcon.png"),
  "icons/gift_annual_sub.png": require("../../../assets/icons/gift_annual_sub.png"),
  "icons/gift_pl_one.png": require("../../../assets/icons/gift_pl_one.png"),
  "icons/gift_pl_two.png": require("../../../assets/icons/gift_pl_two.png"),
  "icons/gold.png": require("../../../assets/icons/gold.png"),
  "icons/goldBgDark.png": require("../../../assets/icons/goldBgDark.png"),
  "icons/goldCoin.png": require("../../../assets/icons/goldCoin.png"),
  "icons/goldcoincredit.png": require("../../../assets/icons/goldcoincredit.png"),
  "icons/goldenIllustration.png": require("../../../assets/icons/goldenIllustration.png"),
  "icons/google-icon-new.png": require("../../../assets/icons/google-icon-new.png"),
  "icons/google-news-initiative.jpg": require("../../../assets/icons/google-news-initiative.jpg"),
  "icons/grey-close-in.png": require("../../../assets/icons/grey-close-in.png"),
  "icons/greyNegativeIcon.png": require("../../../assets/icons/greyNegativeIcon.png"),
  "icons/greyPlusIcon.png": require("../../../assets/icons/greyPlusIcon.png"),
  "icons/greyQuoteLeft.png": require("../../../assets/icons/greyQuoteLeft.png"),
  "icons/greyQuoteRight.png": require("../../../assets/icons/greyQuoteRight.png"),
  "icons/hamburger-menu-desk.png": require("../../../assets/icons/hamburger-menu-desk.png"),
  "icons/hamburger-menu-deskDark.png": require("../../../assets/icons/hamburger-menu-deskDark.png"),
  "icons/hamburger-menu-mob.png": require("../../../assets/icons/hamburger-menu-mob.png"),
  "icons/hamburger-menu-mobDark.png": require("../../../assets/icons/hamburger-menu-mobDark.png"),
  "icons/homeIcon.png": require("../../../assets/icons/homeIcon.png"),
  "icons/homescreen-icon.png": require("../../../assets/icons/homescreen-icon.png"),
  "icons/inbox-icon.png": require("../../../assets/icons/inbox-icon.png"),
  "icons/info-red.png": require("../../../assets/icons/info-red.png"),
  "icons/info.png": require("../../../assets/icons/info.png"),
  "icons/intro-screen-book-icon.png": require("../../../assets/icons/intro-screen-book-icon.png"),
  "icons/intro-screen-circle-icon.png": require("../../../assets/icons/intro-screen-circle-icon.png"),
  "icons/investorsDots.png": require("../../../assets/icons/investorsDots.png"),
  "icons/investorsleftIcon.png": require("../../../assets/icons/investorsleftIcon.png"),
  "icons/invitation-verified.png": require("../../../assets/icons/invitation-verified.png"),
  "icons/invite-members.png": require("../../../assets/icons/invite-members.png"),
  "icons/journalism-dark.png": require("../../../assets/icons/journalism-dark.png"),
  "icons/journalism.png": require("../../../assets/icons/journalism.png"),
  "icons/laptop.png": require("../../../assets/icons/laptop.png"),
  "icons/leadershipCircle.png": require("../../../assets/icons/leadershipCircle.png"),
  "icons/leadershipCross.png": require("../../../assets/icons/leadershipCross.png"),
  "icons/leadershipDualCircle.png": require("../../../assets/icons/leadershipDualCircle.png"),
  "icons/like-icon-light.png": require("../../../assets/icons/like-icon-light.png"),
  "icons/like-icon-selected.png": require("../../../assets/icons/like-icon-selected.png"),
  "icons/like-icon.png": require("../../../assets/icons/like-icon.png"),
  "icons/linkedin-b.png": require("../../../assets/icons/linkedin-b.png"),
  "icons/linkedin-dark.png": require("../../../assets/icons/linkedin-dark.png"),
  "icons/linkedin-light.png": require("../../../assets/icons/linkedin-light.png"),
  "icons/linkedin_icon.png": require("../../../assets/icons/linkedin_icon.png"),
  "icons/linkedlngrey.png": require("../../../assets/icons/linkedlngrey.png"),
  "icons/lock-icon-white.png": require("../../../assets/icons/lock-icon-white.png"),
  "icons/lockIcon.png": require("../../../assets/icons/lockIcon.png"),
  "icons/teamPlans.png": require("../../../assets/icons/teamPlans.png"),
  "icons/gifitingOption.png": require("../../../assets/icons/gifitingOption.png"),
  "icons/studentPlans.png": require("../../../assets/icons/studentPlans.png"),

  "icons/lockIconBlue.png": require("../../../assets/icons/lockIconBlue.png"),
  "icons/logout.png": require("../../../assets/icons/logout.png"),
  "icons/logoutIcon.png": require("../../../assets/icons/logoutIcon.png"),
  "icons/long_read.png": require("../../../assets/icons/long_read.png"),
  "icons/menu-icon.png": require("../../../assets/icons/menu-icon.png"),
  "icons/moon-dark.png": require("../../../assets/icons/moon-dark.png"),
  "icons/moon.png": require("../../../assets/icons/moon.png"),
  "icons/most-viewed.png": require("../../../assets/icons/most-viewed.png"),
  "icons/newsletter.png": require("../../../assets/icons/newsletter.png"),
  "icons/newsletters.png": require("../../../assets/icons/newsletters.png"),
  "icons/newsletter_icons.png": require("../../../assets/icons/newsletter_icons.png"),
  "icons/pdfIcon.png": require("../../../assets/icons/pdfIcon.png"),
  "icons/pdfIconDark.png": require("../../../assets/icons/pdfIconDark.png"),
  "icons/pink-dots.png": require("../../../assets/icons/pink-dots.png"),
  "icons/plCredits.png": require("../../../assets/icons/plCredits.png"),
  "icons/plCreditsDark.png": require("../../../assets/icons/plCreditsDark.png"),
  "icons/premium-newsltetters-dark.png": require("../../../assets/icons/premium-newsltetters-dark.png"),
  "icons/premium-newsltetters.png": require("../../../assets/icons/premium-newsltetters.png"),
  "icons/price-icon.png": require("../../../assets/icons/price-icon.png"),
  "icons/purple-dots-a.png": require("../../../assets/icons/purple-dots-a.png"),
  "icons/purple-dots-b.png": require("../../../assets/icons/purple-dots-b.png"),
  "icons/question.png": require("../../../assets/icons/question.png"),
  "icons/quotegreen.png": require("../../../assets/icons/quotegreen.png"),
  "icons/quotegreen1.png": require("../../../assets/icons/quotegreen1.png"),
  "icons/quoteImageL.png": require("../../../assets/icons/quoteImageL.png"),
  "icons/quoteImageR.png": require("../../../assets/icons/quoteImageR.png"),
  "icons/radio_circle.png": require("../../../assets/icons/radio_circle.png"),
  "icons/radio_circle_checked.png": require("../../../assets/icons/radio_circle_checked.png"),
  "icons/reading-list-icon.png": require("../../../assets/icons/reading-list-icon.png"),
  "icons/reading-list-light.png": require("../../../assets/icons/reading-list-light.png"),
  "icons/referfriend.png": require("../../../assets/icons/referfriend.png"),
  "icons/removeIcon.png": require("../../../assets/icons/removeIcon.png"),
  "icons/removeIconBlue.png": require("../../../assets/icons/removeIconBlue.png"),
  "icons/rightBlueIcon.png": require("../../../assets/icons/rightBlueIcon.png"),
  "icons/risk.png": require("../../../assets/icons/risk.png"),
  "icons/sCredits.png": require("../../../assets/icons/sCredits.png"),
  "icons/sCreditsDark.png": require("../../../assets/icons/sCreditsDark.png"),
  "icons/search.png": require("../../../assets/icons/search.png"),
  "icons/searchbar.png": require("../../../assets/icons/searchbar.png"),
  "icons/searchbox.png": require("../../../assets/icons/searchbox.png"),
  "icons/settings.png": require("../../../assets/icons/settings.png"),
  "icons/share-icon-dark.png": require("../../../assets/icons/share-icon-dark.png"),
  "icons/share-icon-light.png": require("../../../assets/icons/share-icon-light.png"),
  "icons/share-light.png": require("../../../assets/icons/share-light.png"),
  "icons/share.png": require("../../../assets/icons/share.png"),
  "icons/sharp-login.png": require("../../../assets/icons/sharp-login.png"),
  "icons/showPassowrd.png": require("../../../assets/icons/showPassowrd.png"),
  "icons/silver-coin.png": require("../../../assets/icons/silver-coin.png"),
  "icons/silver.png": require("../../../assets/icons/silver.png"),
  "icons/silverBgDark.png": require("../../../assets/icons/silverBgDark.png"),
  "icons/silverCoin.png": require("../../../assets/icons/silverCoin.png"),
  "icons/silverCoinSmall.png": require("../../../assets/icons/silverCoinSmall.png"),
  "icons/sorty-icon.png": require("../../../assets/icons/sorty-icon.png"),
  "icons/star.png": require("../../../assets/icons/star.png"),
  "icons/starter-cerdits.png": require("../../../assets/icons/starter-cerdits.png"),
  "icons/starter-dots.png": require("../../../assets/icons/starter-dots.png"),
  "icons/starter-semi-circle.png": require("../../../assets/icons/starter-semi-circle.png"),
  "icons/starterCollection.png": require("../../../assets/icons/starterCollection.png"),
  "icons/stepComplete.png": require("../../../assets/icons/stepComplete.png"),
  "icons/stepOneActive.png": require("../../../assets/icons/stepOneActive.png"),
  "icons/stepOneFailed.png": require("../../../assets/icons/stepOneFailed.png"),
  "icons/stepThree.png": require("../../../assets/icons/stepThree.png"),
  "icons/steptwo.png": require("../../../assets/icons/steptwo.png"),
  "icons/steptwoActive.png": require("../../../assets/icons/steptwoActive.png"),
  "icons/storyLocked.png": require("../../../assets/icons/storyLocked.png"),
  "icons/storyLockedWhite.png": require("../../../assets/icons/storyLockedWhite.png"),
  "icons/studenOrangeIcon.png": require("../../../assets/icons/studenOrangeIcon.png"),
  "icons/studentBlueIcon.svg": require("../../../assets/icons/studentBlueIcon.svg"),
  "icons/success.png": require("../../../assets/icons/success.png"),
  "icons/support-line.png": require("../../../assets/icons/support-line.png"),
  "icons/theme_icon_one.png": require("../../../assets/icons/theme_icon_one.png"),
  "icons/theme_icon_two.png": require("../../../assets/icons/theme_icon_two.png"),
  "icons/tick-icon-2.png": require("../../../assets/icons/tick-icon-2.png"),
  "icons/tick-icon.png": require("../../../assets/icons/tick-icon.png"),
  "icons/tmc-footer-logo.png": require("../../../assets/icons/tmc-footer-logo.png"),
  "icons/tmc-logo.png": require("../../../assets/icons/tmc-logo.png"),
  "icons/twitter-b.png": require("../../../assets/icons/twitter-b.png"),
  "icons/twitter-dark.png": require("../../../assets/icons/twitter-dark.png"),
  "icons/twitter-light.png": require("../../../assets/icons/twitter-light.png"),
  "icons/twitterGrey.png": require("../../../assets/icons/twitterGrey.png"),
  "icons/twitterIcon.png": require("../../../assets/icons/twitterIcon.png"),
  "icons/uncheck.png": require("../../../assets/icons/uncheck.png"),
  "icons/unCheckedIcon.png": require("../../../assets/icons/unCheckedIcon.png"),
  "icons/unCheckedIconSq.png": require("../../../assets/icons/unCheckedIconSq.png"),
  "icons/unlock-content.png": require("../../../assets/icons/unlock-content.png"),
  "icons/unlockIcon.png": require("../../../assets/icons/unlockIcon.png"),
  "icons/unlockIconGreen.png": require("../../../assets/icons/unlockIconGreen.png"),
  "icons/unlockIconWhite.png": require("../../../assets/icons/unlockIconWhite.png"),
  "icons/unlockNoteIcon.png": require("../../../assets/icons/unlockNoteIcon.png"),
  "icons/unrestricted-access.png": require("../../../assets/icons/unrestricted-access.png"),
  "icons/unsuccessful.png": require("../../../assets/icons/unsuccessful.png"),
  "icons/uparrow.png": require("../../../assets/icons/uparrow.png"),
  "icons/userIcon.png": require("../../../assets/icons/userIcon.png"),
  "icons/userIconFilledLight.png": require("../../../assets/icons/userIconFilledLight.png"),
  "icons/userIconlight.png": require("../../../assets/icons/userIconlight.png"),
  "icons/userIconOffWhite.png": require("../../../assets/icons/userIconOffWhite.png"),
  "icons/view-more.png": require("../../../assets/icons/view-more.png"),
  "icons/whiteClock.png": require("../../../assets/icons/whiteClock.png"),
  "icons/whiteTick.png": require("../../../assets/icons/whiteTick.png"),
  "icons/zig-zag.png": require("../../../assets/icons/zig-zag.png"),
  "img/404-page.png": require("../../../assets/img/404-page.png"),
  "img/aboutBg.png": require("../../../assets/img/aboutBg.png"),
  "img/tmcApp.png": require("../../../assets/img/tmc-app.png"),
  "img/accountItemBg.png": require("../../../assets/img/accountItemBg.png"),
  "img/NotificationsFlatline.png": require("../../../assets/img/NotificationsFlatline.png"),
  "img/notificationsFlatlineMobile.png": require("../../../assets/img/notificationsFlatlineMobile.png"),

  "img/all-stories-dark.png": require("../../../assets/img/all-stories-dark.png"),
  "img/all-stories.png": require("../../../assets/img/all-stories.png"),
  "img/amazonLogoDark.png": require("../../../assets/img/amazonLogoDark.png"),
  "img/amazonLogoLight.png": require("../../../assets/img/amazonLogoLight.png"),
  "img/app-available.png": require("../../../assets/img/app-available.png"),
  "img/app-store.png": require("../../../assets/img/app-store.png"),
  "img/archive-dark.png": require("../../../assets/img/archive-dark.png"),
  "img/archive.png": require("../../../assets/img/archive.png"),
  "img/arrow-right-round-active.png": require("../../../assets/img/arrow-right-round-active.png"),
  "img/arrow-right-round.png": require("../../../assets/img/arrow-right-round.png"),
  "img/ashish-profile.jpg": require("../../../assets/img/ashish-profile.jpg"),
  "img/axilor-logo.png": require("../../../assets/img/axilor-logo.png"),
  "img/banner-left-circle.png": require("../../../assets/img/banner-left-circle.png"),
  "img/banner-right-circle-dark.png": require("../../../assets/img/banner-right-circle-dark.png"),
  "img/banner-right-circle.png": require("../../../assets/img/banner-right-circle.png"),
  "img/banner-slide-dark.png": require("../../../assets/img/banner-slide-dark.png"),
  "img/banner-slide.png": require("../../../assets/img/banner-slide.png"),
  "img/tmcAppMobile.png": require("../../../assets/img/tmc-mobile-app.png"),
  "img/BadgeAndroid.png": require("../../../assets/icons/BadgeAndroid.png"),
  "img/BadgeiOS.png": require("../../../assets/icons/BadgeiOS.png"),
  "img/suscription-top-section-bg.png": require("../../../assets/img/suscription-top-section-bg.png"),
  "img/bi_clock.png": require("../../../assets/img/bi_clock.png"),
  "img/cancel-complete.png": require("../../../assets/img/cancel-complete.png"),
  "img/category-dark.png": require("../../../assets/img/category-dark.png"),
  "img/category.png": require("../../../assets/img/category.png"),
  "img/color-circles-dark.png": require("../../../assets/img/color-circles-dark.png"),
  "img/colored-circles.png": require("../../../assets/img/colored-circles.png"),
  "img/company-dark.png": require("../../../assets/img/company-dark.png"),
  "img/company.png": require("../../../assets/img/company.png"),
  "img/companyBg.png": require("../../../assets/img/companyBg.png"),
  "img/companyBgDark.png": require("../../../assets/img/companyBgDark.png"),
  "img/credits-icon-back.png": require("../../../assets/img/credits-icon-back.png"),
  "img/credits-icon.png": require("../../../assets/img/credits-icon.png"),
  "img/custom-subscriptions.png": require("../../../assets/img/custom-subscriptions.png"),
  "img/darkmode.png": require("../../../assets/img/darkmode.png"),
  "img/eazydiner-logo.png": require("../../../assets/img/eazydiner-logo.png"),
  "img/eccomerce-img.png": require("../../../assets/img/eccomerce-img.png"),
  "img/editorial-code.png": require("../../../assets/img/editorial-code.png"),
  "img/editorial-mob.png": require("../../../assets/img/editorial-mob.png"),
  "img/editorialCodeDarkDesktop.png": require("../../../assets/img/editorialCodeDarkDesktop.png"),
  "img/editorialCodeDarkMobile.png": require("../../../assets/img/editorialCodeDarkMobile.png"),
  "img/email-offer-image.png": require("../../../assets/img/email-offer-image.png"),
  "img/everythingImage.png": require("../../../assets/img/everythingImage.png"),
  "img/excerpt_overlay.png": require("../../../assets/img/excerpt_overlay.png"),
  "img/excerpt_overlay_old.png": require("../../../assets/img/excerpt_overlay_old.png"),
  "img/featured.png": require("../../../assets/img/featured.png"),
  "img/filter.png": require("../../../assets/img/filter.png"),
  "img/fintech-img.png": require("../../../assets/img/fintech-img.png"),
  "img/firework-logo-dark.png": require("../../../assets/img/firework-logo-dark.png"),
  "img/Firework.png": require("../../../assets/img/Firework.png"),
  "img/flf-logo-dark.png": require("../../../assets/img/flf-logo-dark.png"),
  "img/flf.png": require("../../../assets/img/flf.png"),
  "img/forgotPassword.png": require("../../../assets/img/forgotPassword.png"),
  "img/google-play.png": require("../../../assets/img/google-play.png"),
  "img/gradientLeft.png": require("../../../assets/img/gradientLeft.png"),
  "img/gradientRight.png": require("../../../assets/img/gradientRight.png"),
  "img/harveen-profile.jpg": require("../../../assets/img/harveen-profile.jpg"),
  "img/himanshu-profile.jpg": require("../../../assets/img/himanshu-profile.jpg"),
  "img/imgeOverlay.png": require("../../../assets/img/imgeOverlay.png"),
  "img/internet_image.png": require("../../../assets/img/internet_image.png"),
  "img/intro-screen-bg.png": require("../../../assets/img/intro-screen-bg.png"),
  "img/premium-tag.png": require("../../../assets/img/premium-tag.png"),
  "img/investor-mob.png": require("../../../assets/img/investor-mob.png"),
  "img/investor.png": require("../../../assets/img/investor.png"),
  "img/investorsDarkDesktop.png": require("../../../assets/img/investorsDarkDesktop.png"),
  "img/investorsDarkMobile.png": require("../../../assets/img/investorsDarkMobile.png"),
  "img/iPhoneDark.png": require("../../../assets/img/iPhoneDark.png"),
  "img/irshad-profile.jpg": require("../../../assets/img/irshad-profile.jpg"),
  "img/laptop.png": require("../../../assets/img/laptop.png"),
  "img/leadership-mob.png": require("../../../assets/img/leadership-mob.png"),
  "img/leadership.png": require("../../../assets/img/leadership.png"),
  "img/leadershipDarkDesktop.png": require("../../../assets/img/leadershipDarkDesktop.png"),
  "img/leadershipDarkMobile.png": require("../../../assets/img/leadershipDarkMobile.png"),
  "img/locus-logo-dark.png": require("../../../assets/img/locus-logo-dark.png"),
  "img/Locus.png": require("../../../assets/img/Locus.png"),
  "img/m-ecomerce-img.png": require("../../../assets/img/m-ecomerce-img.png"),
  "img/m-fintech-img.png": require("../../../assets/img/m-fintech-img.png"),
  "img/m-media-img.png": require("../../../assets/img/m-media-img.png"),
  "img/m-retail-img.png": require("../../../assets/img/m-retail-img.png"),
  "img/m-startup-img.png": require("../../../assets/img/m-startup-img.png"),
  "img/media-img.png": require("../../../assets/img/media-img.png"),
  "img/method-1a-mob.png": require("../../../assets/img/method-1a-mob.png"),
  "img/method-2a-mob.png": require("../../../assets/img/method-2a-mob.png"),
  "img/method-2a.png": require("../../../assets/img/method-2a.png"),
  "img/method-2b.png": require("../../../assets/img/method-2b.png"),
  "img/method-3a-mob.png": require("../../../assets/img/method-3a-mob.png"),
  "img/method-3a.png": require("../../../assets/img/method-3a.png"),
  "img/method-3b-mob.png": require("../../../assets/img/method-3b-mob.png"),
  "img/method-3b.png": require("../../../assets/img/method-3b.png"),
  "img/method-3c-mob.png": require("../../../assets/img/method-3c-mob.png"),
  "img/method-3c.png": require("../../../assets/img/method-3c.png"),
  "img/method-3d-mob.png": require("../../../assets/img/method-3d-mob.png"),
  "img/method1.png": require("../../../assets/img/method1.png"),
  "img/Netflix-Logo.png": require("../../../assets/img/Netflix-Logo.png"),
  "img/newslettersIcon-darkc.png": require("../../../assets/img/newslettersIcon-darkc.png"),
  "img/newslettersIcon.png": require("../../../assets/img/newslettersIcon.png"),
  "img/no-articles.png": require("../../../assets/img/no-articles.png"),
  "img/no-internet-dark.png": require("../../../assets/img/no-internet-dark.png"),
  "img/no-internet.png": require("../../../assets/img/no-internet.png"),
  "img/no-unlocked-stories.png": require("../../../assets/img/no-unlocked-stories.png"),
  "img/noAccess.png": require("../../../assets/img/noAccess.png"),
  "img/footerlogo.png": require("../../../assets/img/footerlogo.png"),
  "img/illustration.png": require("../../../assets/img/illustration.png"),
  "img/noaccessDark.png": require("../../../assets/img/noaccessDark.png"),
  "img/of-the-day-blue.png": require("../../../assets/img/of-the-day-blue.png"),
  "img/of-the-day-dark.png": require("../../../assets/img/of-the-day-dark.png"),
  "img/overlay-darkgrey.png": require("../../../assets/img/overlay-darkgrey.png"),
  "img/piyush-profile.jpg": require("../../../assets/img/piyush-profile.jpg"),
  "img/post-excerpt-overlay.png": require("../../../assets/img/post-excerpt-overlay.png"),
  "img/pradip-profile.jpg": require("../../../assets/img/pradip-profile.jpg"),
  "img/pranav-profile.jpg": require("../../../assets/img/pranav-profile.jpg"),
  "img/priya-profile.jpg": require("../../../assets/img/priya-profile.jpg"),
  "img/storyNameThumbnial.png": require("../../../assets/img/storyNameThumbnial.png"),

  "img/razorpay-logo-white.png": require("../../../assets/img/razorpay-logo-white.png"),
  "img/razorpay-logo.png": require("../../../assets/img/razorpay-logo.png"),
  "img/reading-list-back-image-dark.png": require("../../../assets/img/reading-list-back-image-dark.png"),
  "img/reading-list-back-image.png": require("../../../assets/img/reading-list-back-image.png"),
  "img/reading-list-back.png": require("../../../assets/img/reading-list-back.png"),
  "img/reading-list-empty.png": require("../../../assets/img/reading-list-empty.png"),
  "img/reading-list.png": require("../../../assets/img/reading-list.png"),
  "img/redhill-logo.png": require("../../../assets/img/redhill-logo.png"),
  "img/retail-img.png": require("../../../assets/img/retail-img.png"),
  "img/saif-profile.jpg": require("../../../assets/img/saif-profile.jpg"),
  "img/slackImage.png": require("../../../assets/img/slackImage.png"),
  "img/spark-capital.png": require("../../../assets/img/spark-capital.png"),
  "img/spotify-img.png": require("../../../assets/img/spotify-img.png"),
  "img/startup-img.png": require("../../../assets/img/startup-img.png"),
  "img/story-read.png": require("../../../assets/img/story-read.png"),
  "img/studentSubscriptionbanner.png": require("../../../assets/img/studentSubscriptionbanner.png"),
  "img/subscription-icons.png": require("../../../assets/img/subscription-icons.png"),
  "img/tag-dark.png": require("../../../assets/img/tag-dark.png"),
  "img/tag.png": require("../../../assets/img/tag.png"),
  "img/Tata-logo.png": require("../../../assets/img/Tata-logo.png"),
  "img/teamBannerFour.png": require("../../../assets/img/teamBannerFour.png"),
  "img/teamBannerOne.png": require("../../../assets/img/teamBannerOne.png"),
  "img/teamBannerThree.png": require("../../../assets/img/teamBannerThree.png"),
  "img/teamBannertwo.png": require("../../../assets/img/teamBannertwo.png"),
  "img/team_members.png": require("../../../assets/img/team_members.png"),
  "img/theme-dark.png": require("../../../assets/img/theme-dark.png"),
  "img/theme.png": require("../../../assets/img/theme.png"),
  "img/things-change.png": require("../../../assets/img/things-change.png"),
  "img/tmc40.png": require("../../../assets/img/tmc40.png"),
  "img/user-account-bg.png": require("../../../assets/img/user-account-bg.png"),
  "img/userpic.png": require("../../../assets/img/userpic.png"),
  "img/userProfileBg.png": require("../../../assets/img/userProfileBg.png"),
  "img/welcomeBack.png": require("../../../assets/img/welcomeBack.png"),
  "img/welcome_bg.png": require("../../../assets/img/welcome_bg.png"),
  "img/writersBg.png": require("../../../assets/img/writersBg.png"),
  "img/writersBgDark.png": require("../../../assets/img/writersBgDark.png"),
  "icons/cards/americanexpress.png": require("../../../assets/icons/cards/americanexpress.png"),
  "icons/cards/default-card.png": require("../../../assets/icons/cards/default-card.png"),
  "icons/cards/diners.png": require("../../../assets/icons/cards/diners.png"),
  "icons/cards/discover.png": require("../../../assets/icons/cards/discover.png"),
  "icons/cards/jcb.png": require("../../../assets/icons/cards/jcb.png"),
  "icons/cards/mastercard.png": require("../../../assets/icons/cards/mastercard.png"),
  "icons/cards/visa.png": require("../../../assets/icons/cards/visa.png"),
  "icons/whiteBoard-capitals.png": require("../../../assets/icons/whiteBoard-capitals.png"),
  "icons/rainmatter-foundation.png": require("../../../assets/icons/rainmatter-foundation.png"),
  "img/emailYesterDayOne.png": require("../../../assets/img/emailYesterDayOne.png"),
  "img/emailYesterDayTwo.png": require("../../../assets/img/emailYesterDayTwo.png"),
  "icons/yesterdayLogo.png": require("../../../assets/icons/yesterdayLogo.png"),
  "icons/facebook16.png": require("../../../assets/icons/facebook16.png"),
  "icons/link16.png": require("../../../assets/icons/link16.png"),
  "icons/linkedin16.png": require("../../../assets/icons/linkedin16.png"),
  "icons/twitter16.png": require("../../../assets/icons/twitter16.png"),
  "img/logo.png": require("../../../assets/img/logo.png"),
  "img/storyRegister1.png": require("../../../assets/img/storyRegister1.png"),
  "img/storyRegister2.png": require("../../../assets/img/storyRegister2.png"),
  "img/storyRegister3.png": require("../../../assets/img/storyRegister3.png"),
  "img/archiveIStoryName.png": require("../../../assets/img/archiveIStoryName.png"),

  "img/all-stories-dark.png": require("../../../assets/img/all-stories-dark.png"),
  "img/all-stories.png": require("../../../assets/img/all-stories.png"),
  "img/amazonLogoDark.png": require("../../../assets/img/amazonLogoDark.png"),
  "img/amazonLogoLight.png": require("../../../assets/img/amazonLogoLight.png"),
  "img/app-available.png": require("../../../assets/img/app-available.png"),
  "img/app-store.png": require("../../../assets/img/app-store.png"),
  "img/archive-dark.png": require("../../../assets/img/archive-dark.png"),
  "img/archive.png": require("../../../assets/img/archive.png"),
  "img/arrow-right-round-active.png": require("../../../assets/img/arrow-right-round-active.png"),
  "img/arrow-right-round.png": require("../../../assets/img/arrow-right-round.png"),
  "img/ashish-profile.jpg": require("../../../assets/img/ashish-profile.jpg"),
  "img/axilor-logo.png": require("../../../assets/img/axilor-logo.png"),
  "img/banner-left-circle.png": require("../../../assets/img/banner-left-circle.png"),
  "img/banner-right-circle-dark.png": require("../../../assets/img/banner-right-circle-dark.png"),
  "img/banner-right-circle.png": require("../../../assets/img/banner-right-circle.png"),
  "img/banner-slide-dark.png": require("../../../assets/img/banner-slide-dark.png"),
  "img/banner-slide.png": require("../../../assets/img/banner-slide.png"),
  "img/tmcAppMobile.png": require("../../../assets/img/tmc-mobile-app.png"),
  "img/BadgeAndroid.png": require("../../../assets/icons/BadgeAndroid.png"),
  "img/BadgeiOS.png": require("../../../assets/icons/BadgeiOS.png"),
  "img/suscription-top-section-bg.png": require("../../../assets/img/suscription-top-section-bg.png"),
  "img/bi_clock.png": require("../../../assets/img/bi_clock.png"),
  "img/cancel-complete.png": require("../../../assets/img/cancel-complete.png"),
  "img/category-dark.png": require("../../../assets/img/category-dark.png"),
  "img/category.png": require("../../../assets/img/category.png"),
  "img/color-circles-dark.png": require("../../../assets/img/color-circles-dark.png"),
  "img/colored-circles.png": require("../../../assets/img/colored-circles.png"),
  "img/company-dark.png": require("../../../assets/img/company-dark.png"),
  "img/company.png": require("../../../assets/img/company.png"),
  "img/companyBg.png": require("../../../assets/img/companyBg.png"),
  "img/companyBgDark.png": require("../../../assets/img/companyBgDark.png"),
  "img/credits-icon-back.png": require("../../../assets/img/credits-icon-back.png"),
  "img/credits-icon.png": require("../../../assets/img/credits-icon.png"),
  "img/custom-subscriptions.png": require("../../../assets/img/custom-subscriptions.png"),
  "img/darkmode.png": require("../../../assets/img/darkmode.png"),
  "img/eazydiner-logo.png": require("../../../assets/img/eazydiner-logo.png"),
  "img/eccomerce-img.png": require("../../../assets/img/eccomerce-img.png"),
  "img/editorial-code.png": require("../../../assets/img/editorial-code.png"),
  "img/editorial-mob.png": require("../../../assets/img/editorial-mob.png"),
  "img/editorialCodeDarkDesktop.png": require("../../../assets/img/editorialCodeDarkDesktop.png"),
  "img/editorialCodeDarkMobile.png": require("../../../assets/img/editorialCodeDarkMobile.png"),
  "img/email-offer-image.png": require("../../../assets/img/email-offer-image.png"),
  "img/everythingImage.png": require("../../../assets/img/everythingImage.png"),
  "img/excerpt_overlay.png": require("../../../assets/img/excerpt_overlay.png"),
  "img/excerpt_overlay_old.png": require("../../../assets/img/excerpt_overlay_old.png"),
  "img/featured.png": require("../../../assets/img/featured.png"),
  "img/filter.png": require("../../../assets/img/filter.png"),
  "img/fintech-img.png": require("../../../assets/img/fintech-img.png"),
  "img/firework-logo-dark.png": require("../../../assets/img/firework-logo-dark.png"),
  "img/Firework.png": require("../../../assets/img/Firework.png"),
  "img/flf-logo-dark.png": require("../../../assets/img/flf-logo-dark.png"),
  "img/flf.png": require("../../../assets/img/flf.png"),
  "img/forgotPassword.png": require("../../../assets/img/forgotPassword.png"),
  "img/google-play.png": require("../../../assets/img/google-play.png"),
  "img/gradientLeft.png": require("../../../assets/img/gradientLeft.png"),
  "img/gradientRight.png": require("../../../assets/img/gradientRight.png"),
  "img/harveen-profile.jpg": require("../../../assets/img/harveen-profile.jpg"),
  "img/himanshu-profile.jpg": require("../../../assets/img/himanshu-profile.jpg"),
  "img/imgeOverlay.png": require("../../../assets/img/imgeOverlay.png"),
  "img/internet_image.png": require("../../../assets/img/internet_image.png"),
  "img/intro-screen-bg.png": require("../../../assets/img/intro-screen-bg.png"),
  "img/premium-tag.png": require("../../../assets/img/premium-tag.png"),
  "img/investor-mob.png": require("../../../assets/img/investor-mob.png"),
  "img/investor.png": require("../../../assets/img/investor.png"),
  "img/investorsDarkDesktop.png": require("../../../assets/img/investorsDarkDesktop.png"),
  "img/investorsDarkMobile.png": require("../../../assets/img/investorsDarkMobile.png"),
  "img/iPhoneDark.png": require("../../../assets/img/iPhoneDark.png"),
  "img/irshad-profile.jpg": require("../../../assets/img/irshad-profile.jpg"),
  "img/laptop.png": require("../../../assets/img/laptop.png"),
  "img/leadership-mob.png": require("../../../assets/img/leadership-mob.png"),
  "img/leadership.png": require("../../../assets/img/leadership.png"),
  "img/leadershipDarkDesktop.png": require("../../../assets/img/leadershipDarkDesktop.png"),
  "img/leadershipDarkMobile.png": require("../../../assets/img/leadershipDarkMobile.png"),
  "img/locus-logo-dark.png": require("../../../assets/img/locus-logo-dark.png"),
  "img/Locus.png": require("../../../assets/img/Locus.png"),
  "img/m-ecomerce-img.png": require("../../../assets/img/m-ecomerce-img.png"),
  "img/m-fintech-img.png": require("../../../assets/img/m-fintech-img.png"),
  "img/m-media-img.png": require("../../../assets/img/m-media-img.png"),
  "img/m-retail-img.png": require("../../../assets/img/m-retail-img.png"),
  "img/m-startup-img.png": require("../../../assets/img/m-startup-img.png"),
  "img/media-img.png": require("../../../assets/img/media-img.png"),
  "img/method-1a-mob.png": require("../../../assets/img/method-1a-mob.png"),
  "img/method-2a-mob.png": require("../../../assets/img/method-2a-mob.png"),
  "img/method-2a.png": require("../../../assets/img/method-2a.png"),
  "img/method-2b.png": require("../../../assets/img/method-2b.png"),
  "img/method-3a-mob.png": require("../../../assets/img/method-3a-mob.png"),
  "img/method-3a.png": require("../../../assets/img/method-3a.png"),
  "img/method-3b-mob.png": require("../../../assets/img/method-3b-mob.png"),
  "img/method-3b.png": require("../../../assets/img/method-3b.png"),
  "img/method-3c-mob.png": require("../../../assets/img/method-3c-mob.png"),
  "img/method-3c.png": require("../../../assets/img/method-3c.png"),
  "img/method-3d-mob.png": require("../../../assets/img/method-3d-mob.png"),
  "img/method1.png": require("../../../assets/img/method1.png"),
  "img/Netflix-Logo.png": require("../../../assets/img/Netflix-Logo.png"),
  "img/newslettersIcon-darkc.png": require("../../../assets/img/newslettersIcon-darkc.png"),
  "img/newslettersIcon.png": require("../../../assets/img/newslettersIcon.png"),
  "img/no-articles.png": require("../../../assets/img/no-articles.png"),
  "img/no-internet-dark.png": require("../../../assets/img/no-internet-dark.png"),
  "img/no-internet.png": require("../../../assets/img/no-internet.png"),
  "img/no-unlocked-stories.png": require("../../../assets/img/no-unlocked-stories.png"),
  "img/noAccess.png": require("../../../assets/img/noAccess.png"),
  "img/footerlogo.png": require("../../../assets/img/footerlogo.png"),
  "img/illustration.png": require("../../../assets/img/illustration.png"),
  "img/noaccessDark.png": require("../../../assets/img/noaccessDark.png"),
  "img/of-the-day-blue.png": require("../../../assets/img/of-the-day-blue.png"),
  "img/of-the-day-dark.png": require("../../../assets/img/of-the-day-dark.png"),
  "img/overlay-darkgrey.png": require("../../../assets/img/overlay-darkgrey.png"),
  "img/piyush-profile.jpg": require("../../../assets/img/piyush-profile.jpg"),
  "img/post-excerpt-overlay.png": require("../../../assets/img/post-excerpt-overlay.png"),
  "img/pradip-profile.jpg": require("../../../assets/img/pradip-profile.jpg"),
  "img/pranav-profile.jpg": require("../../../assets/img/pranav-profile.jpg"),
  "img/priya-profile.jpg": require("../../../assets/img/priya-profile.jpg"),
  "img/storyNameThumbnial.png": require("../../../assets/img/storyNameThumbnial.png"),

  "img/razorpay-logo-white.png": require("../../../assets/img/razorpay-logo-white.png"),
  "img/razorpay-logo.png": require("../../../assets/img/razorpay-logo.png"),
  "img/reading-list-back-image-dark.png": require("../../../assets/img/reading-list-back-image-dark.png"),
  "img/reading-list-back-image.png": require("../../../assets/img/reading-list-back-image.png"),
  "img/reading-list-back.png": require("../../../assets/img/reading-list-back.png"),
  "img/reading-list-empty.png": require("../../../assets/img/reading-list-empty.png"),
  "img/reading-list.png": require("../../../assets/img/reading-list.png"),
  "img/redhill-logo.png": require("../../../assets/img/redhill-logo.png"),
  "img/retail-img.png": require("../../../assets/img/retail-img.png"),
  "img/saif-profile.jpg": require("../../../assets/img/saif-profile.jpg"),
  "img/slackImage.png": require("../../../assets/img/slackImage.png"),
  "img/spark-capital.png": require("../../../assets/img/spark-capital.png"),
  "img/spotify-img.png": require("../../../assets/img/spotify-img.png"),
  "img/startup-img.png": require("../../../assets/img/startup-img.png"),
  "img/story-read.png": require("../../../assets/img/story-read.png"),
  "img/studentSubscriptionbanner.png": require("../../../assets/img/studentSubscriptionbanner.png"),
  "img/subscription-icons.png": require("../../../assets/img/subscription-icons.png"),
  "img/tag-dark.png": require("../../../assets/img/tag-dark.png"),
  "img/tag.png": require("../../../assets/img/tag.png"),
  "img/Tata-logo.png": require("../../../assets/img/Tata-logo.png"),
  "img/teamBannerFour.png": require("../../../assets/img/teamBannerFour.png"),
  "img/teamBannerOne.png": require("../../../assets/img/teamBannerOne.png"),
  "img/teamBannerThree.png": require("../../../assets/img/teamBannerThree.png"),
  "img/teamBannertwo.png": require("../../../assets/img/teamBannertwo.png"),
  "img/team_members.png": require("../../../assets/img/team_members.png"),
  "img/theme-dark.png": require("../../../assets/img/theme-dark.png"),
  "img/theme.png": require("../../../assets/img/theme.png"),
  "img/things-change.png": require("../../../assets/img/things-change.png"),
  "img/tmc40.png": require("../../../assets/img/tmc40.png"),
  "img/user-account-bg.png": require("../../../assets/img/user-account-bg.png"),
  "img/userpic.png": require("../../../assets/img/userpic.png"),
  "img/userProfileBg.png": require("../../../assets/img/userProfileBg.png"),
  "img/welcomeBack.png": require("../../../assets/img/welcomeBack.png"),
  "img/welcome_bg.png": require("../../../assets/img/welcome_bg.png"),
  "img/writersBg.png": require("../../../assets/img/writersBg.png"),
  "img/writersBgDark.png": require("../../../assets/img/writersBgDark.png"),
  "icons/cards/americanexpress.png": require("../../../assets/icons/cards/americanexpress.png"),
  "icons/cards/default-card.png": require("../../../assets/icons/cards/default-card.png"),
  "icons/cards/diners.png": require("../../../assets/icons/cards/diners.png"),
  "icons/cards/discover.png": require("../../../assets/icons/cards/discover.png"),
  "icons/cards/jcb.png": require("../../../assets/icons/cards/jcb.png"),
  "icons/cards/mastercard.png": require("../../../assets/icons/cards/mastercard.png"),
  "icons/cards/visa.png": require("../../../assets/icons/cards/visa.png"),
  "icons/whiteBoard-capitals.png": require("../../../assets/icons/whiteBoard-capitals.png"),
  "icons/rainmatter-foundation.png": require("../../../assets/icons/rainmatter-foundation.png"),
  "img/emailYesterDayOne.png": require("../../../assets/img/emailYesterDayOne.png"),
  "img/emailYesterDayTwo.png": require("../../../assets/img/emailYesterDayTwo.png"),
  "icons/yesterdayLogo.png": require("../../../assets/icons/yesterdayLogo.png"),
  "icons/facebook16.png": require("../../../assets/icons/facebook16.png"),
  "icons/link16.png": require("../../../assets/icons/link16.png"),
  "icons/linkedin16.png": require("../../../assets/icons/linkedin16.png"),
  "icons/twitter16.png": require("../../../assets/icons/twitter16.png"),
  "img/logo.png": require("../../../assets/img/logo.png"),
  "img/storyRegister1.png": require("../../../assets/img/storyRegister1.png"),
  "img/storyRegister2.png": require("../../../assets/img/storyRegister2.png"),
  "img/storyRegister3.png": require("../../../assets/img/storyRegister3.png"),
  "img/archiveIStoryName.png": require("../../../assets/img/archiveIStoryName.png"),
  "img/yesterdayBadge.png": require("../../../assets/img/yesterdayBadge.png"),
  "icons/buttongradient.png": require("../../../assets/icons/buttongradient.png"),
  "icons/groupelipse.png": require("../../../assets/icons/groupelipse.png"),
  "icons/groupelipsetick.png": require("../../../assets/icons/groupelipsetick.png"),
  "icons/button_gradient.jpg": require("../../../assets/icons/button_gradient.jpg"),
  "icons/arrow-left.svg": require("../../../assets/icons/arrow-left.svg"),
  "icons/arrow-right.svg": require("../../../assets/icons/arrow-right.svg"),
  "img/mockups.png": require("../../../assets/img/mockups.png"),
  "icons/text_in_circle.png": require("../../../assets/icons/text_in_circle.png"),
  "icons/clock.png": require("../../../assets/icons/clock.png"),
  "icons/credit-card.png": require("../../../assets/icons/credit-card.png"),
  "icons/ico_card_512.png": require("../../../assets/icons/ico_card_512.png"),
  "icons/ico_bank_512.png": require("../../../assets/icons/ico_bank_512.png"),
  "icons/ico_wallet_512.png": require("../../../assets/icons/ico_wallet_512.png"),
  "icons/ico_upi_512.png": require("../../../assets/icons/ico_upi_512.png"),
  "icons/ico_hdfc_512.png": require("../../../assets/icons/ico_hdfc_512.png"),
  "icons/ico_citbank_512.png": require("../../../assets/icons/ico_citbank_512.png"),
  "icons/ico_icici_512.png": require("../../../assets/icons/ico_icici_512.png"),
  "icons/ico_sbi_512.png": require("../../../assets/icons/ico_sbi_512.png"),
  "icons/ico_kotak_512.png": require("../../../assets/icons/ico_kotak_512.png"),
  "icons/ico_axis_512.png": require("../../../assets/icons/ico_axis_512.png"),
  "icons/ico_standard_512.png": require("../../../assets/icons/ico_standard_512.png"),
  "icons/lock_icon.png": require("../../../assets/icons/lock_icon.png"),
  "icons/story-twitter.png": require("../../../assets/icons/story-twitter.png"),
  "icons/story-linkedin.png": require("../../../assets/icons/story-linkedin.png"),
  "icons/story-google.png": require("../../../assets/icons/story-google.png"),
  "icons/story-apple.png": require("../../../assets/icons/story-apple.png"),
  "icons/rightarrow-new.png": require("../../../assets/icons/rightarrow-new.png"),
  "icons/popup-google.png": require("../../../assets/icons/popup-google.png"),
  "img/extras/app-seo.png": require("../../../assets/img/extras/app-seo.png"),
  "icons/tmc_logo_with_text.png": require("../../../assets/icons/tmc_logo_with_text.png"),
  "img/referral-gift.svg": require("../../../assets/img/referral-gift.svg"),
  "img/sub-gift.png": require("../../../assets/img/sub-gift.png"),
  "img/gift-monthly.svg": require('../../../assets/img/gift-monthly.svg'),
  "img/hero-banner-pricing.png": require("../../../assets/img/hero-banner-pricing.png"),
  "icons/green-tick.png": require("../../../assets/icons/green-tick.png"),
  "img/why-subscribe-bg.png": require('../../../assets/img/why-subscribe-bg.png'),
  "img/corporate-img-2.png": require("../../../assets/img/corporate-img-2.png"),
  "img/corporate-img.png": require("../../../assets/img/corporate-img.png"),
  "icons/start-reading.png": require("../../../assets/icons/start-reading.png"),
  "icons/invite-members-corporate.png": require("../../../assets/icons/invite-members-corporate.png"),
  "icons/purchase-seat.png": require("../../../assets/icons/purchase-seat.png"),
  "img/student-plan-img.jpeg": require("../../../assets/img/student-plan-img.jpeg"),
  "img/student-img.png": require("../../../assets/img/student-img.png"),
  "img/student-callout.png": require("../../../assets/img/student-callout.png"),
  "img/teacher-university-plan.png": require("../../../assets/img/teacher-university-plan.png"),
  "img/callout-illustration.png": require("../../../assets/img/callout-illustration.png"),
  "img/friends-of-tmc.png": require("../../../assets/img/friends-of-tmc.png"),
  "img/tmc-supporters.png": require("../../../assets/img/tmc-supporters.png"),
  "img/press-freedom-index.jpg": require("../../../assets/img/press-freedom-index.jpg"),
  "icons/times-internet-logo.png": require("../../../assets/icons/times-internet-logo.png"),
  "icons/iit-delhi-logo.png": require("../../../assets/icons/iit-delhi-logo.png"),
  "icons/paul-writer-logo.png": require("../../../assets/icons/paul-writer-logo.png"),
  "icons/platoon-one-films-logo.png": require("../../../assets/icons/platoon-one-films-logo.png"),
  "icons/a91-logo.png": require("../../../assets/icons/a91-logo.png"),
  "icons/mindtree-logo.png": require("../../../assets/icons/mindtree-logo.png"),
  "icons/yuvaa-logo.png": require("../../../assets/icons/yuvaa-logo.png"),
  "icons/iim-ahmedabad-logo.png": require("../../../assets/icons/iim-ahmedabad-logo.png"),
  "icons/iim-amritsar-logo.png": require("../../../assets/icons/iim-amritsar-logo.png"),
  "icons/iim-kozikhode-logo.png": require("../../../assets/icons/iim-kozikhode-logo.png"),
  "icons/isb-logo.png": require("../../../assets/icons/isb-logo.png"),
  "icons/iim-indore-logo.png": require("../../../assets/icons/iim-indore-logo.png"),
  "img/pricing-hero-top-mob.png": require("../../../assets/img/pricing-hero-top-mob.png"),
  "img/pricing-hero-bottom-mob.png": require("../../../assets/img/pricing-hero-bottom-mob.png"),
  "img/pricing-hero-bg-mob.png": require("../../../assets/img/pricing-hero-bg-mob.png"),
  "img/corporate-img-mob.png": require("../../../assets/img/corporate-img-mob.png"),
  "img/student-img-mob.png": require("../../../assets/img/student-img-mob.png"),
  "img/teacher-img-mob.png": require("../../../assets/img/teacher-img-mob.png"),
  "img/friends-of-tmc-img-mob.png": require("../../../assets/img/friends-of-tmc-img-mob.png"),
  "img/student-refund-mob.png": require("../../../assets/img/student-refund-mob.png"),
  "icons/carousel-selected.png": require("../../../assets/icons/carousel-selected.png"),
  "icons/carousel-unselected.png": require("../../../assets/icons/carousel-unselected.png"),
  "icons/faq-icon.svg": require("../../../assets/icons/faq-icon.svg"),
  "icons/faq-icon-opened.svg": require("../../../assets/icons/faq-icon-opened.svg"),
  "img/hero-banner-mob.png": require("../../../assets/img/hero-banner-mob.png"),
  "icons/lock-icon.svg": require("../../../assets/icons/lock-icon.svg"),
  "icons/lock-icon-dark.svg": require("../../../assets/icons/lock-icon-dark.svg"),
  "icons/dark-tick.svg": require("../../../assets/icons/dark-tick.svg"),
  "img/upgrade-hero-banner.png": require("../../../assets/img/upgrade-hero-banner.png"),
  "img/upgrade-hero-banner-mob.png": require("../../../assets/img/upgrade-hero-banner-mob.png"),
  "icons/friends-of-tmc-icon.svg": require("../../../assets/icons/friends-of-tmc-icon.svg"),
  "icons/friends-of-tmc-icon-dark.svg": require("../../../assets/icons/friends-of-tmc-icon-dark.svg"),
  "icons/cross-icon-red.png": require("../../../assets/icons/cross-icon-red.png"),
  "img/our-readers-img.svg": require("../../../assets/img/our-readers-img.svg"),
  "img/sectors-our-readers-come-from-img.png": require("../../../assets/img/sectors-our-readers-come-from-img.png"),
  "img/where-are-our-readers.png": require("../../../assets/img/where-are-our-readers.png"),
  "icons/flag-India-icon.png": require("../../../assets/icons/flag-India-icon.png"),
  "icons/flag-UK-icon.png": require("../../../assets/icons/flag-UK-icon.png"),
  "icons/flag-Singapore-icon.png": require("../../../assets/icons/flag-Singapore-icon.png"),
  "icons/flag-Germany-icon.png": require("../../../assets/icons/flag-Germany-icon.png"),
  "icons/flag-USA-icon.png": require("../../../assets/icons/flag-USA-icon.png"),
  "icons/legal-services.svg": require("../../../assets/icons/legal-services.svg"),
  "icons/it-technology.svg": require("../../../assets/icons/it-technology.svg"),
  "icons/govt.svg": require("../../../assets/icons/govt.svg"),
  "icons/banking.svg": require("../../../assets/icons/banking.svg"),
  "icons/accountancy.svg": require("../../../assets/icons/accountancy.svg"),
  "icons/business-leaders.svg": require("../../../assets/icons/business-leaders.svg"),
  "icons/industrial-goods-and-services.svg": require("../../../assets/icons/industrial-goods-and-services.svg"),
  "icons/startups.svg": require("../../../assets/icons/startups.svg"),
  "icons/aerospace-and-defence.svg": require("../../../assets/icons/aerospace-and-defence.svg"),
  "icons/retails.svg": require("../../../assets/icons/retails.svg"),
  "icons/faculty-icon.png": require("../../../assets/icons/faculty-icon.png"),
  "icons/entrepreneurs-icon.png": require("../../../assets/icons/entrepreneurs-icon.png"),
  "icons/vc-icon.png": require("../../../assets/icons/vc-icon.png"),
  "icons/stock-market-icon.png": require("../../../assets/icons/stock-market-icon.png"),
  "icons/senior-leadership-icon.png": require("../../../assets/icons/senior-leadership-icon.png"),
  "icons/private-equity-firms-icon.png": require("../../../assets/icons/private-equity-firms-icon.png"),
  "icons/research-org-icon.png": require("../../../assets/icons/research-org-icon.png"),
  "icons/search-indigo.png": require("../../../assets/icons/search-indigo.png"),
  "icons/slider-arrow-icon.svg": require("../../../assets/icons/slider-arrow-icon.svg"),
  "icons/whatsapp-icon-white.svg": require("../../../assets/icons/whatsapp-icon-white.svg"),
};
export default imagePaths;
