import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Dimensions,
  TextInput,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  article,
  formStyle,
  Colors,
  articleItem,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import PageCover from "../../components/page-cover";
import PageCoverMobile from "../../components/page-cover-mobile";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import ContentLoader from "react-native-easy-content-loader";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const coverimage = getImagePath("img/companyBg.png");
const coverimageDark = getImagePath("img/companyBgDark.png");
const cookies = new Cookies();
const searchBox = getImagePath("icons/searchbox.png");
const upArrowIcon = getImagePath("icons/uparrow.png");
const noArticles = getImagePath("img/no-articles.png");

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "0-9",
];
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: this.props.companyData
        ? this.sortCompany(this.props.companyData.company)
        : "",
      companyDataSSR: this.props.companyData ? true : false,
      searchText: "",
      pageLoader: this.props.companyData ? false : true,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      custom_user_id: false
    };

    this.alphFilterOffset = [];
    this.alphFilterOffsetMain = 0;
    this.alphFilterOffsetSub = 0;
    this.alphFilterOffsetTitle = 0;
  }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false,
        });
      } else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id,
        });
      }
    });
  };

  componentDidMount = () => {
    if (!this.state.companyDataSSR) {
      this.getCompanies();
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken();
    }
  };

  componentWillUnmount = () => {
    if (this.scrollRef) {
      this.scrollRef();
    }
  };

  getCompanies = () => {
    this.setState({
      showErrorModal: false,
      pageLoader: true,
    });
    let endpoint = `/companies`;
    let body = {
      sort_on: "name",
      sort_by: "asc",
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "company_found") {
          let companyData = this.sortCompany(res.data.company);
          this.setState({
            companyData: companyData,
            pageLoader: false,
          });
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  sortCompany = (companyList) => {
    let companies = {};
    companyList.map((company) => {
      let firstLetter = company.name.substr(0, 1).toUpperCase();
      let letterIndex = firstLetter;
      if (numbers.includes(firstLetter)) {
        letterIndex = "0-9";
      }
      if (companies[letterIndex]) {
        companies[letterIndex].push(company);
      } else {
        companies[letterIndex] = [company];
      }
    });
    const sorted_object = {};
    Object.keys(companies)
      .sort()
      .forEach(function (key) {
        sorted_object[key] = companies[key];
      });
    if (sorted_object["0-9"]) {
      let first_value = sorted_object["0-9"];
      delete sorted_object["0-9"];
      sorted_object["0-9"] = first_value;
    }

    return sorted_object;
  };

  getModalClickEvent = () => { };

  viewCompanyDetails = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/company/" + slug);
    } else {
      this.props.navigation.navigate("company-archive", { archiveSlug: slug });
    }
  };

  getScrollOffset = (event) => {
    let scrollCheck;
    if (helpers.isWeb()) {
      scrollCheck = window.pageYOffset;
    } else {
      scrollCheck = event.nativeEvent.contentOffset.y;
    }
    if (scrollCheck > 500) {
      this.setState({
        showUparrow: true,
      });
    } else {
      this.setState({
        showUparrow: false,
      });
    }
  };

  applyRef = (ref) => {
    if (helpers.isWeb()) {
      this.scrollRef = window.addEventListener("scroll", this.getScrollOffset);
    }
  };

  scrollToTop = () => {
    if (helpers.isWeb()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(0);
    }
  };

  scrollToSection = (element) => {
    let offsetPosition =
      this.alphFilterOffset[element] +
      this.alphFilterOffsetMain +
      this.alphFilterOffsetSub -
      this.alphFilterOffsetTitle;

    if (helpers.isWeb()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(offsetPosition);
    }
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };

  // showPageLoader = () => {
  //     const webView = helpers.isWeb();
  //     return (
  //         <View style={[Flex.justfiyCenter, Flex.alignCenter,
  //         {
  //             position: webView ? 'fixed' : 'absolute',
  //             left: 0,
  //             right: 0,
  //             top: 0,
  //             bottom: 0,
  //             backgroundColor: '#ffffff',
  //         }]}
  //         >
  //             <ActivityIndicator size={25} color={'#000000'} />
  //         </View>
  //     )
  // }

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Margin.mt_4,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
        ]}
      >
        {helpers.isDesktop() ? (
          <PageCover
            title={"Company"}
            coverImage={darkMode ? coverimageDark : coverimage}
            require={true}
            pageCoverOverlay={false}
            content={`We have written on some of the leading business groups, tech companies and investment firms. Here’s a selection of stories on them divided by theme.`}
            darkMode={darkMode}
          />
        ) : (
          <PageCover
            title={"Company"}
            coverImage={darkMode ? coverimageDark : coverimage}
            require={true}
            content={`We have written on some of the leading business groups, tech companies and investment firms. Here’s a selection of stories on them divided by theme.`}
            company={true}
            darkMode={darkMode}
          />
        )}
      </View>
    );
  };

  filterCompanies = (list, search_text) => {
    let filtered_array = list.filter((item) =>
      item.name.toLowerCase().includes(search_text.toLowerCase())
    );
    return filtered_array.length > 0 ? true : false;
  };

  handleInputBlur = () => {
    if (this.state.searchText.trim() !== '') {
      this.pushSearchInteractionEventToDataLayer(this.state.searchText)
    }
  }

  pushSearchInteractionEventToDataLayer = (searchText) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "search_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      search_text: searchText,
      category_type: "NA"
    });
  }

  renderPageContent = () => {
    const { companyData, searchText } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let company_found = false;
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && Flex.row,
            !helpers.isDesktop() && Flex.column,
            Flex.justfiyCenter,
            Margin.mb_2,
            {
              alignItems: helpers.isDesktop() ? "baseline" : "center",
              width: helpers.isDesktop() ? 500 : "100%",
              marginHorizontal: "auto",
            },
            !helpers.isDesktop() && { paddingHorizontal: 20 },
          ]}
        >
          <View style={{ width: helpers.isDesktop() ? 500 : "100%" }}>
            <TextInput
              placeholderTextColor={Colors.placeholderTextColor}
              style={[
                Padding.pl_2,
                {
                  borderWidth: 1,
                  borderColor: "#907CFF",
                  height: helpers.isDesktop() ? 46 : 40,
                  paddingRight: helpers.isDesktop() ? 80 : 60,
                  width: "100%",
                  fontFamily: FontFamily.regular,
                  color: "#000",
                },
                darkMode && { color: Colors.lightWhite },
              ]}
              placeholder={"Search"}
              onChangeText={(text) => 
                {
                  this.setState({ searchText: text })
                }
              }
              onBlur={this.handleInputBlur}
              value={searchText}
            />
            <CustomImage
              source={searchBox}
              require={true}
              style={{
                width: 42,
                height: 42,
                position: "absolute",
                right: 0,
                top: -1,
                resizeMode: "contain",
              }}
              webStyle={{
                width: 48,
                height: helpers.isDesktop() ? 46 : 40,
                position: "absolute",
                right: helpers.isDesktop() ? 0 : -2,
                top: 0,
                objectFit: "contain",
              }}
            />
          </View>
        </View>
        <View
          ref={this.applyRef}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            this.alphFilterOffsetMain = layout.y;
          }}
        >
          <View
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              this.alphFilterOffsetTitle = layout.y;
            }}
            style={[
              Padding.ph_2,
              Margin.mt_3,
              helpers.isDesktop() && Margin.mb_4,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            {/* <View style={[Helpers.alphFilterWrap]}>
                            {alphabets.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => this.scrollToSection(item)}
                                        key={`aplph_${index}`}
                                        activeOpacity={0.8}>
                                        <Text style={[Helpers.alphText]}>{item}</Text>
                                    </TouchableOpacity>
                                )
                            })}
                        </View> */}
          </View>
          <View
            style={[
              Padding.ph_2,
              helpers.isDesktop() && Margin.mb_4,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              this.alphFilterOffsetSub = layout.y;
            }}
          >
            {companyData &&
              Object.keys(companyData).map((item, index) => {
                if (this.filterCompanies(companyData[item], searchText)) {
                  company_found = true;
                  return (
                    <View
                      onLayout={(event) => {
                        const layout = event.nativeEvent.layout;
                        this.alphFilterOffset[item] = layout.y;
                      }}
                      key={`compItem${index}`}
                      style={[Margin.mb_5]}
                    >
                      <View
                        style={[
                          Helpers.capitalLetterWrap,
                          Flex.row,
                          Flex.justifyBetween,
                          Flex.alignCenter,
                          !helpers.isDesktop() && { borderBottomWidth: 0 },
                        ]}
                      >
                        <Text
                          style={[
                            Helpers.captialLetter,
                            {
                              fontSize: helpers.isDesktop() ? 36 : 24,
                              lineHeight: helpers.isDesktop() ? 45 : 35,
                              width: 150,
                            },
                            !helpers.isDesktop() &&
                            helpers.isWeb() && {
                              fontWeight: "700",
                              fontFamily: FontFamily.regular,
                            },
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                            darkMode && { color: Colors.lightWhite },
                            Platform.OS == "ios" && { fontWeight: "600" },
                          ]}
                        >
                          {item}
                        </Text>
                        {helpers.isDesktop() && (
                          <Text
                            style={[
                              Helpers.captialLetter,
                              {
                                fontSize: 30,
                                lineHeight: 45,
                                color: "#999",
                                width: 340,
                                textAlign: "left",
                              },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            Latest Story
                          </Text>
                        )}
                        {helpers.isDesktop() && (
                          <Text
                            style={[
                              Helpers.captialLetter,
                              { fontSize: 30, lineHeight: 45, color: "#999" },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            Stories
                          </Text>
                        )}
                      </View>
                      {companyData[item] != undefined && (
                        <View key={`comp${index}`}>
                          {companyData[item].map((citem, index) => {
                            if (
                              searchText != "" &&
                              !citem.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            ) {
                              return <></>;
                            }
                            if(!citem.latest_story.category){
                              console.log("Debug: ", citem)
                              citem.latest_story.category = {slug: 'yesterday'}
                            }
                            let btnClick,
                              btnTitleClick,
                              authorClick,
                              author2BtnClick;
                            if (helpers.isWeb()) {
                              btnClick = {
                                to: "/company/" + citem.slug,
                              };
                              btnTitleClick = {
                                to: {
                                  pathname:
                                    "/" +
                                    citem.latest_story.category.slug +
                                    "/" +
                                    citem.latest_story.slug,
                                  postData: citem.latest_story,
                                },
                              };
                              authorClick = {
                                to:
                                  "/writers/" +
                                  citem.latest_story.author[0].slug,
                              };
                              if (citem.latest_story.author[1]) {
                                author2BtnClick = {
                                  to:
                                    "/writers/" +
                                    citem.latest_story.author[1].slug,
                                };
                              }
                            } else {
                              btnClick = {
                                onPress: () =>
                                  this.viewCompanyDetails(citem.slug),
                              };
                              authorClick = {
                                onPress: (event) => {
                                  event.stopPropagation();
                                  viewAuthor(citem.latest_story.author[0].slug);
                                },
                              };
                              if (citem.latest_story.author[1]) {
                                author2BtnClick = {
                                  onPress: (event) => {
                                    event.stopPropagation();
                                    viewAuthor(
                                      citem.latest_story.author[1].slug
                                    );
                                  },
                                };
                              }
                            }
                            return (
                              <>
                                {helpers.isDesktop() ? (
                                  <View
                                    key={`cbutton${index}`}
                                    style={[
                                      Flex.row,
                                      Flex.justifyBetween,
                                      Flex.alignStart,
                                      helpers.isDesktop() && Margin.mb_4,
                                      !helpers.isDesktop() && Margin.mb_2,
                                    ]}
                                  >
                                    <View
                                      style={{
                                        width: helpers.isDesktop() ? 200 : 275,
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <TouchableOpacityLink {...btnClick}>
                                        <Text
                                          style={[
                                            Helpers.companyName,
                                            {
                                              fontSize: helpers.isDesktop()
                                                ? 22
                                                : 18,
                                              color: helpers.isDesktop()
                                                ? darkMode
                                                  ? Colors.darkBlue
                                                  : "#907CFF"
                                                : darkMode
                                                  ? Colors.lightWhite
                                                  : "#000",
                                              letterSpacing: 0.1,
                                              fontFamily:
                                                "PlayfairDisplay-Bold",
                                            },
                                            !helpers.isDesktop() && { flex: 1 },
                                          ]}
                                        >
                                          {citem.name}
                                        </Text>
                                      </TouchableOpacityLink>
                                    </View>
                                    <View
                                      style={[Flex.flexStart, { width: 370 }]}
                                    >
                                      {citem.latest_story.id && (
                                        <>
                                          <TouchableOpacityLink
                                            {...btnTitleClick}
                                          >
                                            <Text
                                              style={[
                                                Helpers.companyDetail,
                                                {
                                                  color: darkMode
                                                    ? Colors.lightWhite
                                                    : "#000",
                                                  fontFamily:
                                                    "PlayfairDisplay-Bold",
                                                },
                                              ]}
                                            >
                                              {citem.latest_story.title}
                                            </Text>
                                          </TouchableOpacityLink>
                                          <View
                                            style={[
                                              {
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                marginTop: 0,
                                                alignItems: "baseline",
                                                paddingTop: 10,
                                              },
                                            ]}
                                          >
                                            <TouchableOpacityLink
                                              {...authorClick}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 15,
                                                  fontWeight: "500",
                                                  fontFamily:
                                                    FontFamily.regular,
                                                  color: darkMode
                                                    ? Colors.lightWhite
                                                    : "#000",
                                                }}
                                              >
                                                {
                                                  citem.latest_story.author[0]
                                                    .name
                                                }
                                              </Text>
                                            </TouchableOpacityLink>
                                            {citem.latest_story.author[1] && (
                                              <>
                                                <Text
                                                  style={{
                                                    fontSize: 15,
                                                    fontWeight: "300",
                                                    fontFamily:
                                                      FontFamily.light,
                                                    color: darkMode
                                                      ? Colors.lightWhite
                                                      : "#000",
                                                  }}
                                                >
                                                  {" "}
                                                  and{" "}
                                                </Text>
                                                <TouchableOpacityLink
                                                  {...author2BtnClick}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: 15,
                                                      fontWeight: "500",
                                                      fontFamily:
                                                        FontFamily.regular,
                                                      color: darkMode
                                                        ? Colors.lightWhite
                                                        : "#000",
                                                    }}
                                                  >
                                                    {
                                                      citem.latest_story
                                                        .author[1].name
                                                    }
                                                  </Text>
                                                </TouchableOpacityLink>
                                              </>
                                            )}
                                            <View
                                              style={[
                                                articleItem.dot,
                                                {
                                                  position: "relative",
                                                  top: helpers.isDesktop()
                                                    ? -3
                                                    : -3,
                                                },
                                              ]}
                                            ></View>
                                            <Text
                                              style={{
                                                fontSize: 15,
                                                fontWeight: "300",
                                                fontFamily: FontFamily.light,
                                                color: darkMode
                                                  ? Colors.lightWhite
                                                  : "#000",
                                              }}
                                            >
                                              {moment(
                                                citem.latest_story
                                                  .published_date
                                              ).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                                            </Text>
                                          </View>
                                        </>
                                      )}
                                    </View>
                                    <View
                                      style={{
                                        width: helpers.isDesktop()
                                          ? 120
                                          : "auto",
                                      }}
                                    >
                                      {citem.stories_count > 1 ? (
                                        <Text
                                          style={[
                                            Helpers.companyDetail,
                                            {
                                              fontSize: helpers.isDesktop()
                                                ? 16
                                                : 14,
                                              color: darkMode
                                                ? Colors.lineColor
                                                : "#414141",
                                              fontWeight: "400",
                                              textAlign: "right",
                                            },
                                          ]}
                                        >
                                          {citem.stories_count
                                            ? citem.stories_count
                                            : 0}{" "}
                                          Stories
                                        </Text>
                                      ) : (
                                        <Text
                                          style={[
                                            Helpers.companyDetail,
                                            {
                                              fontSize: helpers.isDesktop()
                                                ? 16
                                                : 14,
                                              color: darkMode
                                                ? Colors.lineColor
                                                : "#414141",
                                              fontWeight: "400",
                                              textAlign: "right",
                                            },
                                          ]}
                                        >
                                          {citem.stories_count
                                            ? citem.stories_count
                                            : 0}{" "}
                                          Story
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                ) : (
                                  <View key={`cbutton${index}`}>
                                    <TouchableOpacityLink {...btnClick}>
                                      <View
                                        style={[
                                          Flex.row,
                                          Flex.justifyBetween,
                                          Flex.alignStart,
                                          Margin.mb_2,
                                        ]}
                                      >
                                        <View
                                          style={{
                                            width: helpers.isDesktop()
                                              ? 200
                                              : 275,
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <Text
                                            style={[
                                              Helpers.companyName,
                                              {
                                                fontSize: helpers.isDesktop()
                                                  ? 22
                                                  : 18,
                                                color: helpers.isDesktop()
                                                  ? darkMode
                                                    ? Colors.darkBlue
                                                    : "#907CFF"
                                                  : darkMode
                                                    ? Colors.lightWhite
                                                    : "#000",
                                                letterSpacing: 0.1,
                                              },
                                              !helpers.isDesktop() && {
                                                flex: 1,
                                              },
                                            ]}
                                          >
                                            {citem.name}
                                          </Text>
                                        </View>

                                        <View
                                          style={{
                                            width: helpers.isDesktop()
                                              ? 120
                                              : "auto",
                                          }}
                                        >
                                          <Text
                                            style={[
                                              Helpers.companyDetail,
                                              {
                                                fontSize: helpers.isDesktop()
                                                  ? 16
                                                  : 14,
                                                color: darkMode
                                                  ? Colors.lineColor
                                                  : "#414141",
                                                fontWeight: "400",
                                                textAlign: "right",
                                              },
                                            ]}
                                          >
                                            {citem.stories_count
                                              ? citem.stories_count
                                              : 0}
                                          </Text>
                                        </View>
                                      </View>
                                    </TouchableOpacityLink>
                                  </View>
                                )}
                              </>
                            );
                          })}
                        </View>
                      )}
                    </View>
                  );
                }
              })}
            {!company_found && (
              <View style={[Flex.alignCenter, Margin.mb_6]}>
                <CustomImage
                  source={noArticles}
                  require={true}
                  style={{
                    width: helpers.isDesktop() ? 360 : 200,
                    height: helpers.isDesktop() ? 360 : 200,
                  }}
                  webStyle={{
                    width: helpers.isDesktop() ? 360 : 200,
                    height: helpers.isDesktop() ? 360 : 200,
                  }}
                  altText={"Match not found"}
                />
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 36 : 24,
                    lineHeight: 43,
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center",
                    fontFamily: FontFamily.medium,
                    color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.9)",
                  }}
                >
                  Whoops!
                </Text>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 24 : 16,
                    lineHeight: helpers.isDesktop() ? 30 : 24,
                    marginBottom: 10,
                    textAlign: "center",
                    fontFamily: FontFamily.regular,
                    color: darkMode ? Colors.lineColor : "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  We couldn’t find a match for "{this.state.searchText}"
                </Text>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 24 : 16,
                    lineHeight: helpers.isDesktop() ? 30 : 24,
                    marginBottom: 10,
                    textAlign: "center",
                    fontFamily: FontFamily.regular,
                    color: darkMode ? Colors.lineColor : "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  Please try another search.
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/company",
      native: { redirect: "/company", verifyRedirect: "/company" },
    };

    return (
      <ScrollView
        onScroll={this.getScrollOffset}
        scrollEventThrottle={16}
        ref={(ref) => (this.appscrollview = ref)}
      >
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/company",
      native: { redirect: "/company", verifyRedirect: "/company" },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["company"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/company"
        />

        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? "fixed" : "absolute",
                  bottom: 20,
                  left: 0,
                  right: 0,
                  zIndex: 2,
                  width: helpers.isDesktop() ? "1280px" : "100%",
                  margin: "auto",
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CustomImage
                    source={upArrowIcon}
                    require={true}
                    style={{ width: 60, height: 60 }}
                    webStyle={{ width: 60, height: 60 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(Company));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Company",
  },
];
