import moment from "moment";
import React, { Component } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import ContentLoader from "react-native-easy-content-loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppFooter from "../../components/app-footer";
import AppHeader from "../../components/app-header";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import CustomImage from "../../components/custom-image";
import ErrorModal from "../../components/error-modal";
import PageCover from "../../components/page-cover";
import Seo from "../../components/seo-meta";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import { Picker } from "@react-native-picker/picker";
import {
  appStyles,
  article,
  Flex,
  FontFamily,
  Helpers,
  Margin,
  teamSubscription,
  articleItemHorizontal,
} from "../../styles/appStyles";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import seoObj from "../../utils/seo-helper";
import { getWeeks } from "./last-week-algo";
import MonthYearPicker from "react-native-simple-month-year-picker";

const teamOne = getImagePath("img/teamBannerOne.png");
const authorProfile = getImagePath("img/pranav-profile.jpg");

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export class LastWeekData extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();

    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;

    this.state = {
      pageLoader: true,
      showErrorModal: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      token: token,
      // stories:[],
      storiesMap: [],
      selectedTab: 0,
      seeSecondAuthor: false,
      loader: true,
      // selectedValueMonth:new Date().getMonth()+1,// current month value
      tab_list: [],
      // selectedValueYear:new Date().getFullYear()
      showAuthorPostId: "",
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().toLocaleString("default", { month: "short" }),
      allYears: [],
      allMonths: [],
      selectedRangeStartDate: "",
      selectedRangeEndDate: "",
    };
    this.setSelectedYear = this.setSelectedYear.bind(this);
  }

  //   Render page
  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={{ marginTop: "80px", marginBottom: "-30px" }}>
        <PageCover
          title={this.getPageTitle()}
          pageCoverOverlay={true}
          topRightContent={null}
          darkMode={darkMode}
        />
      </View>
    );
  };
  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;

    viewArticle(catSlug, slug, postData);
  };
  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };
  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };
  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_section: this.getPageTitle(),
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: this.getPageTitle(),
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  setTab = (index, item) => {
    let { selectedTab } = this.state;

    let start_date = new Date(item.start_date).toISOString().split("T")[0];
    let end_date = new Date(item.end_date).toISOString().split("T")[0];
    this.setState({
      selectedTab: index,
      selectedRangeStartDate: start_date,
      selectedRangeEndDate: end_date,
    });
    if (selectedTab != index) {
      this.setState({
        loader: true,
        seeSecondAuthor: false,
      });
      this.getStories(start_date, end_date);
    }
  };

  storiesMapData = (storiesMap, selectedTab, seeSecondAuthor) => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      // this.state.loader ? (
      //   <View style={[styles.container]}>
      //     <ActivityIndicator size="large" style={styles.activityIndicator} />
      //   </View>
      // ) : (
      <>
        {storiesMap &&
          storiesMap.map((item, index) => {
            let is_shorts = false;
            let btnClick,
              authorBtnClick,
              author2BtnClick,
              btnClickBookmark,
              btnClickLike;
            is_shorts =
              item?.types && item.types.filter((d) => d.slug == "short");
            if (helpers.isWeb()) {
              btnClick = {
                to: {
                  pathname: "/" + item.category.slug + "/" + item.slug,
                  postData: item,
                },
              };
              authorBtnClick = {
                to: "/writers/" + item.author[0].slug,
              };
              if (item.author[1]) {
                author2BtnClick = {
                  to: "/writers/" + item.author[1].slug,
                };
              }
            }
    
            const typesArray = item?.types?.map(item => {
              return item.name.toLowerCase();
            })
        
            let btnCategoryClick = {
              to: "/category/" + item.category.slug,
              handleClick: item?.newsletter?.name
                ? () =>
                    this.pushNewsletterCategoryClickToDataLayer(
                      item?.newsletter?.slug,
                      typesArray.join(",")
                    )
                : () =>
                    this.pushArticleCategoryClickToDataLayer(
                      item?.category?.slug,
                      typesArray.join(",")
                    ),
            };

            return (
              <View
                style={[
                  Flex.column,
                  Flex.FlexWrap,
                  {
                    marginBottom: helpers.isDesktop() ? 56 : 0,
                    padding: 24,
                    width: helpers.isDesktop() ? "33%" : "100%",
                  },
                ]}
              >
                <View
                  style={[{ maxWidth: helpers.isDesktop() ? "100%" : "100%" }]}
                >
                  <TouchableOpacityLink {...btnCategoryClick}>
                    <Text
                      style={[
                        {
                          width: 250,
                          marginBottom: helpers.isDesktop() ? 16 : 8,
                          textAlign: "left",
                          textTransform: "uppercase",
                          fontFamily: FontFamily.medium,
                          fontWeight: "600",
                          display: "block",
                          color:
                            is_shorts !== false && is_shorts.length > 0
                              ? "#000"
                              : item.category.name === "Business"
                                ? "#2CB680"
                                : item.category.name === "Chaos"
                                  ? "#907cff"
                                  : item.category.name === "Internet"
                                    ? "#FC876D"
                                    : "#ECB731",
                          fontSize: helpers.isDesktop() ? 19 : 13,
                          lineHeight: helpers.isDesktop() ? 22.8 : 16,
                          letterSpacing: helpers.isDesktop() ? 1 : 1,
                        },
                        !helpers.isWeb() && { fontFamily: FontFamily.medium },
                        darkMode && { color: "#5263F4" },
                      ]}
                    >
                      {is_shorts !== false && is_shorts.length > 0
                        ? "News Brief"
                        : item?.newsletter?.name
                          ? item.newsletter.name
                          : item.category.name}
                    </Text>
                  </TouchableOpacityLink>
                  <TouchableOpacityLink {...btnClick}>
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          paddingBottom: 1,
                          display: "block",
                          fontSize: helpers.isDesktop() ? 30 : 20,
                          lineHeight: helpers.isDesktop() ? 37 : 31.52,
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          marginBottom: helpers.isDesktop() ? 15 : 0,
                          fontFamily: "PlayfairDisplay-Bold",
                        },
                      ]}
                    >
                      {item.title}
                    </Text>
                    {/* <Text
                      style={[
                        {
                          fontWeight: "400",
                          fontSize: helpers.isDesktop() ? 18 : 18,
                          lineHeight: helpers.isDesktop() ? 29 : 29,
                          letterSpacing: 0.03,
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        { fontFamily: "Matteo-regular" },
                      ]}
                    >
                      {item.strapline}
                    </Text> */}
                  </TouchableOpacityLink>

                  <View
                    style={{
                      width: 100,
                      height: 2,
                      backgroundColor: "#907CFF",
                      marginTop: helpers.isDesktop() ? 16 : 8,
                      marginBottom: helpers.isDesktop() ? 18 : 10,
                    }}
                  ></View>
                  <View
                    style={[
                      Flex.row,
                      Flex.alignCenter,
                      { width: "100%", flexWrap: "wrap" },
                    ]}
                  >
                    <TouchableOpacityLink {...authorBtnClick}>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          { marginRight: 15 },
                        ]}
                      >
                        <View
                          style={{
                            width: 34,
                            height: 34,
                            overflow: "hidden",
                            marginRight: 10,
                            borderRadius: "50%",
                            padding: 1,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#FC876D",
                          }}
                        >
                          <View
                            style={{
                              width: 30,
                              height: 30,
                              overflow: "hidden",
                              borderRadius: "50%",
                            }}
                          >
                            <CustomImage
                              source={item.author[0].profile_image}
                              require={true}
                            />
                          </View>
                        </View>
                        <Text
                          style={[
                            {
                              fontWeight: "400",
                              fontSize: helpers.isDesktop() ? 16 : 16,
                              lineHeight: helpers.isDesktop() ? 19 : 22,
                            },
                            darkMode && { color: "rgba(255,255,255,0.87)" },
                            { fontFamily: FontFamily.regular },
                          ]}
                        >
                          {item.author[0].name}{" "}
                        </Text>
                      </View>
                    </TouchableOpacityLink>
                    <TouchableOpacityLink {...author2BtnClick}>
                      {seeSecondAuthor && item.author[1] && (
                        <View
                          style={[
                            Flex.row,
                            Flex.alignCenter,
                            { marginRight: 15 },
                          ]}
                        >
                          <View
                            style={{
                              width: 34,
                              height: 34,
                              overflow: "hidden",
                              marginRight: 10,
                              borderRadius: "50%",
                              padding: 1,
                              borderStyle: "solid",
                              borderWidth: 1,
                              borderColor: "#FC876D",
                            }}
                          >
                            <View
                              style={{
                                width: 30,
                                height: 30,
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            >
                              <CustomImage
                                source={item.author[1].profile_image}
                                require={true}
                              />
                            </View>
                          </View>
                          <Text
                            style={[
                              {
                                fontWeight: "400",
                                fontSize: helpers.isDesktop() ? 16 : 16,
                                lineHeight: helpers.isDesktop() ? 19 : 22,
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                              { fontFamily: FontFamily.regular },
                            ]}
                          >
                            {item.author[1].name}
                          </Text>
                        </View>
                      )}
                    </TouchableOpacityLink>

                    {item.author.length > 1 && !seeSecondAuthor && (
                      <TouchableOpacity
                        onPress={() => this.setState({ seeSecondAuthor: true })}
                      >
                        <View
                          style={[
                            {
                              borderStyle: "solid",
                              fontFamily: FontFamily.regular,
                              fontSize: helpers.isDesktop() ? 16 : 16,
                              lineHeight: helpers.isDesktop() ? 19 : 22,
                              borderWidth: 1,
                              backgroundColor: "#F3F5FF",
                              borderColor: "#907CFF",
                              width: 28,
                              height: 28,
                              borderRadius: 28,
                            },
                            Flex.alignCenter,
                            Flex.row,
                            Flex.justfiyCenter,
                          ]}
                        >
                          +1
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                  <View
                    style={
                      (helpers.isDesktop && { alignSelf: "center" },
                        !helpers.isDesktop && { alignSelf: "flex-start" })
                    }
                  >
                    <Text
                      style={[
                        articleItemHorizontal.articleDate,
                        helpers.isDesktop() &&
                        articleItemHorizontal.articleDateDesktop,
                        !helpers.isWeb() &&
                        articleItemHorizontal.articleDateApp,
                        { fontWeight: "300", color: "#292929" },
                        !helpers.isDesktop() && { fontSize: 12 },
                        { marginLeft: 44 },
                      ]}
                    >
                      {moment(item.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
      </>
    );
  };

  fetchDetailsYear = (itemValue) => {
    let { selectedRangeStartDate, selectedRangeEndDate, selectedTab } =
      this.state;
    let get_date_month = new Date(
      `31-${this.state.selectedMonth}-${itemValue}`
    );
    let { tab_list } = getWeeks(new Date(get_date_month), 5);

    let start_date = new Date(tab_list[selectedTab].start_date)
      .toISOString()
      .split("T")[0];
    let end_date = new Date(tab_list[selectedTab].end_date)
      .toISOString()
      .split("T")[0];
    this.setState(
      {
        selectedRangeStartDate: start_date,
        selectedRangeEndDate: end_date,
        selectedYear: itemValue,
        tab_list,
        // storiesMap: [],
        loader: true,
      },
      () => {
        this.getMonthsFromYear();
        this.getStories(start_date, end_date);
      }
    );
  };

  /**
   * Select Year
   * @returns
   */
  setSelectedYear = (itemValue) => {
    this.setState(
      { selectedMonth: String(itemValue) === "2019" ? "Sept" : "Jan" },
      () => {
        this.fetchDetailsYear(itemValue);
      }
    );
  };

  /**
   * Select Month
   * @returns
   */
  setSelectedMonth = (itemValue) => {
    let { selectedRangeStartDate, selectedRangeEndDate, selectedTab } =
      this.state;
    let get_date_month = new Date(`31-${itemValue}-${this.state.selectedYear}`);
    let { tab_list } = getWeeks(new Date(get_date_month), 5);

    let start_date = new Date(tab_list[selectedTab].start_date)
      .toISOString()
      .split("T")[0];
    let end_date = new Date(tab_list[selectedTab].end_date)
      .toISOString()
      .split("T")[0];
    this.setState({
      selectedRangeStartDate: start_date,
      selectedRangeEndDate: end_date,
      selectedMonth: itemValue,
      tab_list,
      // storiesMap: [],
      loader: true,
    });
    this.getStories(start_date, end_date);
  };
  /**
   * Render Page Content
   * @returns
   */
  renderPageContent = () => {
    let {
      loader,
      storiesMap,
      selectedTab,
      seeSecondAuthor,
      selectedValueMonth,
      tab_list,
      selectedValueYear,
      allYears,
      allMonths,
      selectedYear,
      selectedMonth,
    } = this.state;
    return (
      <>
        <View style={[Flex.row, Flex.justfiyCenter, Flex.FlexWrap]}>
          <View
            style={[
              { marginRight: helpers.isDesktop() ? 27 : 18 },
              !helpers.isDesktop() && {
                marginTop: 10,
                marginBottom: 10,
                justifyContent: "center",
              },
            ]}
          >
            <View
              style={[
                Flex.row,
                Flex.justfiyCenter,
                Flex.FlexWrap,
                {
                  width: "100%",
                  marginBottom: 30,
                },
              ]}
            >
              <Picker
                selectedValue={selectedYear}
                style={{
                  border: "0",
                  fontSize: "18",
                  lineHeight: "26",
                  color: "#B1B1B1",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                }}
                onValueChange={(itemValue, itemIndex) =>
                  this.setSelectedYear(itemValue)
                }
              >
                {allYears.map((d, i) => {
                  return (
                    <Picker.Item
                      key={`year_${d}`}
                      color="#6a6a6a"
                      label={d}
                      value={d}
                    />
                  );
                })}
              </Picker>
              <View
                style={{
                  width: 2,
                  height: 13,
                  backgroundColor: "#AEAEAE",
                  transform: "rotate(20deg)",
                  margin: 15,
                }}
              ></View>
              <Picker
                selectedValue={selectedMonth}
                style={{
                  border: "0",
                  fontSize: "18",
                  lineHeight: "26",
                  fontWeight: 700,
                  color: "#051462",
                  backgroundColor: "#FFF",
                }}
                onValueChange={(itemValue, itemIndex) =>
                  this.setSelectedMonth(itemValue)
                }
              >
                {allMonths.map((d, i) => {
                  return (
                    <Picker.Item
                      key={`mon_${d}`}
                      color="#6a6a6a"
                      label={d}
                      value={d}
                    />
                  );
                })}
              </Picker>
            </View>
          </View>

          <View
            style={[
              Flex.row,
              Flex.justfiyCenter,
              Flex.FlexWrap,
              {
                width: "100%",
                marginBottom: 30,
                borderStyle: "dashed",
                borderBottomWidth: 1,
                borderBottomColor: "#000000",
              },
              !helpers.isDesktop && { paddingBottom: 5 },
            ]}
          >
            {helpers.isDesktop() ? (
              tab_list &&
              tab_list.map((item, idx) => {
                return (
                  <TouchableOpacity
                    style={[
                      {
                        padding: 10,
                        fontFamily: FontFamily.bold,
                        fontSize: helpers.isDesktop() ? 16 : 16,
                        lineHeight: helpers.isDesktop() ? 19 : 22,
                        paddingLeft: 30,
                        marginBottom: -2,
                        paddingRight: 30,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: "#B1B1B1",
                      },
                      !helpers.isDesktop() && {
                        borderBottomColor: "#000000",
                        marginBottom: 0,
                        borderRadius: 10,
                      },
                      selectedTab === idx && {
                        backgroundColor: "#ffffff",
                        borderTopRightRadius: 10,
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderBottomColor: "#ffffff",
                        borderColor: "#000000",
                        color: "#051462",
                      },
                    ]}
                    onPress={() => {
                      this.setTab(idx, item);
                    }}
                  >
                    {item.name}
                  </TouchableOpacity>
                );
              })
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    flex: 1,
                    backgroundColor: "#ecf0f1",
                    marginTop: 30,
                  },
                ]}
              >
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  {tab_list &&
                    tab_list.map((item, idx) => {
                      return (
                        <TouchableOpacity
                          style={[
                            {
                              // width: "20%",
                              padding: 10,
                              fontFamily: FontFamily.bold,
                              fontSize: helpers.isDesktop() ? 16 : 16,
                              lineHeight: helpers.isDesktop() ? 19 : 22,
                              paddingLeft: 20,
                              // marginBottom: -2,
                              paddingRight: 20,
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              color: "#B1B1B1",
                            },
                            !helpers.isDesktop() && {
                              borderBottomColor: "#000000",
                              marginBottom: 0,
                              borderRadius: 10,
                            },
                            selectedTab === idx && {
                              backgroundColor: "#ffffff",
                              borderTopRightRadius: 10,
                              borderStyle: "dashed",
                              borderWidth: 1,
                              borderBottomColor: "#ffffff",
                              borderColor: "#000000",
                              color: "#051462",
                            },
                          ]}
                          onPress={() => {
                            this.setTab(idx, item);
                          }}
                        >
                          <Text>{item.name}</Text>
                        </TouchableOpacity>
                      );
                    })}
                </ScrollView>
              </View>
            )}
          </View>
          <View
            style={[
              Flex.row,
              Flex.justifyStart,
              Flex.FlexWrap,
              { width: "100%", maxWidth: 1200, marginBottom: 50 },
            ]}
          >
            {this.state.loader ? (
              <View style={[styles.container]}>
                <ActivityIndicator
                  size="large"
                  style={styles.activityIndicator}
                />
              </View>
            ) : storiesMap && storiesMap.length !== 0 ? (
              this.storiesMapData(storiesMap, selectedTab, seeSecondAuthor)
            ) : (
              "Story unavailable"
            )}
          </View>
        </View>
      </>
    );
  };

  //   renderWebPage
  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </ScrollView>
    );
  };
  getSeoPage = () => {
    const { archiveType } = this.state;
    let pageTitle = "/all-stories";
    if (archiveType == "category") {
      pageTitle = "/category/:archive_slug/all";
    } else if (archiveType == "company") {
      pageTitle = "/company/:archive_slug";
    } else if (archiveType == "tag") {
      pageTitle = "/tag/:archive_slug";
    } else if (archiveType == "theme") {
      pageTitle = "/theme/:archive_slug";
    } else if (archiveType == "category-archive") {
      pageTitle = "/category/:archive_slug/archive";
    } else if (archiveType == "archive") {
      pageTitle = "/archive";
    }
    return pageTitle;
  };

  getSeoObject = () => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    if (archiveType == "all") {
      return seoObj["archive-all"];
    } else if (archiveData) {
      return seoObj.getListingPageSeo(archiveType, archiveSlug, archiveData);
    }
    return {};
  };
  getPageTitle = (lower, placeholder = false) => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    let title,
      pageTitle = "Last Week";
    return lower ? pageTitle.toLowerCase() : pageTitle;
  };
  showPageLoader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[20]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 50,
          }}
          active={true}
          pHeight={[100]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 10, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />

        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>

        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>

        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>

        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  getMonthsFromYear = () => {
    const _month = new Date().getMonth();
    const currentYear = String(new Date().getFullYear());
    const leastYear = "2019";
    switch (String(this.state.selectedYear)) {
      case currentYear:
        let _m = [...months];
        const previous = _m.splice(0, _month);
        const output = [...previous, months[_month]];
        this.setState({ allMonths: output });
        break;

      case leastYear:
        this.setState({
          allMonths: ["Sept", "Oct", "Nov", "Dec"],
        });
        break;

      default:
        this.setState({ allMonths: months });
        break;
    }
  };
  componentDidMount = () => {
    let { tab_list } = getWeeks(new Date(), 5);
    this.setState({ tab_list });

    /*get week start_date and end_date on page load
     */
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    let start_date = firstday.toISOString().split("T")[0];
    let end_date = new Date().toISOString().split("T")[0];
    this.setState({
      selectedRangeStartDate: start_date,
      selectedRangeEndDate: end_date,
      pageLoader: false,
    });

    this.getStories(start_date, end_date);

    // Set array list of years;
    let startYear = startYear || 2019;
    let years = [];

    while (startYear <= this.state.selectedYear) {
      years.unshift(startYear++);
    }
    this.setState({ allYears: years });
    this.getMonthsFromYear();
    // let monthOfYear = currentDate.getMonth();
  };

  /**
   * Get stories from the selected date
   * @param {*} start_date
   * @param {*} end_date
   */
  getStories = (start_date, end_date) => {
    let endpoint = `/stories`;
    let body = {
      sort_on: "date",
      start_date: `${start_date}`,
      end_date: `${end_date}`,
      limit: 30,
      filters: [
        {
          key: "newsletter",
          value: ["include"],
        },
        { key: "type", value: ["short", "regular", "newsletter"] },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        console.log("dta:", res.data.story);
        if (res.success && res.code == "story_found") {
          this.setState({
            storiesMap: res.data.story,
            loader: false,
          });
        } else {
          this.setState({
            storiesMap: [],
            loader: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  render() {
    const {
      pageLoader,
      showErrorModal,
      footerData,
      redirectSlugs,
      archiveData,
      archiveSlug,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();

    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={this.getSeoObject()}
          page={this.getSeoPage()}
          pageData={{ pageTitle: this.getPageTitle(), pageSlug: archiveSlug }}
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          allStories={this.state.archiveType == "all" ? true : false}
          setSearchText={(text) => this.setSearchText(text)}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
          </>
        )}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(LastWeekData));

let Breadcrumbs = [];
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80,
  },
});
