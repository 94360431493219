import React, { Component } from "react";
import {
  StyleSheet,
  ActivityIndicator,
  ImageBackground,
  StatusBar,
  ScrollView,
  SafeAreaView,
  Text,
  View,
  TouchableOpacity,
  findNodeHandle,
  Image,
  Dimensions
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HTML from "react-native-render-html";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppFooter from "../../components/app-footer";
import AppHeader from "../../components/app-header";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import ErrorModal from "../../components/error-modal";
import PostShare from "../../components/post-share";
import {
  appStyles,
  Colors,
  Flex,
  button,
  FontFamily,
  postExcerptTags,
  postExcerptClasses,
  postExcerptTagsDarkMode,
  Helpers,

  postSingle,
  Padding,
  Margin,
  Typo,
  actionBox,
  accessContent,
  exclusiveContent,
  textBlock,
  articleLoader,
  articleLists,
  FontWeight,
  article,
  articleItem,
  pageContent,

} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../../components/button-gradient";
import { DEVICE_WIDTH } from "../../components/custom-camera";
import ScrollContainerView from "../../components/scroll-container-view";
import DontStopReading from "../../components/dont-stop-reading";
import YesterdayDontStopReading from "../../components/yesterday-dont-stop-reading";
import apiHelpers from "../../utils/api-helpers";
import PostContent from "../../components/post-content";
import moment from "moment";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";
import { Popable } from "react-native-popable";
import YesterdayModal from "../../components/yesterday-modal";
import { ChargebeeController } from "../../utils/chargebee";
import UnlockStoryNew from "../../components/unlock-story-new";
import ContentLoader from "react-native-easy-content-loader";
import PageBreak from "../../components/page-break";
import SubscriptionPricingPlan from "../../components/subscription-pricing-plan";
import InsufficientCreditPopup from "../../components/insufficient-credit-popup";
import { Mixpanel } from "../../utils/mixpanel";

const healthcare = getImagePath("icons/healthcare.png");
const business = getImagePath("icons/business.png");
const covid = getImagePath("icons/covid-19.png");
const envoroment = getImagePath("icons/envoroment.png");
const markets = getImagePath("icons/markets.png");
const sports = getImagePath("icons/sports.png");
const world = getImagePath("icons/world.png");
const economy = getImagePath("icons/economy.png");
const choas = getImagePath("icons/choas.png");
const politics = getImagePath("icons/politics.png");

const facebook16 = getImagePath("icons/fb.png");
const link16 = getImagePath("icons/link16.png");
const twitter16 = getImagePath("icons/twitterIcon.png");
const linkedin16 = getImagePath("icons/linkedin_icon.png");
const giftIcon = getImagePath('icons/gift-dark.png');
const profile = getImagePath("img/priya-profile.jpg");
const storyNameThumbnial = getImagePath("img/storyNameThumbnial.png");
const logo = getImagePath("img/logo.png");
const storyRegister1 = getImagePath("img/storyRegister1.png");
const storyRegister2 = getImagePath("img/storyRegister2.png");
const storyRegister3 = getImagePath("img/storyRegister3.png");
const archiveIStoryName = getImagePath("img/archiveIStoryName.png");

const triangleIcon = getImagePath('icons/triangle-icon.png');
const copyIcon = getImagePath('icons/copy-icon.svg');
const whatsappIcon = getImagePath('icons/whatsapp-icon.svg');
const emailIcon = getImagePath("icons/email-icon.svg");

const PrivilegeCreditGift = {
  id: "privilege_credits",
  name: "Gift a story",
  tmc_group: "",
  billing_enitity_type: "adhoc",
  invoice_name: "Gift a story",
  description: {
    access_desc: [
      "Can gift upto 3 privilege credits a month for free",
      "Can be used to unlock any long form story or newsletter of your choice",
    ],
  },
  is_popular: false,
  recommended_plan: false,
  discount: {},
  is_story_gift: true,
};

export class YesterdayStoryScreen extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;

    let tokenSet = token == undefined || token == "" ? false : true;

    this.state = {
      isGift: this.props.history.location.search.includes("giftCode"),
      allowGifting: false,
      pageLoader: false,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      isUserLoggedIn: token == undefined || token == "" ? false : true,
      locked_content:
        "<p><span class='firstletter'>I</span>f a bad penny were to acquire a name at this point in time, chances are it would settle for calling itself Gautam Singhania. Because Raymond, the iconic company Singhania inherited from his father, and of which he is chairman and managing director, is now perhaps in the most precarious position it has ever been in its 96-year history.</p> <p>Last year, several members of its senior management team, assiduously built over five years, quit. A replacement CEO for the lifestyle business put in his papers last month, after just seven months on the job. To think this happened at a time when the company had posted its biggest ever annual loss has given shareholders cause for concern. To add to their worries, Raymond’s valuation is down to Rs 2,600 crore, lesser than the value of 125 acres of land it holds in Mumbai’s suburbs. (A group company had sold 20 acres for Rs 700 crore in 2019.)</p> <p>Singhania, meanwhile, has been busy adding to his collection of vintage cars, a pursuit he seems</p>",
      isArchiveStory: false,
      token: token,
      storyData: this.props.postData?.data || false,
      seletedTab: 0,
      seeSecondAuthor: false,
      hideSideShareActionButton: true,
      scrollCheck: 0,
      subscriptionRefreshKey: false,
      // Set of parameters needs to show the nudge
      monthlyData: [],
      isPopupEnabled: false,
      userLoader: tokenSet ? true : false,
      plansLoader:
        this.props.postData &&
          (this.props.postData.api_code == "story_partial_read" || !tokenSet)
          ? true
          : false,
      userSubscriptionActive:
        this.props.postData && this.props.postData.api_code == "story_full_read"
          ? true
          : false,
      userSubscriptionStatus: false,
      userSubscription: false,
      teamSubscription: false,
      userSubscriptionExpired: false,
      userCredit: 0,
      creditPlan: false,
      plansData: false,
      postUnlocked:
        this.props.postData && this.props.postData.api_code == "story_full_read"
          ? true
          : false,
      redirectSlugs: {
        web: "/yesterday/" + this.props.postSlug,
      },
      showSubscribeModal: false,
      postDataSSR: this.props.postData ? true : false,
      postData: this.props.postData ? this.props.postData : "",
      country: false,
      // Unlock data
      privCredits: 0,
      planCheckoutLoaderState: false,
      copyButtonText: "Copy Link",
      openGiftModal: false,
    };
    this._nodes = new Map();
    this.displayPopup = false;
    this.percentStoryScrolled = 0;
  }

  setPlanButtonLoaderState = (state) => {
    this.setState({
      planCheckoutLoaderState: state,
    });
  };

  getPlanButtonLoaderState = () => {
    return this.state.planCheckoutLoaderState;
  };

  /************* Business Logics start ***************/

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = async () => {
    // Getting the user location 
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
      let countryInfo = null;
      if (
        cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
      ) {
        countryInfo = {
          code: "IN",
          name: "India",
        };
      } else if (cb_handle?.instance?.billingInfo) {
        countryInfo = {
          code: "US",
          name: "USA",
        };
      }

      if (countryInfo) {
        this.setState({
          locationInfo: { countryInfo: countryInfo },
        });
      }
    });
    setTimeout(() => {
      // let location = "https://themorningcontext.com/yesterday/natural-gas-prices-to-be-more-than-doubled-axis-bank-to-buy-citis-india-retail-business-and-cci-raids-tyre-manufacturers?post_id=2"
      let location = window.location.href;
      let post_id = location.indexOf("?");
      if (post_id > -1) {
        var url = new URL(location);
        var id = url.searchParams.get("post_id");
        this.setState({ seletedTab: parseInt(id) });
        this.scrollToElement(parseInt(id));
      }
    }, 2000);

    if (this.state.token || this.state.userLoader) {
      this.getUserInfo(this.state.postData)
    }
    else if (this.state.plansLoader) {
      this.getSubscriptionPlans();
    }
    else {
      this.getLocationInfo();
    }

    this.YesterdayApiData();

    if (!this.state.storyData) {
      this.getSubscriptionPlans();
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken()
    }
  };

  getDataLayerProps = () => {
    const pageProps = {
      event: "virtualpageview",
      page_location: window.location.href,
      page_title: document.title,
      article_category: "NA",
      newsletter_category: "NA",
      article_id: parseInt(this.state.storyData?.id),
      category_type: "yesterday",
    };
    return pageProps;
  };

  pushSidetrayClickEventToDataLayer = (item, index) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_sidetray_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_name: item.title?.toLowerCase(),
      article_category: item.category?.join(","),
      article_section: "yesterday",
      category_type: 'yesterday',
      index_number: index,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushShareStoryEventToDataLayer = (shareType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_share",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      share_type: shareType,
      article_name: this.state.storyData?.title,
      article_category: "NA",
      article_section: "Yesterday",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  openSocialMedia = (link) => {
    if (helpers.isWeb()) {
      window.open(link, "_blank");
    } else {
      if (link == "/investors") {
        this.props.navigation.navigate("/investors");
      } else {
        // Linking.openURL(link);
        // this.props.navigation.navigate('in-app-browser', { url: link });
        this.refs.header.openLink(link);
      }
    }
  };

  // shareOnLinkedIn = (path, index, title) => {
  //   let body = {
  //     "lifecycleState": "PUBLISHED",
  //     "specificContent": {
  //       "com.linkedin.ugc.ShareContent": {
  //         "shareCommentary": {
  //           "text": title
  //         },
  //         "shareMediaCategory": "ARTICLE",
  //         "media": [
  //           {
  //             "status": "READY",
  //             "description": {
  //               "text": title
  //             },
  //             "originalUrl": path,
  //             "title": {
  //               "text": title
  //             }
  //           }
  //         ]
  //       }
  //     },
  //     "visibility": {
  //       "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
  //     }
  //   }
  //   apiHelpers.linkedInShare(body)
  //     .then((res) => {
  //       console.log('share:', res);
  //     })
  //     .catch((error) => {
  //       console.log("Error=>> down", JSON.stringify(error));
  //     });
  // }

  /**
   * Scroll to top on click of FAB icon
   */
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // auto
    });
  };

  /**
   * Scroll to the specific element
   * @param {Number} indexOf : index number
   */
  scrollToElement = (indexOf) => {
    let offset = true
    if (!this.displayPopup && !this.state.userSubscriptionActive && this.state.teamSubscription?.plan?.tmc_group != "team_credit") {
      indexOf = 1
      offset = false
    }
    if (this.state.showSubscribeModal) {
      indexOf = 1
      offset = false
    }
    const node = this._nodes.get(indexOf);
    const position = findNodeHandle(node);
    let ext_offset = helpers.isDesktop() ? 850 : 500;
    if (!offset) {
      ext_offset = 0
    }
    window.scroll(0, position.offsetTop + ext_offset); //position.offsetWidth - 850 for desktop
  };

  /**
   * On click of link, copy to clipboard
   */
  copyToClipboard = () => {
    let path = window.location.origin + window.location.pathname;
    navigator.clipboard.writeText(path);
  };

  YesterdayApiData = () => {
    const { token } = this.state;
    let endpoint = `/yesterday/edition/single/${this.props.postSlug}`;
    let body = {
      // "limit": 1,
      sort_on: "published_date",
      sort_by: "DESC",
      // "start_date": "13-11-2021",
      // "end_date": "13-11-2021",
      // "published_date": "14-11-2021",
      published_date: moment(new Date()).format("DD-MM-YYYY"),
      // paged: 1,
    };
    apiHelpers
      .apiRequest(endpoint, body, "GET", true, token)
      .then((res) => {
        if (res.code === "story_full_read") {
          this.setState(
            {
              storyData: res.data,
              postData: res.data,
              postUnlocked: true,
              userSubscriptionActive: true,
              userSubscriptionStatus: false,
              userSubscription: false,
              pageLoader: false,
              showSubscribeModal: false
            },
            () => {
              console.log("storyData:", this.state.storyData);
              // Push initial pageview data
              const dataLayer = window.dataLayer || [];
              const pageViewData = this.getDataLayerProps();
              if (pageViewData) {
                dataLayer.push(pageViewData);
              }
            }
          );
        }
        else {
          if (token) {
            this.setState({
              postData: res.data,
              storyData: res.data,
              postUnlocked: false,
              pageLoader: false
            }, () => {
              console.log("storyData:", this.state.storyData);
              // Push initial pageview data
              const dataLayer = window.dataLayer || [];
              const pageViewData = this.getDataLayerProps();
              if (pageViewData) {
                dataLayer.push(pageViewData);
              }
            })

            /**
             * Checking if the given url is gift url
             */
            if (this.state.isGift) {
              apiHelpers.apiRequest(`/redeem-gift-link`, { giftCode: this.props.history.location.search.split('giftCode=')[1] }, 'POST', true, token).then((data) => {
                if (data && data.message === "success") {
                  //reload the page without query params
                  window.location = window.location.href.split("?")[0] + '?giftRedeemed';
                } else {
                  //show link expired error and navigate to pricing page
                  window.location.pathname = '/pricing'
                }
              })
              return
            }
          } else {
            this.setState({
              postData: res.data,
              storyData: res.data,
              postUnlocked: false,
              pageLoader: false
            }, () => {
              console.log("storyData:", this.state.storyData);
              // Push initial pageview data
              const dataLayer = window.dataLayer || [];
              const pageViewData = this.getDataLayerProps();
              if (pageViewData) {
                dataLayer.push(pageViewData);
              }
            })
            if (this.state.isGift) {
              this.props.navigateToScreen(
                `/sign-in?redirect=/yesterday/${this.state.storyData.slug}&redirect-params=giftCode=${this.props.history.location.search.split('giftCode=')[1]}`
              );
            }
          }
        }

      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    let body = {
      filters: {
        tmc_group: ["individual", "credit", "free"],
        // billing_entity_id: ["credit-plan-usd","credit-plan-inr"]
      },
      rank_sort: "ASC",
    };
    // if (profileData && profileData?.subscription?.plan) {
    //   body.filters.plan_gte = profileData.subscription.plan.id;
    // }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          let plansData =
            profileData && profileData?.team_subscription?.is_team_member
              ? []
              : res.data.plans.individual;
          let monthly_price = plansData.filter(
            (item) => item?.name == "Annual Plan"
          );
          this.setState({
            creditPlan: null,
            plansData: plansData,
            country: res.data.country,
            plansLoader: false,
            monthlyData: monthly_price.length > 0 ? monthly_price : [],
          });
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            plansLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          plansLoader: false,
        });
      });
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
    } else {
      this.props.removeUserToken();
    }
    this.setState(
      {
        token: false,
        userLoggedin: false,
      },
      () => this.getPost()
    );
  };

  getUserInfo = (postData) => {
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription", "credits", "profile"],
    };
    let is_coin_reedemed = localStorage.getItem("is_coin_redeemed");
    console.log("is_coin_redeemed:", is_coin_reedemed);
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          this.authTokenExpired();
          this.setState({
            userLoader: false,
          });
          return false;
        }
        if (res.success) {
          let privilegedCredits = res.data.credits.privileged_credits;
          let nonPrivilegedCredits = res.data.credits.non_privileged_credits;
          console.log("credits:", res.data);
          if (res.data?.free_credits_available) {
            this.setState({ allowGifting: true })
          }
          let showSubscribeModal = false;
          if (
            (!res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}") &&
            is_coin_reedemed != "1" &&
            privilegedCredits.available == 0 &&
            !this.state.postUnlocked
          ) {
            showSubscribeModal = true;
          } else {
            showSubscribeModal = false;
            localStorage.setItem("is_coin_reedemed", "0");
          }
          this.setState({
            privCredits: privilegedCredits.available,
            starterCredits: nonPrivilegedCredits,
            userSubscriptionActive:
              (res.data.subscription &&
                JSON.stringify(res.data.subscription) != "{}" &&
                res.data.subscription.status != "expired") ||
                (res.data.team_subscription &&
                  JSON.stringify(res.data.team_subscription) != "{}" &&
                  res.data.team_subscription.status != "expired" &&
                  res.data.team_subscription.is_team_member)
                ? true
                : false,
            userSubscriptionStatus:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription.status,
            userSubscription:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription,
            showSubscribeModal: showSubscribeModal,
            teamSubscription:
              !res.data.team_subscription ||
                JSON.stringify(res.data.team_subscription) == "{}"
                ? false
                : res.data.team_subscription,
            userCredit:
              postData?.access_type == "privileged"
                ? privilegedCredits.available
                : privilegedCredits.available + nonPrivilegedCredits.available,
            userLoader: false,
            freeCreditsAvailable: res.data.free_credits_available,
            userSubscriptionExpired:
              (res.data.team_subscription &&
                !this.isObjectEmpty(res.data.team_subscription)) ||
                (res.data.subscription &&
                  !this.isObjectEmpty(res.data.subscription)) ||
                (res.data.expired_subscription &&
                  !this.isObjectEmpty(res.data.team_subscription))
                ? true
                : false,
            userProfile: res.data
          });
          this.getSubscriptionPlans(res.data);
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            userLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          userLoader: false,
        });
      });
  };

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  /**
   * Navigate to the Yesterday Landing page
   */
  navigateScreen = (action) => {
    action === "button"
      ? this.props.navigateToScreen(`/yesterday`, {
        fromModule: "story-screen",
      })
      : this.props.navigateToScreen(`/yesterday`);
  };

  applyRef = (ref) => {
    if (helpers.isWeb()) {
      this.scrollRef = window.addEventListener("scroll", this.getScrollOffset);
    }
  };

  sharePost = (slug) => {
    let { isUserLoggedIn, isArchiveStory, storyData, seletedTab } = this.state;
    if (helpers.isWeb()) {
      let path = window.location.origin + window.location.pathname;
      // let path = "https://themorningcontext.com" + window.location.pathname;

      if (slug == "fb") {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${ReactHtmlParser(
            storyData.title
          )}`,
          "_blank"
        );
      } else if (slug == "twitter") {
        window.open(
          `https://twitter.com/intent/tweet?text=${ReactHtmlParser(
            storyData.title
          )}&url=${path}`,

          "_blank"
        );
      } else if (slug == "linkedin") {
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${ReactHtmlParser(
            storyData.title
          )}`,
          "_blank"
        );
      } else {
        navigator
          .share({
            title: storyData.title,
            text: storyData.title,
            url: path,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    } else {
      Share.share({
        message: `${helpers.siteURL}/yesterday/${slug}`,
      });
    }
  };

  getScrollOffset = (event) => {
    let hideSideShareActionButton;
    let isPopupEnabled
    console.log("hideSideShareActionButton");
    if (window.scrollY <= 500) {
      hideSideShareActionButton = true;
      isPopupEnabled = false;
    } else {
      hideSideShareActionButton = false;
      isPopupEnabled = true;
    }
    this.setState({
      hideSideShareActionButton: hideSideShareActionButton,
      isPopupEnabled: isPopupEnabled
    });
  };

  /***************** Business logic End & View Render start **************/

  renderPageHeader = () => {
    return <></>;
  };

  pushUnlockStoryEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    const { storyData } = this.state;
    dataLayer.push({
      event: "unlock_with_credit",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_name: storyData?.title?.toLowerCase(),
      article_category: "NA",
      credit_type: "privilege",
      credit_used: storyData?.credits_to_unlock,
      credit_balance: this.state.privCredits,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  unlockPost = () => {
    try {
      Mixpanel.track("click_unlock_now_btn");
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    if (this.state.privCredits > 0) {
      this.pushUnlockStoryEventToDataLayer()
    }
    const { token } = this.state;
    if (token) {
      this.setState({
        showErrorModal: false,
        postUnlockError: false,
        unlockLoader: true,
      });
      const { storyData, postData } = this.state;
      let body = {
        story: {
          slug: this.props.postSlug,
          id: storyData.id,
        },
      };

      let endpoint = `/unlock-story`;
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.code == "unlock_success") {
            localStorage.setItem("is_coin_reedemed", "1");
            this.YesterdayApiData();

            try {
              let userStarterCredits =
                this.state.userCredit - this.state.privCredits;
              let userPrivilegeCredits = this.state.privCredits;
              if (postData?.access_type == "privileged") {
                userPrivilegeCredits -= postData?.credits_to_unlock;
              } else {
                userStarterCredits -= postData?.credits_to_unlock;
              }
              console.log("Story Unlocked");
              window.webengage.track("Story Unlocked", {
                "Starter credits pending": userStarterCredits,
                "Privilege credits pending": userPrivilegeCredits,
                "Slug of story": this.props.postSlug,
              });
              console.log({
                "Starter credits pending": userStarterCredits,
                "Privilege credits pending": userPrivilegeCredits,
                "Slug of story": this.props.postSlug,
              });
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", res.message);
            this.setState({
              // postUnlockError: true,
              unlockLoader: false,
              // insufficientCredits: true,
              // mhariharan: Disabling the code for insufficient code popup 
              //showInsufficientCreditsPopup: true,
            });
          }
        })
        .catch((error) => {
          console.log("Error=>>", error);
          this.setState({
            // postUnlockError: true,
            unlockLoader: false,
          });
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          "/sign-in?redirect=" + this.state.redirectSlugs.web
        );
      } else {
        this.props.navigation.navigate(
          "/sign-in",
          this.state.redirectSlugs.native
        );
      }
    }
  };

  onCopyButtonClick = async () => {
    if (this.state.copyButtonText === "Copied") {
      return;
    }
    let { token } = this.state;
    let endpoint = `/generate-gift-link`;
    let data = {};
    data.story = {
      id: this.state.storyData.id,
      slug: this.state.storyData.slug
    }

    if (typeof ClipboardItem && navigator.clipboard.write) {

      apiHelpers.apiRequest(endpoint, data, "POST", true, token)
        .then(res => {
          const textContent = `${this.state.userProfile.name || this.state.userProfile.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`;

          const textBlob = new Blob([textContent], { type: "text/plain" });
          const textItem = new ClipboardItem({ "text/plain": textBlob });

          // Write the ClipboardItem to the clipboard
          navigator.clipboard.write([textItem]);

          this.setState({ copyButtonText: "Copied" });
          setTimeout(() => {
            this.setState({ copyButtonText: "Copy Link" });
          }, 1000);
        })
        .catch(error => {
          console.error("Error fetching API data:", error);
        });
    }
    else {
      // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
      //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
      //   Good news is that other than Safari, Firefox does not care about
      //   Clipboard API being used async in a Promise.
      apiHelpers.apiRequest(endpoint, data, "POST", true, token)
        .then(res => `${this.state.userProfile.name || this.state.userProfile.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`)
        .then((text) => {
          navigator.clipboard.writeText(text)
          this.setState({ copyButtonText: "Copied" })
          setTimeout(() => {
            this.setState({ copyButtonText: "Copy Link" })
          }, 1000)
        })
    }
  }
  handleWhatsappShare = () => {
    console.log("Clicked on gifting via Whatsapp option")
    let { token } = this.state;
    let endpoint = `/generate-gift-link`;
    let data = {};
    data.story = {
      id: this.state.storyData.id,
      slug: this.state.storyData.slug
    }
    apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
      if (res.success) {
        const expression = /(iPhone|iPod|iPad)/i;

        if (expression.test(navigator.platform)) {
          // console.log('Apple device detected!');
          window.location.href = encodeURI(`https://wa.me/?text=${this.state.userProfile.name || this.state.userProfile.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`)
        } else {
          // console.log('Other device detected!');
          window.open(encodeURI(`https://wa.me/?text=${this.state.userProfile.name || this.state.userProfile.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`))
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  handleEmailShare = () => {
    console.log("Clicked on gifting via Email option")
    let { token } = this.state;
    let endpoint = `/generate-gift-link`;
    let data = {};
    data.story = {
      id: this.state.storyData.id,
      slug: this.state.storyData.slug
    }
    apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
      console.log(res)
      if (res.success) {
        window.location.href = `mailto:?subject=Gift from ${this.state.userProfile.name || this.state.userProfile.email}&body=${this.state.userProfile.name || this.state.userProfile.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.
            ${document.location.href + '?giftCode=' + res.data.gift_code}`
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  handleGiftButtonClickMob = () => {
    if (this.state.openGiftModal) {
      this.setState({ openGiftModal: false })
    } else {
      this.setState({ openGiftModal: true })
    }
  }

  showPageLoader = () => {
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          Flex.justfiyCenter,
          Flex.alignCenter,
          {
            position: webView ? "fixed" : "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "#ffffff",
          },
        ]}
      >
        <ActivityIndicator size={25} color={"#000000"} />
      </View>
    );
  };

  renderReadMainStoryBanner = (darkMode) => {
    return (
      <View style={{ backgroundColor: "#ffffff", marginTop: 30 }}>
        <View
          style={[
            helpers.isDesktop() ? Flex.row : Flex.column,
            Flex.alignStart,
            Flex.justfiyCenter,
            Flex.FlexWrap,
            {
              maxWidth: helpers.isDesktop() ? "90%" : "100%",
              width: "100%",
              margin: "auto",
              marginTop: 0,
              marginBottom: 0,
            },
          ]}
        >
          <View
            style={[
              helpers.isDesktop() ? Flex.row : Flex.flexColumnReverse,
              Flex.alignStart,
              Flex.justfiyCenter,
              {
                maxWidth: helpers.isDesktop() ? "100%" : "100%",
                width: "100%",
                margin: "auto",
                padding: 20,
                paddingBottom: 0,
              },
            ]}
          >
            <View style={{ width: helpers.isDesktop() ? "70%" : "100%" }}>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 42 : 24,
                    lineHeight: helpers.isDesktop() ? 50.4 : 31,
                    fontFamily: FontFamily.abrilFatface,
                    color: "#051462",
                    paddingBottom: 15,
                    textAlign: "left",
                  },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                Read main story of that day
              </Text>
            </View>

            <View
              style={[
                Flex.row,
                helpers.isDesktop() ? Flex.justifyEnd : Flex.justifyStart,
                { width: "30%" },
              ]}
            >
              <View
                style={{
                  width: helpers.isDesktop() ? 167 : 100,
                  marginBottom: 10,
                }}
              >
                <CustomImage
                  source={logo}
                  require={true}
                  style={[
                    helpers.isWeb()
                      ? {
                        width: helpers.isDesktop() ? 116 : 96,
                        height: "auto",
                      }
                      : { width: helpers.isDesktop() ? 116 : 96, height: 30 },
                  ]}
                  altText={"The Morning Context"}
                />
              </View>
            </View>
          </View>

          <View
            style={[
              helpers.isDesktop() ? Flex.row : Flex.column,
              Flex.alignStart,
              {
                maxWidth: helpers.isDesktop() ? "90%" : "100%",
                width: helpers.isDesktop() ? "90%" : "100%",
                margin: "auto",
                marginTop: helpers.isDesktop() ? 30 : 10,
                marginBottom: 0,
              },
            ]}
          >
            <View
              style={{
                padding: 20,
                width: helpers.isDesktop() ? "90%" : "100%",
                paddingTop: 0,
              }}
            >
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 19 : 16,
                    lineHeight: helpers.isDesktop() ? 22.8 : 19.5,
                    fontFamily: FontFamily.medium,
                    color: "#F7A34B",
                    textTransform: "uppercase",
                  },
                  { paddingBottom: 3, textAlign: "left" },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                internet
              </Text>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 30 : 22,
                    lineHeight: helpers.isDesktop() ? 45 : 32.56,
                    fontFamily: FontFamily.medium,
                    color: "#000000",
                  },
                  { paddingBottom: 3, textAlign: "left" },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                How India is handling human to animal transmission of COVID-19
                (or not)
              </Text>
              <ImageBackground
                style={[
                  {
                    width: "100%",
                    height: helpers.isDesktop() ? 168 : 114,
                    margin: "auto",
                    background: "#ccc",
                  },
                ]}
                source={archiveIStoryName}
                resizeMode="cover"
              ></ImageBackground>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 30 : 27,
                    lineHeight: helpers.isDesktop() ? 45 : 35,
                    fontFamily: FontFamily.medium,
                    color: "#000000",
                  },
                  { paddingBottom: 3, marginTop: 10, textAlign: "left" },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              ></Text>
              <HTML
                html={this.state.locked_content}
                tagsStyles={postExcerptTags}
                classesStyles={postExcerptClasses}
                onLinkPress={(event, href, data) => this.props.openLink(href)}
              />
            </View>
          </View>
          <View
            style={[
              Flex.row,
              Flex.alignStart,
              Flex.justfiyCenter,
              { width: "100%" },
            ]}
          >
            <View
              style={[
                { padding: 20, width: helpers.isDesktop() ? 200 : "80%" },
              ]}
            >
              <ButtonGradient
                title={"LEARN MORE"}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradient,
                    { width: "100%", height: helpers.isDesktop() ? 60 : 40 },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    { fontSize: 16, fontWeight: "400" },
                  ],
                }}
                onClick={() => null}
                darkMode={darkMode}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };
  renderReadStoryFreeBanner = (darkMode) => {
    return (
      <View
        style={
          helpers.isWeb()
            ? {
              position: "absolute",
              width: "100%",
              height: "20%",
              bottom: 0,
              left: 0,
              right: 0,
            }
            : {
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 20,
              right: 0,
            }
        }
      >
        <View
          style={{
            shadowColor: "#ffffff",
            shadowOffset: { width: 0, height: -22 },
            shadowOpacity: 1,
            shadowRadius: 200,
            elevation: 5,
            position: "absolute",
            width: "100%",
            height: helpers.isDesktop() ? 280 : 300,
            bottom: 0,
            left: 0,
            textAlign: "center",
            backgroundColor: "#ffffff",
            padding: 20,
            paddingBottom: 50,
            paddingLeft: 20,
            paddingTop: 40,
            paddingBottom: 20,
          }}
        >
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 42 : 24,
                lineHeight: helpers.isDesktop() ? 50 : 32,
                paddingBottom: helpers.isDesktop() ? 16 : 16,
                fontFamily: FontFamily.abrilFatface,
                color: "#051462",
              },
              { textAlign: "center" },
              darkMode && { color: "rgba(255, 255, 255, 0.89)" },
            ]}
          >
            Read the edition for free
          </Text>

          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 24 : 16,
                lineHeight: helpers.isDesktop() ? 36 : 23.68,
                paddingBottom: helpers.isDesktop() ? 20 : 20,
                fontFamily: FontFamily.regular,
                color: "#051462",
              },
              { textAlign: "center" },
              darkMode && { color: "rgba(255, 255, 255, 0.89)" },
            ]}
          >
            Sign up for Yesterday and let it help you catch up with the news. We
            land in your inbox every weekday at 8 am IST.
          </Text>
          <View style={[Flex.row, Flex.alignStart, Flex.justfiyCenter]}>
            <View
              style={{ padding: 20, width: helpers.isDesktop() ? 200 : "100%" }}
            >
              <ButtonGradient
                title={"Sign up"}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradient,
                    { width: "100%", height: helpers.isDesktop() ? 60 : 40 },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    { fontSize: 16, fontWeight: "400" },
                  ],
                }}
                onClick={() => {
                  const dataLayer = window.dataLayer || [];
                  dataLayer.push({
                    event: "signup_initiate",
                    custom_user_id: "NA",
                    user_status: "guest",
                    CTA_text: "sign up",
                    header_title: "read this edition for free",
                    CTA_position: "bottom",
                    article_name: this.state.storyData?.title?.toLowerCase(),
                    signup_method: "NA",
                  });
                  this.props.navigateToScreen(
                    `/sign-up?redirect=${this.props.history?.location?.pathname}`
                  );
                }}
                darkMode={darkMode}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };

  goToLogin = () => {
    this.props.navigateToScreen(
      "/sign-in?redirect=/yesterday/" +
      this.props.postSlug
    );
  };

  goToSignUp = (email) => {
    this.props.navigateToScreen(
      "/sign-in?redirect=/yesterday/" +
      this.props.postSlug +
      "&providerHint=tmc-passwordless" +
      (email ? "&emailHint=" + email : "")
    );
  };

  closeModal = () => {
    document.body.style.overflow = "visible";
    this.setState({ isPopupEnabled: false });
  };

  pushStoryNudgeInteractionToDataLayer = (data, cta, plan_name, plan_price) => {
    const dataLayer = window.dataLayer || [];
    const authorArray = data?.author?.map((item) => {
      return item.name.toLowerCase();
    });
    dataLayer.push({
      event: "nudge_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      plan_name: plan_name.toLowerCase(),
      plan_price: plan_price,
      article_name: data?.story?.title?.toLowerCase(),
      article_category: this.state.categorySlug?.toLowerCase(),
      article_section: "NA",
      author_name: authorArray?.join(", "),
      newsletter_category: data?.newsletter?.name?.toLowerCase() || "NA",
      newsletter_section: "NA",
      newsletter_name: data?.story?.title?.toLowerCase(),
      CTA_text: cta.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  }


  renderPageContent = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const boxShadowStyle = { shadowColor: '#171717', shadowOffset: { width: -2, height: 4 }, shadowOpacity: 0.2, shadowRadius: 3, }
    let { isUserLoggedIn, isArchiveStory, storyData, seletedTab, postData, isPopupEnabled, teamSubscription, showSubscribeModal, country, privCredits, postDataSSR, userLoader, plansLoader, userSubscriptionActive, userSubscriptionStatus, plansData, userSubscription, creditPlan } = this.state;
    let path = window.location.origin + window.location.pathname;
    let btnClickBookmark, btnClickShare, btnClickFB, btnClickTwitter, btnClickLinkdin, btnClickLike, allowShare, btnClickGift, btnClickGiftSub;
    btnClickShare = {
      onPress: () => sharePost('open')
    }

    const openGiftCardModal = () => {
      this.refs.header.openGiftCardModal(PrivilegeCreditGift.id, PrivilegeCreditGift, null, null, postData, postData.story?.credits?.unlock)
    }

    btnClickGift = {
      onPress: () => {
        openGiftCardModal()
        this.setState({ openGiftModal: false })
      }
    }

    btnClickGiftSub = {
      onPress: () => {
        this.props.openGiftSubModal()
        this.setState({ showGiftSubModal: false })
        this.setState({ openGiftModal: false })
      }
    }
    if (process.browser) {
      if (navigator.share) {
        allowShare = true;
      }
      let path = window.location.origin + window.location.pathname;
      btnClickFB = {
        href: `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${this.props.shareTitle}`
      }
      btnClickTwitter = {
        href: `https://twitter.com/intent/tweet?text=${this.props.shareTitle}&url=${path}`
      }
      btnClickLinkdin = {
        href: `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${this.props.shareTitle}`
      }
    } else {
      allowShare = true;
    }

    let showFullContent = false

    if (isUserLoggedIn && (!showSubscribeModal && (userSubscriptionActive || this.state.postUnlocked))) {
      showFullContent = true
    }

    const authorArray = storyData?.authors?.map((item) => {
      return item.name.toLowerCase();
    });

    let postSummary;
    if (storyData) {
      postSummary = {
        featured_image: storyData.featured_image,
        category: storyData.category,
        newsletter: storyData.newsletter,
        postTitle: storyData.title,
        author: authorArray.join(","),
        story: { credits: { unlock: storyData.credits_to_unlock } },
        lazyLoad: !postDataSSR,
        freeRead: storyData?.unlock_data?.type == "free" ? true : false,
        is_free: storyData.is_free,
      };
    }



    if (
      !isUserLoggedIn ||
      (isUserLoggedIn &&
        teamSubscription?.plan?.tmc_group != "team_credit" &&
        teamSubscription?.plan?.tmc_group != "team" &&
        postData?.access_type == "privileged" &&
        showSubscribeModal)
    )
      this.displayPopup = true;
    else this.displayPopup = false;


    // let path = "https://themorningcontext.com" + window.location.pathname;
    return (
      <>
        {storyData ? (
          <View>
            {/* </View><View style={[{ backgroundColor: "#F3F5FF", position: "relative", height: helpers.isDesktop() ? 1150 : 780, overflow: "hidden" }, { padding: helpers.isDesktop() ? 40 : 20, paddingTop: helpers.isDesktop() ? 0 : 20, paddingBottom: helpers.isDesktop() ? 0 : 20 }, darkMode && { backgroundColor: Colors.darkPurple }]}> */}
            {/* isUserLoggedIn checks if the user is logged in and isPopupEnabled checks if we should show a nudge */}
            <View
              style={[
                {
                  backgroundColor: "#F3F5FF",
                  position: "relative",
                  height: helpers.isDesktop()
                    ? showFullContent
                      ? "auto"
                      : 1480
                    : showFullContent
                      ? "auto"
                      : 1050,
                  overflow:
                    showFullContent
                      ? "visible"
                      : "hidden",
                },
                {
                  paddingLeft: helpers.isDesktop() ? 40 : 20,
                  paddingRight: helpers.isDesktop() ? 40 : 20,
                  paddingTop: helpers.isDesktop() ? 0 : 20,
                  paddingBottom: 20,
                  marginBottom: helpers.isDesktop() ? "auto" : -23,
                },
                darkMode && { backgroundColor: Colors.darkPurple },
              ]}
            >
              {(!isUserLoggedIn ||
                (isUserLoggedIn &&
                  teamSubscription?.plan?.tmc_group != "team_credit" &&
                  teamSubscription?.plan?.tmc_group != "team" &&
                  postData?.access_type == "privileged" &&
                  showSubscribeModal)) &&
                isPopupEnabled ? (
                <View
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    flex: 1,
                    justifyContent: "flex-end",
                    width: "100%",
                    height: "100%",
                    top: 48,
                    left: 0,
                    zIndex: 9,
                  }}
                ></View>
              ) : null}
              <View
                style={[
                  helpers.isDesktop() ? Flex.row : Flex.column,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  {
                    maxWidth: helpers.isDesktop() ? "90%" : "100%",
                    width: "100%",
                    margin: "auto",
                    marginTop: 0,
                    marginBottom: 0,
                  },
                ]}
              >
                {/* <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "20%" : "100%",
                      padding: 10,
                    },
                  ]}
                ></View> */}
                <View style={[{ width: helpers.isDesktop() ? "70%" : "100%" }]}>
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onPress={() => this.navigateScreen("breadcrumb")}
                    >
                      <Text
                        style={[
                          {
                            fontSize: helpers.isDesktop() ? 18 : 16,
                            lineHeight: helpers.isDesktop() ? 21 : 23.5,
                            fontFamily: FontFamily.regular,
                            color: "#051462",
                          },
                          {
                            textAlign: "left",
                            marginTop: helpers.isDesktop() ? 34 : 10,
                            marginBottom: helpers.isDesktop() ? 15 : 10,
                          },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        News Explainer{" "}
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={[
                        {
                          fontSize: helpers.isDesktop() ? 18 : 16,
                          lineHeight: helpers.isDesktop() ? 21 : 23.5,
                          fontFamily: FontFamily.regular,
                          color: "#051462",
                        },
                        {
                          textAlign: "left",
                          marginTop: helpers.isDesktop() ? 34 : 10,
                          marginBottom: helpers.isDesktop() ? 15 : 10,
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      &gt;{" "}
                      {moment(storyData?.published_date).add(5, 'hours').add(30, 'minutes').format(
                        "DD MMMM, YYYY"
                      )}
                    </Text>
                  </View>

                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 48 : 24,
                        lineHeight: helpers.isDesktop() ? 64.75 : 32,
                        fontFamily: FontFamily.abrilFatface,
                        color: "#051462",
                      },
                      { paddingBottom: 3, textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    {ReactHtmlParser(storyData?.title)}
                  </Text>
                  <View
                    style={[
                      Flex.alignStar,
                      {
                        marginBottom: 20,
                        flexDirection: helpers.isDesktop() ? "row" : "column",
                      },
                    ]}
                  >
                    <View
                      style={[
                        Flex.alignStart,

                        Flex.row,
                        {
                          // flexDirection: helpers.isDesktop() ? "row" : "column",
                          padding: 10,
                          paddingLeft: 0,
                          paddingRight: 20,
                        },
                      ]}
                    >
                      {storyData && storyData.authors && (
                        <>
                          <View
                            style={[
                              Flex.alignCenter,
                              Flex.justfiyCenter,
                              Flex.row,
                              {
                                width: 40,
                                height: 40,
                                borderWidth: 1,
                                borderColor: "rgb(252, 135, 109)",
                                borderRadius: 40,
                                marginRight: 10,
                                borderStyle: "solid",
                                marginTop: "-10px",
                              },
                            ]}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                window.open(
                                  `/writers/${storyData.authors[0].slug}`,
                                  "_self"
                                );
                              }}
                            >
                              <View
                                style={{
                                  width: 34,
                                  height: 34,
                                  overflow: "hidden",
                                  borderRadius: 36,
                                }}
                              >
                                <CustomImage
                                  resizeMode={"contain"}
                                  source={storyData.authors[0].image}
                                  require={true}
                                  style={[
                                    {
                                      height: 34,
                                      width: 34,
                                      borderRadius: 20,
                                    },
                                  ]}
                                  altText={storyData.authors[0].name}
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              window.open(
                                `/writers/${storyData.authors[0].slug}`,
                                "_self"
                              );
                            }}
                          >
                            <View style={{ marginRight: 10 }}>
                              <Text
                                style={[
                                  {
                                    fontSize: helpers.isDesktop() ? 16 : 15,
                                    lineHeight: helpers.isDesktop() ? 19.2 : 18,
                                    fontFamily: FontFamily.regular,
                                    color: "#000000",
                                  },
                                  { textAlign: "left", marginBottom: 0 },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {storyData.authors[0].name}{" "}
                                {/* {helpers.isDesktop() ? null : "\n"} */}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </>
                      )}
                      {storyData &&
                        storyData.authors &&
                        storyData.authors.length > 1 &&
                        !this.state.seeSecondAuthor && (
                          <TouchableOpacity
                            onPress={() =>
                              this.setState({ seeSecondAuthor: true })
                            }
                          >
                            {helpers.isWeb() ? (
                              <View
                                style={[
                                  {
                                    borderStyle: "solid",
                                    fontFamily: FontFamily.regular,
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop() ? 19 : 22,
                                    borderWidth: 1,
                                    backgroundColor: "#F3F5FF",
                                    borderColor: "#907CFF",
                                    width: 28,
                                    height: 28,
                                    borderRadius: 28,
                                    marginRight: 5,
                                    marginTop: "-4px",
                                  },
                                  Flex.alignCenter,
                                  Flex.row,
                                  Flex.justfiyCenter,
                                ]}
                              >
                                {`+${storyData.authors.length - 1}`}
                              </View>
                            ) : (
                              <Text
                                style={[
                                  {
                                    borderStyle: "solid",
                                    fontFamily: FontFamily.regular,
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop() ? 19 : 22,
                                    borderWidth: 1,
                                    backgroundColor: "#F3F5FF",
                                    borderColor: "#907CFF",
                                    width: 28,
                                    height: 28,
                                    borderRadius: 28,
                                    marginRight: 5,
                                    marginTop: "-4px",
                                  },
                                  Flex.alignCenter,
                                  Flex.row,
                                  Flex.justfiyCenter,
                                ]}
                              >
                                {`+${storyData.authors.length - 1}`}
                              </Text>
                            )}
                          </TouchableOpacity>
                        )}
                    </View>

                    <View
                      style={[
                        Flex.alignStart,
                        Flex.row,
                        {
                          paddingTop: 10,
                          paddingLeft: 0,
                          paddingRight: 20,
                          paddingBottom: 10,
                          width: helpers.isDesktop() ? "46%" : "100%",
                        },
                      ]}
                    >
                      {storyData &&
                        storyData.authors &&
                        this.state.seeSecondAuthor &&
                        storyData?.authors.map((item, idx) => {
                          if (idx >= 1) {
                            return (
                              <View
                                style={{
                                  flexDirection: "row",
                                  flexBasis: "50%",
                                  flex: 1,
                                  flexWrap: "wrap",
                                }}
                              >
                                <View
                                  style={[
                                    Flex.alignCenter,
                                    Flex.justfiyCenter,
                                    // Flex.row,
                                    {
                                      width: 40,
                                      height: 40,
                                      borderWidth: 1,
                                      borderColor: "rgb(252, 135, 109)",
                                      borderRadius: 40,
                                      marginRight: 10,
                                      borderStyle: "solid",
                                      marginTop: "-10px",
                                    },
                                  ]}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      window.open(
                                        `/writers/${item.slug}`,
                                        "_self"
                                      );
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: 34,
                                        height: 34,
                                        overflow: "hidden",
                                        borderRadius: 36,
                                      }}
                                    >
                                      <CustomImage
                                        resizeMode={"contain"}
                                        source={item.image}
                                        require={true}
                                        style={[
                                          {
                                            height: 34,
                                            width: 34,
                                            borderRadius: 20,
                                          },
                                        ]}
                                        altText={item.name}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                </View>

                                <TouchableOpacity
                                  onPress={() => {
                                    window.open(
                                      `/writers/${item.slug}`,
                                      "_self"
                                    );
                                  }}
                                >
                                  <View style={{ marginRight: 10 }}>
                                    <Text
                                      style={[
                                        {
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 15,
                                          lineHeight: helpers.isDesktop()
                                            ? 19.2
                                            : 18,
                                          fontFamily: FontFamily.regular,
                                          color: "#000000",
                                        },
                                        {
                                          textAlign: "left",
                                          marginBottom: 0,
                                        },
                                        darkMode && {
                                          color: "rgba(255, 255, 255, 0.89)",
                                        },
                                      ]}
                                    >
                                      {item.name}{" "}
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            );
                          }
                        })}
                    </View>
                    {/* </View> */}
                    {/* <View style={[Flex.row, Flex.alignStart, { padding: 10, paddingLeft: 0, paddingRight: 10 }]}>
                                    <View style={[Flex.alignCenter, Flex.justfiyCenter, Flex.row, { width: 24, height: 24, borderWidth: 1, borderColor: "#EF3C3B", borderRadius: 24, marginRight: helpers.isDesktop() ? 15 : 10, }]}>
                                        <View style={{ width: 22.4, height: 22.4, borderWidth: 2, borderColor: "#ffffff", borderRadius: 24, overflow: "hidden" }}>
                                            <CustomImage
                                                resizeMode={'contain'}
                                                source={profile}
                                                require={true}
                                                style={[{ width: "100%", borderRadius: 24, height: "100%" }]}
                                                altText={'Have Only 5 Minutes'}
                                            />
                                        </View>

                                    </View>
                                    <View>
                                        <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 15, lineHeight: helpers.isDesktop() ? 19.2 : 18, fontFamily: FontFamily.regular, color: '#000000' }, { textAlign: "left", marginBottom: 0 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                            Gaurangi Agarwal
                                        </Text>
                                        <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 15, lineHeight: helpers.isDesktop() ? 19.2 : 18, fontFamily: FontFamily.light, color: '#000000' }, { textAlign: "left", marginBottom: helpers.isDesktop() ? 15 : 10 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                            Mumbai
                                        </Text>
                                    </View>
                                </View> */}
                  </View>
                </View>
              </View>
              <View
                style={[
                  helpers.isDesktop() ? Flex.row : Flex.column,
                  Flex.alignStart,
                  Flex.justfiyCenter,
                  {
                    maxWidth: helpers.isDesktop() ? "90%" : "100%",
                    width: "100%",
                    marginLeft: helpers.isDesktop() ? "-48px" : 0,
                    // margin: "auto",
                    // marginTop: 0,
                    // marginBottom: helpers.isDesktop() ? 60 : 20,
                  },
                ]}
              >
                <View
                  style={[
                    !helpers.isDesktop() && {
                      height: "auto",
                    },
                    {
                      width: helpers.isDesktop() ? "70%" : "100%",
                      marginLeft: helpers.isDesktop() ? "20%" : 0,
                    },
                  ]}
                >
                  {storyData && storyData?.featured_image && (
                    <CustomImage
                      source={storyData.featured_image}
                      require={true}
                      resizeMode={"cover"}
                      style={{
                        width: "100%",
                        height: "auto"
                      }}
                      webStyle={{
                        width: "100%",
                        height: "auto"
                      }}
                      altText={"The Morning Context"}
                    />
                  )}
                </View>
              </View>

              <View
                style={[
                  helpers.isDesktop() ? Flex.row : Flex.column,
                  Flex.alignStart,
                  Flex.justfiyCenter,
                  {
                    maxWidth: helpers.isDesktop() ? "90%" : "100%",
                    width: "100%",
                    marginLeft: helpers.isDesktop() ? "-48px" : 0,
                    // margin: "auto",
                    // marginTop: 0,
                    // marginBottom: helpers.isDesktop() ? 60 : 20,
                  },
                ]}
              >
                {helpers.isDesktop() && (
                  <View
                    style={[
                      {
                        width: helpers.isDesktop() ? "20%" : "100%",
                        paddingTop: 10,
                        position: "sticky",
                        top: 50,
                      },
                    ]}
                  >
                    {storyData &&
                      storyData.posts &&
                      storyData.posts.map((item, index) => {
                        return (
                          <>
                            {/* 6C7AF6 */}
                            <TouchableOpacity
                              onPress={() => {
                                this.pushSidetrayClickEventToDataLayer(
                                  item,
                                  index
                                );
                                this.setState({ seletedTab: index });
                                //   window.scroll(0, index * 943);
                                this.scrollToElement(index);
                              }}
                              style={[
                                {
                                  marginBottom: 15,
                                  color: "#051462",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                },
                                {
                                  fontSize: helpers.isDesktop() ? 14 : 16,
                                  lineHeight: helpers.isDesktop() ? 20 : 23,
                                  fontFamily: FontFamily.regular,
                                },
                                { textAlign: "left", marginBottom: 15 },
                                darkMode && {
                                  color: "rgba(255, 255, 255, 0.89)",
                                },
                                seletedTab == index && {
                                  borderLeftWidth: 2,
                                  fontFamily: FontFamily.bold,
                                  borderColor: "#6C7AF6",
                                  color: "#907CFF",
                                },
                              ]}
                            >
                              {item.title}
                            </TouchableOpacity>
                            {/* <TouchableOpacity style={[{ marginBottom: 15, borderLeftWidth: 2, borderColor: "#f3f5ff", paddingLeft: 10, paddingRight: 10 }, { fontSize: helpers.isDesktop() ? 14 : 16, lineHeight: helpers.isDesktop() ? 20 : 23, fontFamily: FontFamily.regular, color: '#051462' }, { textAlign: "left", marginBottom: 15 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                Government curbs export of COVID-19 Rapid Antigen Testing kits
                            </TouchableOpacity>
                            <TouchableOpacity style={[{ marginBottom: 15, borderLeftWidth: 2, borderColor: "#f3f5ff", paddingLeft: 10, paddingRight: 10 }, { fontSize: helpers.isDesktop() ? 14 : 16, lineHeight: helpers.isDesktop() ? 20 : 23, fontFamily: FontFamily.regular, color: '#051462' }, { textAlign: "left", marginBottom: 15 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                Quick updates on what unfolded in Afghanistan
                            </TouchableOpacity>
                            <TouchableOpacity style={[{ marginBottom: 15, borderLeftWidth: 2, borderColor: "#f3f5ff", paddingLeft: 10, paddingRight: 10 }, { fontSize: helpers.isDesktop() ? 14 : 16, lineHeight: helpers.isDesktop() ? 20 : 23, fontFamily: FontFamily.regular, color: '#051462' }, { textAlign: "left", marginBottom: 15 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                Live-in relationship between man and married woman illegal: Rajasthan HC
                            </TouchableOpacity>
                            <TouchableOpacity style={[{ marginBottom: 15, borderLeftWidth: 2, borderColor: "#f3f5ff", paddingLeft: 10, paddingRight: 10 }, { fontSize: helpers.isDesktop() ? 14 : 16, lineHeight: helpers.isDesktop() ? 20 : 23, fontFamily: FontFamily.regular, color: '#051462' }, { textAlign: "left", marginBottom: 15 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                                TCS became the second Indian firm to cross ₹13 lakh crore in market value
                            </TouchableOpacity> */}
                          </>
                        );
                      })}
                    <View
                      style={{
                        width: "100%",
                        height: 2,
                        backgroundColor: "#DCD5FF",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    ></View>
                    <Text
                      style={[
                        {
                          fontSize: helpers.isDesktop() ? 16 : 16,
                          lineHeight: helpers.isDesktop() ? 19.2 : 23.5,
                          fontFamily: FontFamily.regular,
                          color: "#000000",
                        },
                        {
                          textAlign: helpers.isDesktop() ? "left" : "center",
                          marginBottom: helpers.isDesktop() ? 10 : 10,
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      Share
                    </Text>
                    <View style={[Flex.row]}>
                      <TouchableOpacity
                        onPress={() => {
                          this.pushShareStoryEventToDataLayer("facebook");
                          window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${ReactHtmlParser(
                              storyData.title
                            )}`,
                            "_blank"
                          );
                        }}
                        style={[
                          {
                            marginBottom: 15,
                            paddingLeft: 0,
                            paddingRight: 10,
                          },
                          {
                            fontSize: helpers.isDesktop() ? 14 : 16,
                            lineHeight: helpers.isDesktop() ? 20 : 23,
                            fontFamily: FontFamily.bold,
                            color: "#907CFF",
                          },
                          { textAlign: "left" },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        <CustomImage
                          source={facebook16}
                          require={true}
                          style={{ width: 16, height: 16 }}
                          webStyle={{ width: 16, height: 16 }}
                          altText={"The Morning Context"}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          this.pushShareStoryEventToDataLayer("twitter");
                          window.open(
                            // `https://twitter.com/intent/tweet?text=${storyData.title}&url=${path}`,
                            `https://twitter.com/intent/tweet?text=${ReactHtmlParser(
                              storyData.title
                            )}&url=${path}`,

                            "_blank"
                          );
                        }}
                        style={[
                          {
                            marginBottom: 15,
                            paddingLeft: 0,
                            paddingRight: 10,
                          },
                          {
                            fontSize: helpers.isDesktop() ? 14 : 16,
                            lineHeight: helpers.isDesktop() ? 20 : 23,
                            fontFamily: FontFamily.bold,
                            color: "#907CFF",
                          },
                          { textAlign: "left" },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        <CustomImage
                          source={twitter16}
                          require={true}
                          style={{ width: 16, height: 16 }}
                          webStyle={{ width: 16, height: 16 }}
                          altText={"The Morning Context"}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          this.pushShareStoryEventToDataLayer("linkedin");
                          window.open(
                            `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${ReactHtmlParser(
                              storyData.title
                            )}`,
                            "_blank"
                          );
                        }}
                        style={[
                          {
                            marginBottom: 15,
                            paddingLeft: 0,
                            paddingRight: 10,
                          },
                          {
                            fontSize: helpers.isDesktop() ? 14 : 16,
                            lineHeight: helpers.isDesktop() ? 20 : 23,
                            fontFamily: FontFamily.bold,
                            color: "#907CFF",
                          },
                          { textAlign: "left" },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        <CustomImage
                          source={linkedin16}
                          require={true}
                          style={{ width: 16, height: 16 }}
                          webStyle={{ width: 16, height: 16 }}
                          altText={"The Morning Context"}
                        />
                      </TouchableOpacity>
                      {/* <Tooltip popover={<Text>Info here</Text>}> */}
                      <Popable
                        content="Copied"
                        onAction={(visible) => {
                          this.pushShareStoryEventToDataLayer("Copied");
                          if (visible) {
                            this.copyToClipboard();
                          }
                        }}
                      >
                        <CustomImage
                          source={link16}
                          require={true}
                          style={{ width: 16, height: 16 }}
                          webStyle={{ width: 16, height: 16 }}
                          altText={"The Morning Context"}
                        />
                      </Popable>
                      {this.state.allowGifting && <TouchableOpacity
                        onPress={() => {
                          this.pushShareStoryEventToDataLayer("gift");
                        }}
                        style={[
                          {
                            marginBottom: 15,
                            paddingLeft: 10,
                            paddingRight: 10,
                          },
                          {
                            fontSize: helpers.isDesktop() ? 14 : 16,
                            lineHeight: helpers.isDesktop() ? 20 : 23,
                            fontFamily: FontFamily.bold,
                            color: "#907CFF",
                          },
                          { textAlign: "left" },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        <CustomImage
                          source={giftIcon}
                          require={true}
                          style={{ width: 16, height: 16 }}
                          webStyle={{ width: 16, height: 16 }}
                          altText={"The Morning Context"}
                        />
                      </TouchableOpacity>}
                    </View>
                    {this.state.allowGifting && <><View style={{ alignSelf: 'flex-end', paddingRight: '45%' }}>
                      <CustomImage
                        source={triangleIcon}
                        require={true}
                        style={{ width: 15, height: 15, resizeMode: 'contain', paddingRight: 15 }}
                        webStyle={{ width: 15, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                      />
                    </View>
                      <View style={[Flex.column, Flex.justfiyCenter, Flex.alignCenter, { backgroundColor: Colors.giftingBackground, borderRadius: 10, paddingTop: 15, paddingHorizontal: 8, width: '70%', alignSelf: 'flex-start', textAlign: 'center', marginRight: '10%' }, boxShadowStyle]}>
                        <Text style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.75rem', borderBottomColor: Colors.primary, borderBottomWidth: 1 }}>Gift this Story</Text>
                        <Text style={{ fontSize: '0.65rem', marginTop: 5, width: '100%', fontFamily: FontFamily.lora, letterSpacing: 1 }}>Give this subscriber-only story to a friend and help them read it for free.</Text>
                        <View style={[Flex.column, { marginTop: 5, width: '100%' }]}>

                        </View>
                        <TouchableOpacity style={[Flex.row, { alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%' }]} onPress={this.onCopyButtonClick}  >
                          <CustomImage
                            source={copyIcon}
                            require={true}
                            style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                            webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                          />
                          <Text style={{ fontWeight: '400', fontSize: '0.75rem' }}>{this.state.copyButtonText}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[Flex.row, { alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%' }]} onPress={this.handleWhatsappShare}>
                          <CustomImage
                            source={whatsappIcon}
                            require={true}
                            style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                            webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                          />
                          <Text style={{ fontWeight: '400', fontSize: '0.75rem' }}>WhatsApp</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[Flex.row, { alignSelf: 'flex-start', paddingVertical: 6, borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: '100%' }]} {...btnClickGift}>
                          <CustomImage
                            source={emailIcon}
                            require={true}
                            style={{ width: 21, height: 22, resizeMode: 'contain', paddingRight: 15 }}
                            webStyle={{ width: 21, height: 'auto', objectFit: 'contain', paddingRight: 15 }}
                          />
                          <Text style={{ fontWeight: '400', fontSize: '0.75rem' }}>Email</Text>
                        </TouchableOpacity>
                        {/* {this.state.subsLeft ?
                          <TouchableOpacity style={[Flex.row, { alignSelf: 'flex-start', paddingTop: 6, paddingBottom: 10 }]} {...btnClickGiftSub}>
                            <Text style={{ fontWeight: '700', fontSize: '0.75rem' }}>GIFT A 30-DAY SUBSCRIPTION</Text>
                          </TouchableOpacity> : null} */}
                      </View></>}
                  </View>
                )}

                <View style={[{ width: helpers.isDesktop() ? "70%" : "100%" }]}>
                  {storyData &&
                    storyData.posts &&
                    storyData.posts.map((item, index) => {
                      // console.log("itemitem:", item);
                      return (
                        <View
                          ref={(ref) => this._nodes.set(index, ref)}
                          style={[
                            helpers.isDesktop() && {
                              backgroundColor: "#F3F5FF",
                            },
                            {
                              padding: helpers.isDesktop() ? 0 : 0,
                              paddingBottom: 0,
                              marginBottom: !isUserLoggedIn ? 20 : 20,
                            },
                            darkMode && { backgroundColor: Colors.darkPurple },
                          ]}
                        >
                          <View
                            style={[
                              helpers.isDesktop() && {
                                backgroundColor: "#ffffff",
                              },
                              {
                                padding: helpers.isDesktop() ? 20 : 0,
                                width: "100%",
                                margin: "auto",
                              },
                            ]}
                          >
                            <View
                              style={[
                                { backgroundColor: "#ffffff" },
                                { padding: helpers.isDesktop() ? 20 : 16 },
                                darkMode && {
                                  backgroundColor: Colors.darkPurple,
                                },
                              ]}
                            >
                              <View
                                style={[
                                  {
                                    width: helpers.isDesktop()
                                      ? "100%"
                                      : "100%",
                                    marginBottom: 15,
                                  },
                                ]}
                              >
                                {item.category.map((value, index) => {
                                  return (
                                    <View>
                                      <Text
                                        style={[
                                          {
                                            color: renderBorderColor(value),
                                            fontFamily: FontFamily.bold,
                                            textTransform: "uppercase",
                                            fontSize: 15,
                                            lineHeight: 16,
                                            backgroundImage: `url(${categoryImage(
                                              value
                                            )})`,
                                            backgroundRepeat: "no-repeat",
                                          },
                                          paddingCategory(value),
                                        ]}
                                      >
                                        {value}
                                      </Text>
                                    </View>
                                    // <View>
                                    //   <Text
                                    //     style={{
                                    //       color: renderBorderColor(value),
                                    //       fontFamily: FontFamily.bold,
                                    //       textTransform: "uppercase",
                                    //       fontSize: 20,
                                    //     }}
                                    //   >
                                    //     {value}
                                    //   </Text>
                                    // </View>
                                    // <CustomImage
                                    //   source={categoryImage(value)}
                                    //   // source={healthcare}
                                    //   require={true}
                                    //   style={[
                                    //     helpers.isWeb()
                                    //       ? {
                                    //         width: helpers.isDesktop()
                                    //           ? 116
                                    //           : 96,
                                    //         height: "auto",
                                    //       }
                                    //       : {
                                    //         width: helpers.isDesktop()
                                    //           ? 116
                                    //           : 96,
                                    //         height: 30,
                                    //       },
                                    //   ]}
                                    //   altText={"The Morning Context"}
                                    // />
                                  );
                                })}
                              </View>

                              <View key={index}>
                                <Text
                                  style={[
                                    {
                                      fontSize: helpers.isDesktop() ? 36 : 22,
                                      lineHeight: helpers.isDesktop() ? 40 : 32,
                                      fontFamily: FontFamily.bold,
                                      color: "#051462",
                                      marginBottom: -2,
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  {item.title}
                                </Text>
                                {/* <CustomImage
                                                            source={item.featured_image}
                                                            require={true}
                                                            resizeMode={'cover'}
                                                            style={{ width: "100%", height: 200, backgroundColor: "red" }}
                                                            altText={'The Morning Context'}
                                                        /> */}

                                <Text
                                  style={[
                                    {
                                      fontSize: helpers.isDesktop() ? 18 : 16,
                                      lineHeight: helpers.isDesktop() ? 26 : 23,
                                      fontFamily: FontFamily.regular,
                                      color: "#051462",
                                      marginTop: 0,
                                    },
                                    { paddingBottom: 3, textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  <PostContent
                                    data={item.content}
                                    darkMode={darkMode}
                                    openLink={(link) =>
                                      this.openSocialMedia(link)
                                    }
                                  />
                                </Text>
                              </View>
                              <Text
                                style={[
                                  {
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop()
                                      ? 19.2
                                      : 23.5,
                                    fontFamily: FontFamily.regular,
                                    color: "#000000",
                                  },
                                  {
                                    textAlign: helpers.isDesktop()
                                      ? "left"
                                      : "left",
                                    marginBottom: helpers.isDesktop() ? 10 : 10,
                                  },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                Share
                              </Text>
                              <View style={[Flex.row]}>
                                <TouchableOpacity
                                  onPress={() => {
                                    window.open(
                                      `https://www.facebook.com/sharer/sharer.php?u=${path}?post_id=${index}&quote=${ReactHtmlParser(
                                        item.title
                                      )}`,
                                      "_blank"
                                    );
                                  }}
                                  style={[
                                    {
                                      marginBottom: 15,
                                      paddingLeft: 0,
                                      paddingRight: 10,
                                    },
                                    {
                                      fontSize: helpers.isDesktop() ? 14 : 16,
                                      lineHeight: helpers.isDesktop() ? 20 : 23,
                                      fontFamily: FontFamily.bold,
                                      color: "#907CFF",
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  <CustomImage
                                    source={facebook16}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                    altText={"The Morning Context"}
                                  />
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    window.open(
                                      // `https://twitter.com/intent/tweet?text=${item.title}&url=${path}`,
                                      `https://twitter.com/intent/tweet?text=${ReactHtmlParser(
                                        item.title
                                      )}&url=${path}?post_id=${index}`,

                                      "_blank"
                                    );
                                  }}
                                  style={[
                                    {
                                      marginBottom: 15,
                                      paddingLeft: 0,
                                      paddingRight: 10,
                                    },
                                    {
                                      fontSize: helpers.isDesktop() ? 14 : 16,
                                      lineHeight: helpers.isDesktop() ? 20 : 23,
                                      fontFamily: FontFamily.bold,
                                      color: "#907CFF",
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  <CustomImage
                                    source={twitter16}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                    altText={"The Morning Context"}
                                  />
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    // this.shareOnLinkedIn(path, index, item.title);
                                    window.open(
                                      `https://www.linkedin.com/shareArticle?mini=true&url=${path}#post_id=${index}&title=${ReactHtmlParser(
                                        item.title
                                      )}&summary=${ReactHtmlParser(
                                        item.title
                                      )}&source=Via%20TMC`,
                                      "_blank"
                                    );
                                  }}
                                  style={[
                                    {
                                      marginBottom: 15,
                                      paddingLeft: 0,
                                      paddingRight: 10,
                                    },
                                    {
                                      fontSize: helpers.isDesktop() ? 14 : 16,
                                      lineHeight: helpers.isDesktop() ? 20 : 23,
                                      fontFamily: FontFamily.bold,
                                      color: "#907CFF",
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  <CustomImage
                                    source={linkedin16}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                    altText={"The Morning Context"}
                                  />
                                </TouchableOpacity>
                                <Popable
                                  content="Copied"
                                  onAction={(visible) => {
                                    if (visible) {
                                      this.copyToClipboard();
                                    }
                                  }}
                                >
                                  <CustomImage
                                    source={link16}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                    altText={"The Morning Context"}
                                  />
                                </Popable>
                                {this.state.allowGifting && <TouchableOpacity
                                  onPress={() => {
                                    // this.shareOnLinkedIn(path, index, item.title);
                                  }}
                                  style={[
                                    {
                                      marginBottom: 15,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    },
                                    {
                                      fontSize: helpers.isDesktop() ? 14 : 16,
                                      lineHeight: helpers.isDesktop() ? 20 : 23,
                                      fontFamily: FontFamily.bold,
                                      color: "#907CFF",
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  <CustomImage
                                    source={giftIcon}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                    altText={"The Morning Context"}
                                  />
                                </TouchableOpacity>}
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  <View
                    style={[
                      {
                        width: helpers.isDesktop() ? "34%" : "100%",
                        alignSelf: "flex-end",
                        padding: 10,
                      },
                    ]}
                  >
                    <ButtonGradient
                      title={"READ YESTERDAY ARCHIVES"}
                      rootStyle={{
                        btnWrapper: [
                          button.primaryGradient,
                          {
                            width: "100%",
                            height: helpers.isDesktop() ? 60 : 40,
                          },
                        ],
                        btnText: [
                          button.primaryGradientText,
                          { fontSize: 16, fontWeight: "400" },
                        ],
                      }}
                      onClick={() => this.navigateScreen("button")}
                      darkMode={darkMode}
                    />
                  </View>

                  {/* {isUserLoggedIn && <View style={[helpers.isDesktop() && {
                                backgroundColor: "#F3F5FF"
                            }, { padding: helpers.isDesktop() ? 0 : 0, paddingBottom: 0, marginBottom: !isUserLoggedIn ? 20 : 0 }, darkMode && { backgroundColor: Colors.darkPurple },]}>
                                <View style={[helpers.isDesktop() && { backgroundColor: "#ffffff" }, { padding: helpers.isDesktop() ? 20 : 0, width: "100%", margin: "auto" }]}>
                                    <View style={[{ backgroundColor: "#ffffff" }, { padding: helpers.isDesktop() ? 20 : 10 }, darkMode && { backgroundColor: Colors.darkPurple }]}>
                                        <View style={[{ width: helpers.isDesktop() ? 116 : 96, marginBottom: 15 }]}>
                                            <CustomImage
                                                source={healthcare}
                                                require={true}
                                                style={[helpers.isWeb() ? { width: helpers.isDesktop() ? 116 : 96, height: "auto" } : { width: helpers.isDesktop() ? 116 : 96, height: 30 }]}
                                                altText={'The Morning Context'}
                                            />
                                        </View>
                                        <View  >
                                            <Text style={[{ fontSize: helpers.isDesktop() ? 36 : 18, lineHeight: helpers.isDesktop() ? 39 : 26, fontFamily: FontFamily.bold, marginBottom: 15, color: '#051462' }, { paddingBottom: 3, textAlign: "left" }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>New Zealand announced strict lockdown over one COVID-19 case second</Text>
                                            <CustomImage
                                                source={storyNameThumbnial}
                                                require={true}
                                                resizeMode={'cover'}
                                                style={{ width: "100%", height: 200, }}
                                                altText={'The Morning Context'}
                                            />

                                            <Text style={[{ fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 36 : 23, fontFamily: FontFamily.regular, color: '#051462', marginTop: 10 }, { paddingBottom: 3, textAlign: "left" }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>The 63-year-old woman was infected with the Delta Plus variant of the virus, succumbing to it on July 27, said Brihanmumbai Municipal Corporation (BMC) officials. The authorities came to know only on August 11 that she was infected with the variant after her genome sequencing report was received. The woman tested positive on July 21 and had several comorbidities, including diabetes, according to officials. She was fully vaccinated and didn’t have any travel history. Two of her close contacts have also tested positive for the Delta Plus variant...
                                                Maharashtra has reported three deaths due to the Delta Plus variant so far.
                                            </Text>

                                        </View>
                                    </View>

                                </View>
                            </View >} */}
                </View>
              </View>

              {(!isUserLoggedIn ||
                (isUserLoggedIn &&
                  teamSubscription?.plan?.tmc_group != "team_credit" &&
                  teamSubscription?.plan?.tmc_group != "team" &&
                  postData?.access_type == "privileged" &&
                  showSubscribeModal)) &&
                isPopupEnabled && (
                  <View>
                    <YesterdayModal
                      navigateToScreen={this.props.navigateToScreen}
                      darkMode={darkMode}
                      postData={postData}
                      goToLogin={this.goToLogin}
                      goToSignUp={this.goToSignUp}
                      closeModal={this.closeModal}
                      monthly_price={this.state.monthlyData}
                      country={country}
                      isLoggedin={isUserLoggedIn}
                      locationInfo={
                        this.state.locationInfo || { countryInfo: country }
                      }
                      userSubscriptionExpired={
                        this.state.userSubscriptionExpired
                      }
                      pushStoryNudgeInteractionToDataLayer={
                        this.pushStoryNudgeInteractionToDataLayer
                      }
                    />
                  </View>
                )}
            </View>

            {!this.displayPopup && (!userSubscriptionActive && !this.state.postUnlocked) && teamSubscription?.plan?.tmc_group != "team_credit" &&
              /*postData?.access_type == "privileged" &&*/ (
                <View
                  onLayout={(event) => {
                    const layout = event.nativeEvent.layout;
                    this.subscriptionLayout = layout.y;
                  }}
                >
                  {userLoader || plansLoader ? (
                    this.actionSubscriptionBoxLOader()
                  ) : (
                    <View
                      style={{
                        backgroundColor: darkMode ? "#121212" : "#fafafa",
                      }}
                    >
                      {this.state.privCredits > 0 ? (
                        <UnlockStoryNew
                          darkMode={darkMode}
                          navigateScreen={(pushProp) =>
                            this.navigateScreen(pushProp)
                          }
                          goToLogin={this.goToLogin}
                          privilege={true}
                          data={postSummary}
                          unlockPost={this.unlockPost}
                          privCredits={this.state.privCredits}
                          custom_user_id={this.state.custom_user_id}
                        />
                      ) : null}

                      <View
                        style={[
                          Padding.ph_2,
                          postSingle.unlockSection,
                          darkMode && { backgroundColor: "#121212" },
                        ]}
                      >
                        <View
                          style={[
                            Helpers.conatinerWeb,
                            helpers.isDesktop() && Helpers.conatinerWeb700,
                            Padding.pb_4,
                          ]}
                        >
                          <Text
                            style={[
                              Helpers.textCenter,
                              Typo.descTextL,
                              helpers.isDesktop() && {
                                lineHeight: 27,
                                letterSpacing: 0.3,
                                marginTop: 30,
                              },
                              {
                                fontFamily: FontWeight.regular,
                                fontWeight: "600",
                              },
                              {
                                fontSize: helpers.isDesktop() ? 16 : 14,
                              },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                              !helpers.isDesktop() && {
                                paddingTop: 40,
                              },
                            ]}
                          ></Text>

                          {helpers.isDesktop() ? (
                            <View
                              style={[
                                Flex.alignCenter,
                                Padding.pt_3,
                                Padding.pb_3,
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: helpers.isDesktop() ? 34 : 20,
                                    lineHeight: 40,
                                    fontFamily: FontFamily.abrilFatface,
                                    marginBottom: 10,
                                  },
                                  Helpers.textCenter,
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {userSubscriptionActive
                                  ? `Upgrade to yearly plan to access this ${postData.newsletter
                                    ? "newsletter"
                                    : "story"
                                  }`
                                  : userSubscriptionStatus == "expired"
                                    ? `Your subscription has expired!`
                                    : this.state.privCredits > 0
                                      ? `Or subscribe to unlock all ${postData.newsletter
                                        ? "Newsletters"
                                        : "Stories"
                                      } `
                                      : postData?.access_type == "non_privileged"
                                        ? `Or subscribe to unlock all ${postData.newsletter
                                          ? "Newsletter"
                                          : "Story"
                                        }`
                                        : null}
                              </Text>
                              <PageBreak size={"small"} darkMode={darkMode} />
                            </View>
                          ) : (
                            <View style={[Padding.pt_4]}>
                              <View
                                style={[
                                  Flex.alignCenter,
                                  Margin.mb_3,
                                  { marginRight: -20, marginLeft: -20 },
                                ]}
                              >
                                <View
                                  style={[
                                    Padding.ph_1,
                                    Helpers.titleBgWrap,
                                    { backgroundColor: "#fafafa" },
                                    darkMode && {
                                      backgroundColor: "#121212",
                                    },
                                  ]}
                                >
                                  <View
                                    style={[
                                      Helpers.borderBottom,
                                      darkMode && {
                                        borderBottomColor:
                                          "rgba(255, 255, 255, 0.6)",
                                      },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        Helpers.textCenter,
                                        Typo.h3_R,
                                        helpers.isDesktop() && Typo.h3_RDesktop,
                                        Margin.mb_0,
                                        {
                                          fontSize: helpers.isDesktop()
                                            ? 34
                                            : 17,
                                          color: "#000",
                                        },
                                        {
                                          fontFamily: FontFamily.abrilFatface,
                                        },
                                        darkMode && {
                                          color: "rgba(255, 255, 255, 0.89)",
                                          borderColor:
                                            "rgba(255, 255, 255, 0.6)",
                                        },
                                      ]}
                                    >
                                      {userSubscriptionActive
                                        ? `Upgrade to yearly plan to access this ${postData.newsletter
                                          ? "newsletter"
                                          : "story"
                                        }`
                                        : userSubscriptionStatus == "expired"
                                          ? `Your subscription has expired!`
                                          : this.state.privCredits > 0
                                            ? `Subscribe and get access to all ${postData.newsletter
                                              ? "Newsletter"
                                              : "Stories"
                                            }`
                                            : null}
                                    </Text>
                                  </View>
                                </View>
                                <View
                                  style={[
                                    Helpers.titleLine,
                                    {
                                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                                    },
                                    darkMode && {
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    },
                                  ]}
                                ></View>
                              </View>
                            </View>
                          )}
                        </View>

                        {plansData && (
                          <View
                            style={[
                              Helpers.conatinerWeb,
                              helpers.isDesktop() && Helpers.conatinerWeb1200,
                              !helpers.isDesktop() && {
                                marginRight: "auto",
                                marginLeft: "auto",
                              },
                              helpers.isDesktop() && {
                                width: "1450px",
                              },
                            ]}
                          >
                            <SubscriptionPricingPlan
                              premium={"prelogout"}
                              planPage={true}
                              plansData={{ individual: plansData }}
                              userSubscription={userSubscription}
                              teamSubscription={teamSubscription}
                              country={country}
                              showSubscriptionPlan={(
                                subscribtionId,
                                subscribtion,
                                country
                              ) =>
                                this.subscribeToPlan(
                                  subscribtionId,
                                  subscribtion,
                                  country
                                )
                              }
                              hidePagebreak={true}
                              sidePanel={false}
                              navigateScreen={(pushProp) =>
                                this.navigateScreen(pushProp)
                              }
                              subscriptionPage={true}
                              lightBlueBg={false}
                              singlePost={true}
                              darkMode={darkMode}
                              hideTeam={true}
                              showTeamSection={true}
                              showCreditPlan={true}
                              creditPlan={creditPlan}
                              showExploreMore={true}
                              setCheckoutLoaderState={(loaderState) =>
                                this.setPlanButtonLoaderState(loaderState)
                              }
                              getCheckoutLoaderState={
                                this.getPlanButtonLoaderState
                              }
                            />
                          </View>
                        )}

                        {/* {this.state.postData && (
                              <View
                                style={
                                  helpers.isDesktop() && { height: "1000px" }
                                }
                              >
                                {this.renderLatestStory(
                                  "Exclusive benefits for subscribers"
                                )}
                              </View>
                            )} */}
                      </View>
                    </View>
                  )}
                </View>
              )}

            {!isArchiveStory && isUserLoggedIn && !isPopupEnabled && false ? (
              <ScrollContainerView>
                <YesterdayDontStopReading
                  navigation={this.props.navigation}
                  navigateWeb={(slug) => this.props.navigateToScreen(slug)}
                  navigateApp={(slug, params) =>
                    this.props.navigation.navigate(slug, params)
                  }
                  custom_user_id={this.state.custom_user_id}
                />
              </ScrollContainerView>
            ) : null}

            {isArchiveStory && isUserLoggedIn && !isPopupEnabled
              ? this.renderReadMainStoryBanner(darkMode)
              : null}
          </View>
        ) : (
          <View style={[styles.container]}>
            <ActivityIndicator size="large" style={styles.activityIndicator} />
          </View>
        )}
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { footerData, storyData } = this.state;
    return (
      <ScrollView
        onScroll={this.getScrollOffset}
        ref={(ref) => (this.appscrollview = ref)}
      >
        {this.renderPageHeader()}
        {this.renderPageContent()}
        {storyData ? (
          <AppFooter
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            footerData={footerData}
          />
        ) : (
          <View style={[styles.container]}>
            <ActivityIndicator size="large" style={styles.activityIndicator} />
          </View>
        )}
      </ScrollView>
    );
  };

  getSeoObject = () => {
    let { storyData } = this.state;
    if (storyData) {
      return seoObj.getYesterdayEditionSeo(storyData);
    }
    return {};
  };

  subscribeToPlan = (subscribtionId, subscribtion, country) => {
    try {
      Mixpanel.track("click_select_plan_btn", {
        plan: subscribtion.name,
        price: subscribtion.price,
      });
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    this.refs.header.showSubscriptionPlan(
      subscribtionId,
      subscribtion,
      country
    );
  };

  navigateToTeamPage = () => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/my-team");
    } else {
      this.props.navigation.navigate("/my-team");
    }
  };

  actionBoxLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let contentBg = darkMode ? "#121212" : "#ffffff";
    return (
      <View
        style={[
          actionBox.box,
          actionBox.boxDesktop,
          Padding.ph_2,
          { backgroundColor: contentBg },
        ]}
      >
        <ContentLoader
          containerStyles={{ marginBottom: 0 }}
          active={true}
          pHeight={[30]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />

        <View style={{ width: "30%", marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[2]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>

        <View style={{ marginBottom: 15 }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={3}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[30]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
      </View>
    );
  };

  actionSubscriptionBoxLOader = () => {
    return (
      <>
        {this.actionSubscriptionTopLOader()}
        {this.actionSubscriptionBottomLOader()}
      </>
    );
  };

  actionSubscriptionTopLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb700,
          { marginBottom: 50 },
        ]}
      >
        {this.actionBoxLOader()}
        <View
          style={[
            {
              marginTop: 20,
              width: 100,
              marginLeft: "auto",
              marginRight: "auto",
            },
            Flex.alignCenter,
          ]}
        >
          <ContentLoader
            containerStyles={{
              marginBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View
          style={[
            {
              marginTop: 10,
              width: 150,
              marginLeft: "auto",
              marginRight: "auto",
            },
            Flex.alignCenter,
          ]}
        >
          <ContentLoader
            containerStyles={{
              marginBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            active={true}
            pHeight={[45]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
      </View>
    );
  };

  actionSubscriptionBottomLOader = () => {
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb800,
          { flexDirection: helpers.isDesktop() ? "row" : "column" },
        ]}
      >
        <View
          style={{ marginHorizontal: helpers.isDesktop() ? 10 : 0, flex: 1 }}
        >
          {this.subscriptionBoxLOader()}
        </View>
        <View
          style={{ marginHorizontal: helpers.isDesktop() ? 10 : 0, flex: 1 }}
        >
          {this.subscriptionBoxLOader()}
        </View>
      </View>
    );
  };

  subscriptionBoxLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let contentBg = darkMode ? "#121212" : "#ffffff";
    return (
      <View
        style={[
          actionBox.box,
          actionBox.boxDesktop,
          Padding.ph_2,
          Flex.fill,
          Margin.mb_4,
          { backgroundColor: contentBg },
        ]}
      >
        <View style={{ width: 100, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[20]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={{ width: 100, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[45]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={{ width: 120, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[35]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>

        <View style={{ marginBottom: 15 }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={3}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[46]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
      </View>
    );
  };


  render() {
    const {
      pageLoader,
      showErrorModal,
      footerData,
      storyData,
      hideSideShareActionButton,
      subscriptionRefreshKey,
      showInsufficientCreditsPopup,
      creditPlan,
      country,
      token,
      teamSubscription
    } = this.state;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/",
      native: { redirect: "/", verifyRedirect: "/" },
    };

    let storyActions = {
      bookmark: false,
      like: false,
    };

    return (
      <>
        <View
          ref={this.applyRef}
          style={[
            webView && null,
            appStyles.appBg,
            Flex.fill,
            darkMode && { backgroundColor: Colors.darkBlackColorBg },
          ]}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            if (webView) {
              this.viewOffset = layout.height;
            }
          }}
        >
          {/* header : we will be new component for this module */}
          <Seo
            data={this.getSeoObject()}
            page="/yesterday/:slug"
            pageData={storyData}
            url={
              process.browser
                ? window.location.origin + window.location.pathname
                : ""
            }
          />

          <AppHeader
            ref="header"
            history={this.props.history}
            searchQuery={this.props.searchQuery}
            locationHash={this.props.locationHash}
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            redirectSlugs={redirectSlugs}
            refreshPage={() =>
              this.setState({ subscriptionRefreshKey: !subscriptionRefreshKey })
            }
            setCredits={(credits) => this.setState({ credits: credits })}
            navigateToSubscriptionScreen
            darkMode={darkMode}
            showFullHeader={true}
          />
          {/* {postUnlocked && postData.story.content && ( */}

          {!showErrorModal && (
            <>
              {pageLoader
                ? this.showPageLoader()
                : webView
                  ? this.renderWebPage()
                  : this.renderAppPage()}
            </>
          )}
          {this.state.token && storyData?.title && (
            <View>
              <View
                style={[
                  Padding.ph_2,
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb1200,
                  { marginHorizontal: "auto" },
                ]}
              >
                {!hideSideShareActionButton && helpers.isWeb() && (
                  <PostShare
                    data={storyData}
                    postContent={true}
                    postTitle={storyData?.title}
                    storyActions={storyActions}
                    // applyStoryActions={(type, value, animateFunc) =>
                    //   this.applyStoryActions(type, value, true, () =>
                    //     animateFunc()
                    //   )
                    // }
                    sharePost={(slug) => this.sharePost(slug)}
                    shareTitle={storyData?.title}
                    bookmarkLoading={false}
                    // likeLoading={true}
                    darkMode={false}
                    giftStory={this.state.allowGifting}
                    allowShare={false}
                    fromPage={"yesterday"}
                    profileInfo={this.state?.userProfile}
                    token={this.state.token}
                    openGiftCardModal={() => {
                      this.refs.header.openGiftCardModal(PrivilegeCreditGift.id, PrivilegeCreditGift, null, null, this.state.postData, this.state.postData.story?.credits?.unlock)
                    }}
                  // credits={credits}
                  />
                )}
              </View>
            </View>
          )}

          {storyData ? (
            <AppFooter
              navigateWeb={(slug) => this.props.navigateToScreen(slug)}
              navigateApp={(slug, params) =>
                this.props.navigation.navigate(slug, params)
              }
              footerData={footerData}
              Breadcrumbs={Breadcrumbs}
            />
          ) : null}

          {showErrorModal && (
            <ErrorModal
              goToHome={() => this.props.navigateToScreen("/")}
              tryAgain={() => this.getModalClickEvent()}
              show={showErrorModal}
            />
          )}
          {showInsufficientCreditsPopup && (
            <InsufficientCreditPopup
              token={token}
              closeModal={() =>
                this.setState({ showInsufficientCreditsPopup: false })
              }
              plan={[creditPlan]}
              country={country}
              subscribeToPlan={(subscribtionId, subscribtion, country) =>
                this.subscribeToPlan(subscribtionId, subscribtion, country)
              }
              credits={storyData.credits_to_unlock}
              teamSubscription={teamSubscription}
              navigateToTeamPage={() => this.navigateToTeamPage()}
            />
          )}
          {/* <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => this.scrollToTop()}
            style={customStyle.touchableOpacityStyle}
          >
            <FaArrowCircleUp style={customStyle.floatingButtonStyle} />
          </TouchableOpacity> */}
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(YesterdayStoryScreen));

const customStyle = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  scrollView: {},
  text: {
    fontSize: 42,
  },
  touchableOpacityStyle: {
    position: "absolute",
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    right: 30,
    bottom: 30,
  },
  floatingButtonStyle: {
    resizeMode: "contain",
    width: 50,
    height: 50,
    //backgroundColor:'black'
  },
});

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Yesterday",
  },
];
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80,
  },
});
