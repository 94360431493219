import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, postContentTags, postContentClasses, postTags, postActionBar } from '../../styles/appStyles';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const shareIcon = getImagePath('icons/share.png');
const shareIconLight = getImagePath('icons/share-light.png');
// const likeIcon = getImagePath('icons/like-icon.png');
// const likeIconSelected = getImagePath('icons/like-icon-selected.png');
const bookmarkIcon = getImagePath('icons/bookmark.png');
const bookmarkIconSelected = getImagePath('icons/bookmark-selected.png');
const fontIcon = getImagePath('icons/font-icon.png');
const fbIcon = getImagePath('icons/facebook-b.png');
const twitterIcon = getImagePath('icons/twitter-b.png');
const linkedIcon = getImagePath('icons/linkedin-b.png');
// const likeIconLight = getImagePath('icons/like-icon-light.png');
const bookmarkIconLight = getImagePath('icons/bookmark-light.png');
const fbIconLight = getImagePath('icons/facebook-light.png');
const twitterIconLight = getImagePath('icons/twitter-light.png');
const linkedIconLight = getImagePath('icons/linkedin-light.png');

export class PostActionBar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        const { updateFontSize, storyActions, applyStoryActions, sharePost, shareTitle, hideStoryActions, hideBorder, darkMode } = this.props;
        let btnClickBookmark, btnClickLike, btnClickShare, btnClickFB, btnClickTwitter, btnClickLinkdin, allowShare = false;
        let webView = helpers.isWeb();
        if (helpers.isWeb()) {
            btnClickBookmark = {
                onPress: () => applyStoryActions("bookmark", (storyActions && storyActions.bookmark) ? false : true)
            }
            btnClickLike = {
                onPress: () => applyStoryActions("like", (storyActions && storyActions.like) ? false : true)
            }
            btnClickShare = {
                onPress: () => sharePost('open')
            }
            if (process.browser) {
                if (navigator.share) {
                    allowShare = true;
                }
                let path = window.location.origin + window.location.pathname;
                btnClickFB = {
                    href: `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${shareTitle}`,
                    handleClick: () => this.props.pushShareStoryEventToDataLayer("facebook")
                }
                btnClickTwitter = {
                    href: `https://twitter.com/intent/tweet?text=${shareTitle}&url=${path}`,
                    handleClick: () => this.props.pushShareStoryEventToDataLayer("twitter")
                }
                btnClickLinkdin = {
                    href: `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${shareTitle}`,
                    handleClick: () => this.props.pushShareStoryEventToDataLayer("linkedin")
                }
            } else {
                allowShare = true;
            }
        } else {
            btnClickBookmark = {
                onPress: () => applyStoryActions("bookmark", (storyActions && storyActions.bookmark) ? false : true)
            }
            btnClickLike = {
                onPress: () => applyStoryActions("like", (storyActions && storyActions.like) ? false : true)
            }
            btnClickShare = {
                onPress: () => sharePost('open')
            }
            btnClickFB = {
                onPress: () => sharePost('fb')
            }
            btnClickTwitter = {
                onPress: () => sharePost('twitter')
            }
            btnClickLinkdin = {
                onPress: () => sharePost('linkedin')
            }
            allowShare = true;
        }
        return (
            <View style={[Flex.row, Flex.justifyBetween, helpers.isDesktop() && { borderLeftWidth: 0.3, borderColor: darkMode ? 'rgba(255,255,255, 0.3)' : 'rgba(0, 0, 0, 0.3)' }, { paddingTop: 0, paddingBottom: 0, marginTop: 15, marginBottom: 15 }, postActionBar.wrapper, hideStoryActions && Flex.justfiyCenter, { borderBottomWidth: 0 }, hideBorder && { borderColor: 'transparent' }]}>
                <View style={[Flex.row]}>
                    <TouchableOpacityLinkExternal {...btnClickFB} newTab={true}>
                        <View style={[postActionBar.actionBtn, hideStoryActions && { paddingRight: 5, paddingLeft: 5 }]}>
                            <CustomImage
                                source={darkMode ? fbIconLight : fbIcon}
                                require={true}
                                style={{ width: 23, height: 23 }}
                                webStyle={{ width: 23, height: 23 }}
                                altText={'Facebook'}
                            />
                        </View>
                    </TouchableOpacityLinkExternal>
                    <TouchableOpacityLinkExternal {...btnClickTwitter} newTab={true}>
                        <View style={[postActionBar.actionBtn, hideStoryActions && { marginRight: 0, paddingRight: 5, paddingLeft: 5 }]}>
                            <CustomImage
                                source={darkMode ? twitterIconLight : twitterIcon}
                                require={true}
                                style={{ width: 30, height: 23, resizeMode: 'contain' }}
                                webStyle={{ width: 28, height: 23 }}
                                altText={'Twitter'}
                            />
                        </View>
                    </TouchableOpacityLinkExternal>
                    {/*<TouchableOpacityLinkExternal {...btnClickLinkdin} newTab={true}>
                        <View style={[postActionBar.actionBtn]}>
                        <CustomImage
                            source={linkedIcon}
                            require={true}
                            style={{ width: 23, height: 25 }}
                            webStyle={{ width: 23, height: 25 }}
                            altText={'Linkedin'}
                        />
                        </View>
                    </TouchableOpacityLinkExternal>*/}
                    {!hideStoryActions &&
                        <>
                            <TouchableOpacity {...btnClickBookmark}
                                style={[postActionBar.actionBtn, { marginLeft: -5, marginRight: -5, paddingRight: 0 }]}>
                                <CustomImage
                                    source={(storyActions && storyActions.bookmark) ? bookmarkIconSelected : (darkMode ? bookmarkIconLight : bookmarkIcon)}
                                    require={true}
                                    style={{ width: 25, height: 24 }}
                                    webStyle={{ width: 25, height: 24 }}
                                />
                            </TouchableOpacity>
                            {/*<TouchableOpacity {...btnClickLike}
                                style={[postActionBar.actionBtn, { paddingRight: 0, marginRight: 0 }]}>
                                <CustomImage
                                    source={(storyActions && storyActions.like) ? likeIconSelected : (darkMode ? likeIconLight : likeIcon)}
                                    require={true}
                                    style={{ width: 27, height: 24 }}
                                    webStyle={{ width: 27, height: 24 }}
                                />
                            </TouchableOpacity>*/}
                        </>
                    }
                </View>
                {/* <View>
                    <TouchableOpacity
                    onPress={() => updateFontSize()}
                    style={[postActionBar.fontBtn]}>
                        <CustomImage
                            source={fontIcon}
                            require={true}
                            style={{ width: 27, height: 27 }}
                            webStyle={{ width: 27, height: 27 }}
                        />
                    </TouchableOpacity>
                </View> */}

            </View>
        );
    }
}

export default PostActionBar;