import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  button,
  Typo,
  subscribePlans,
} from "../../styles/appStyles";
import PageBreak from "../page-break";
import CreditPlanBox from "../credit-plan-box";
import SubscriptionPlanBox from "../subscription-plan-box";
import SubscriptionApiCall from "../subscription-plan-box/subscription-api-call";
import { helpers } from "../../utils/helpers";
import { Mixpanel } from "../../utils/mixpanel";
export class SubscriptionPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlan: [],
      planList: [],
    };
  }

  subscribeToPlan = (subscribtion) => {
    // console.log("Here ----->>>>> 2: ", subscribtion)
    this.props.subscribeToPlan(subscribtion);
    this.pushPurchasePlanInteractionEventToDataLayer(subscribtion)
    this.trackChangePlan(subscribtion);
  };

  pushPurchasePlanInteractionEventToDataLayer = (subscription) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text:
        subscription?.tmc_group === "credit" ? "buy now" : this.props.btnTitle?.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  };

  trackChangePlan = (subscribtion) => {
    const { sidePanel, btnTitle, userSubscription } = this.props;
    try {
      if (btnTitle == "Upgrade Plan" && sidePanel) {
        Mixpanel.track("click_change_plan", {
          current_plan: userSubscription.plan.name,
          move_to: subscribtion.name,
        });
      }
    } catch (error) {
      console.log("mix panel error =>", error);
    }
  };

  componentDidMount = () => {
    let selectedPlan = this.props.data.filter(
      (d, i) => d.id === this.props.userSubscription?.plan?.id
    );
    let freePlan = this.props.data.filter((d, i) => d.tmc_group === "free");
    this.setState(
      {
        currentPlan: selectedPlan.length > 0 ? selectedPlan : freePlan,
      },
      () => {
        let planList = this.props.data.filter((d, i) =>
            {
              if((d.tmc_group == "free" )){
                return false
              }

              if (d.id === this.state.currentPlan[0]?.id){
                return true
              }
              if (d.trial?.has_trial) {
                return d.trial.trial_price > this.state.currentPlan[0]?.price
              }
              if(this.state.currentPlan[0]?.trial?.has_trial){
                return d.price >= this.state.currentPlan[0]?.tax_exclusive_price  
              }
              return d.price >= this.state.currentPlan[0]?.price
            }
        );
        this.setState({ planList: planList });
      }
    );
  };

  render() {
    const {
      title,
      rootStyle,
      onClick,
      data,
      sidePanel,
      btnTitle,
      subscriptionPage = false,
      multiplePlans = false,
      navigateToTeamSubscription,
      darkMode,
      userSubscription,
      quaterlySubscription,
      auth,
      navigateScreen,
    } = this.props;

    let { currentPlan, planList } = this.state;

    let checkDesktop = sidePanel ? false : helpers.isDesktop() ? true : false;
    return (
      <View
        style={[helpers.isWeb() && { flex: subscriptionPage ? 1 : "unset" }]}
      >
        {title != "" && (
          <>
            {/* <Text style={[Helpers.textCenter, Helpers.textUppercase, Typo.h3, Margin.mb_1]}>{title}</Text> */}
            <Text
              style={[
                Helpers.textCenter,
                Helpers.textUppercase,
                Typo.h3_M,
                helpers.isDesktop() && Typo.h3_MDesktop,
                Margin.mb_1,
                checkDesktop && { fontSize: 30, lineHeight: 45 },
              ]}
            >
              {title}
            </Text>
            <PageBreak size={"small"} style={[Margin.mb_5]} />
          </>
        )}

        <View
          style={[helpers.isWeb() && { flex: subscriptionPage ? 1 : "unset" }]}
        >
          <View
            style={[
              subscribePlans.wrapperBlock,
              helpers.isDesktop() && subscribePlans.wrapperBlockDesktop,
              !checkDesktop && { flexWrap: "wrap" },
              { justifyContent: "center" },
              subscriptionPage && { flex: 1 },
              !helpers.isDesktop() && {
                paddingTop: 0,
                paddingTop: helpers.isDesktop() ? 50 : 0,
                backgroundColor: "#ffffff",
              },
            ]}
          >
            {helpers.isDesktop() ? (
              <>
                {planList &&
                  planList.map((item, index) => {
                    return (
                      <>
                        <SubscriptionPlanBox
                          sidePanel={sidePanel}
                          item={item}
                          btnText={btnTitle ? btnTitle : "Select Plan"}
                          index={index}
                          subscribeToPlan={() => this.subscribeToPlan(item)}
                          subscriptionPage={subscriptionPage}
                          multiplePlans={multiplePlans}
                          navigateToTeamSubscription={() =>
                            navigateToTeamSubscription()
                          }
                          darkMode={darkMode}
                          numberOfDatas={planList.length}
                          data={planList}
                          userSubscription={
                            userSubscription || quaterlySubscription
                          }
                          auth={auth}
                          navigateScreen={navigateScreen}
                          country={this.props.country}
                          setCheckoutLoaderState={(loaderState) =>
                            this.props.setCheckoutLoaderState(loaderState)
                          }
                          getCheckoutLoaderState={
                            this.props.getCheckoutLoaderState
                          }
                        />
                      </>
                    );
                  })}
              </>
            ) : (
              <SubscriptionApiCall
                // token={this.props.token}
                sidePanel={sidePanel}
                navigateToTeamSubscription={() => navigateToTeamSubscription()}
                navigateScreen={navigateScreen}
                darkMode={darkMode}
                userSubscription={userSubscription || quaterlySubscription}
                userSubscriptionExpired={this.props.userSubscriptionExpired}
                planType={"individual"}
                auth={auth}
                subscribeToPlan={(item) => this.subscribeToPlan(item)}
                data={data}
                country={this.props.country}
                setCheckoutLoaderState={(loaderState) =>
                  this.props.setCheckoutLoaderState(loaderState)
                }
                getCheckoutLoaderState={this.props.getCheckoutLoaderState}
              />
            )}
          </View>
        </View>
      </View>
    );
  }
}

export default SubscriptionPlans;
