import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  Linking,
  Platform,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  pageCover,
  accordion,
  Padding,
  article,
  Typo,
  FontWeight,
  input,
  Colors,
  formStyle,
  button,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../../components/button-gradient";
import AccordionListItem from "../../components/accordian";
import Seo from "../../components/seo-meta";
import VimeoVideo from "../../components/vimeo-video";
import seoObj from "../../utils/seo-helper";
import { FREQUENTLY_ASKED_QUESTIONS, PRICING_PLANS } from "../../utils/constants";
import dynamic from 'next/dynamic';

const cookies = new Cookies();

const faqIconClosed = getImagePath("icons/faq-icon.svg")
const faqIconOpened = getImagePath("icons/faq-icon-opened.svg")
const friendsOfTMC = getImagePath("img/friends-of-tmc.png");
const friendsOfTMCIcon = getImagePath("icons/friends-of-tmc-icon.svg")
const friendsOfTMCIconDark = getImagePath("icons/friends-of-tmc-icon-dark.svg")

let SECTIONS = [
  {
    id: 0,
    title: "What is The Morning Context?",
    content:
      "The Morning Context helps you make sense of the world with deeply-reported, impactful and ambitious stories. We are unabashedly an ad-free subscriptions business, almost entirely funded by readers.",
  },
  {
    id: 1,
    title: "What do you write about?",
    content: "There are three pillars to our editorial strategy:",
    data: [
      "Longreads— We write the most ambitious stories on tech companies, public markets, environment and conflict.",
      "Newsletters— A wealth of knowledge from India’s top writers curated for our readers.",
      "Catchup news— We give you a clutter-free recap of everything that is happening around the world with a quick, to-the-point perspective.",
    ],
  },
  {
    id: 2,
    title: "How many stories do you write?",
    content: "We publish at least three stories a day.",
  },
  {
    id: 3,
    title: "Can I read your stories without subscribing?",
    content:
      "We have a free starter plan with which you can read a mix of stories and newsletters. Just sign up and we will credit 100 credits in your account. You can then spend your credits in reading the stories from our curated starter collection. No credit card. No hassle. You can check the tutorial video below to see how to unlock stories using starter credits.",
  },
  {
    id: 4,
    title: "What are credits?",
    content:
      "As an alternative to our subscription plans, you can purchase credits that you can then use to unlock specific articles of your choice. There are two types: Starter credits, which you get for free when you sign up, and Privilege credits, which you buy.",
  },
  {
    id: 5,
    title: "Why should I purchase credits?",
    content:
      "The Morning Context is built to serve just one person, you. While we’d love for you to subscribe to read us, if you aren’t up for it, that’s not a deal-breaker. We believe that as a reader you should have the independence to decide what you want to read—the choice should be yours. That’s why we have the credits plan. You can purchase credits, and spend them in reading what you want.",
  },
  {
    id: 6,
    title: "What are your subscription plans and privileges?",
    content:
      "Our subscription plans, monthly and annual are competitively priced. You can check them out ",
  },
  {
    id: 7,
    title: "Is there an option for a refund?",
    content:
      "We don’t issue refunds. But if there’s something you don’t like we’d certainly like to hear from you. Write to us at ",
  },
  // {
  //     title: 'Can I gift stories to my friends who don’t have your subscription?',
  //     content: 'Of course. Gifting a fixed number of stories every month is a feature of our subscriber plans.',
  // },
  //   {
  //     id: 8,
  //     title:
  //       "I want to purchase the quarterly subscription. Why is it only showing a credit card as the only payment option?",
  //     content:
  //       "Our quarterly subscription only supports recurring payment methods, which are currently limited to cards.",
  //   },
  {
    id: 8,
    title:
      "I am a paying subscriber but I am not getting your daily emails. What can I do?",
    content:
      "Your mail client’s filters may be delivering our emails in your spam or promotions folder. Please check there. Just to be sure that you don’t miss our daily emails, add newsletters@themorningcontext.com to your address book or simply reply to any of our daily emails.",
  },
  // {
  //     title: 'I am a paying subscriber but I am not able to access the stories and your website is asking me to pay again. What can I do?',
  //     content: 'Chances are you are not using your correct email address with which you subscribed. Please sign in using that email address only.',
  // },
  {
    id: 9,
    title: "Can I change my email address?",
    content: "Yes. Just head to your account settings and update your details.",
  },
  {
    id: 10,
    title: "Can I cancel my subscription?",
    content:
      "Yes. You can cancel your subscription in your account settings. (You’ll still receive our daily story summaries.)",
  },
  {
    id: 11,
    title: "How do I download The Morning Context app? ",
    content: "",
  },
];

let iOS_FAQ = [
  {
    title:
      "How to sign in to my existing TMC account using Sign in with Apple?",
    content:
      "To sign in to an existing TMC account using Sign in with Apple, the email address associated with your Apple ID must match the email address associated with the TMC account you're trying to sign in to. You will not be able to sign in to an existing TMC account if you opt to hide your email address during the Sign in with Apple flow.",
  },
  {
    title: "How to disconnect my a Apple ID from my TMC account?",
    content:
      "1. On your iOS or iPadOS device, go to the Settings app.\n2. Select the top option where your name is displayed with Apple ID, iCloud, iTunes & Ap... beneath your name.\n3. Select Password & Security.\n4. Select Apps Using Your Apple ID.\n5. Select The Morning Context.\n6. Select Stop Using Apple ID.",
  },
  {
    title:
      "How to share my email id with TMC to sign in to my existing account?",
    content:
      "In case you sign in to TMC via Apple Sign in but chose to hide your email id, you will not be connected to your existing account.\nTo connect to your existing account, follow the below steps:\n1. Disconnect your Apple ID from the TMC app (refer previous FAQ query on how to disconnect)\n2. Once disconnected, go back to the TMC app on your device.\n3. Select Sign in with Apple.\n4. Select Share My Email.\nIf your Apple ID email address matches the email address on your TMC account, it will connect automatically. You will then be able to use Sign in with Apple going forward.",
  },
];

if (Platform.OS == "ios") {
  SECTIONS = SECTIONS.concat(iOS_FAQ);
}

export class FaqComponent extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    this.state = {
      pageLoader: false,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      selectedPlanIdx: 0,
      FAQCollapsedIndices: [0]
    };
  }

  componentDidMount = () => { };


  renderFAQText = (ans) => {
    if (ans === 'Our Android App is here.') {
      return <Text>Our Android App is{" "}
        <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.app.themorningcontext', '_blank')}>
          here
        </Text>
      </Text>
    } else if (ans === 'Our iOS App is here.') {
      return <Text>Our iOS App is{" "}
        <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL('https://apps.apple.com/in/app/the-morning-context/id1551672701', '_blank')}>
          here
        </Text>
      </Text>
    } else if (ans.includes('newsletters@themorningcontext.com')) {
      return <Text> {ans.split('newsletters@themorningcontext.com')[0]} {" "} <Text onPress={() => Linking.openURL('mailto:newsletter@themorningcontext.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>newsletters@themorningcontext.com</Text>{" "}{ans.split('newsletters@themorningcontext.com')[1]}</Text>
    } else if (ans.includes('support@themorningcontext.com')) {
      return <Text>{this.renderFAQText(ans.split('support@themorningcontext.com')[0])}{" "}<Text onPress={() => Linking.openURL('mailto:support@themorningcontext.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>support@themorningcontext.com</Text>{" "}{ans.split('support@themorningcontext.com')[1]}</Text>
    }
    else if (ans.includes('priya@mailtmc.com')) {
      return <Text> {ans.split('priya@mailtmc.com')[0]} {" "} <Text onPress={() => Linking.openURL('mailto:priya@mailtmc.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>priya@mailtmc.com</Text>{" "}{ans.split('priya@mailtmc.com')[1]}</Text>
    } else if (ans.includes("Fixed Plan:")) {
      return <Text><Text style={{ fontWeight: 600 }}>Fixed Plan: </Text>{ans.split('Fixed Plan:').pop()} </Text>
    } else if (ans.includes("Engaged Plan:")) {
      return <Text><Text style={{ fontWeight: 600 }}>Engaged Plan: </Text>{ans.split('Engaged Plan:').pop()} </Text>
    } else if (ans.includes('subscription page')) {
      return <Text>{ans.split('subscription page')[0]}{" "}<Text onPress={() => Linking.openURL('/pricing?idx=1')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>subscription page</Text>{" "}{ans.split('subscription page')[1]}</Text>
    }
    else return <Text>{ans}</Text>
  }

  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          helpers.isDesktop() ? Margin.mb_7 : Margin.mb_4,
          Helpers.textCenter,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          Flex.alignCenter,
          {
            marginTop: helpers.isDesktop() ? 80 : 75,
            paddingHorizontal: helpers.isDesktop() ? 20 : 20,
          },
        ]}
      >
        <Text
          accessibilityRole="header"
          aria-level="1"
          style={[
            Margin.mt_0,
            Margin.mb_2,
            pageCover.title,
            helpers.isDesktop() && pageCover.titleDesktop,
            Helpers.textCenter,
            !helpers.isDesktop() && { fontSize: 28 },
            { color: darkMode ? Colors.lightWhite : "#000000" },
          ]}
        >
          We are here to help you
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 24 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.5,
              fontFamily: FontFamily.regular,
              textAlign: "center",
              color: "#000",
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          Browse through the most frequently asked questions
        </Text>
      </View>
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          Flex.justfiyCenter,
          Flex.alignCenter,
          {
            position: webView ? "fixed" : "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "#ffffff",
          },
        ]}
      >
        <ActivityIndicator size={25} color={"#000000"} />
      </View>
    );
  };

  navigateTo = (link) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(link);
    } else {
      this.props.navigation.navigate("/pricing");
    }
  };

  renderPageContent = () => {
    const videoId = "492192956?&portrait=0&byline=0&title=0&background=1";
    const videoIdUrl =
      "https://player.vimeo.com/video/492192956?&portrait=0&byline=0&title=0&background=1";
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (helpers.isDesktop()) {
      return (
        <>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 20 }}>
            <View
              style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', backgroundColor: '#1E1B4B', width: '80%', height: 60, borderRadius: '30px', paddingLeft: 5, position: 'sticky', top: 0 }}>
              {PRICING_PLANS.map((plan, idx) =>
                <TouchableOpacity
                  style={{ minWidth: 200, display: 'flex', flexDirection: 'row', cursor: 'pointer', backgroundColor: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? 'white' : 'transparent', borderRadius: 30, height: '85%', zIndex: 1, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => {
                    this.setState({ selectedPlanIdx: idx })
                    this.setState({ FAQCollapsedIndices: [0] })
                  }}
                  onMouseEnter={() => {
                    this.setState({ hoverTabIdx: idx })
                    console.log("Focussed: ", idx)
                  }}
                  onMouseLeave={() => this.setState({ hoverTabIdx: -1 })}
                >
                  {idx === 4 && <CustomImage
                    source={this.state.selectedPlanIdx === idx || this.state.hoverTabIdx === idx ? friendsOfTMCIconDark : friendsOfTMCIcon}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 24, height: 24, objectFit: 'contain' }}
                    className={'friends-of-tmc-icon'}
                    altText={name}
                  />}
                  <Text
                    style={{ color: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? '#1E1B4B' : 'white', textAlign: 'center', fontWeight: 600, zIndex: 1, fontSize: 18, paddingHorizontal: 5 }}
                  >
                    {plan}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 100, marginBottom: 100 }}>
            <View style={{ display: 'flex', width: '60%', alignSelf: 'center', justifyContent: 'flex-start' }}>
              <Text style={{ fontSize: 24, fontFamily: 'PlayfairDisplay-Bold', fontWeight: 600, color: '#051462' }}>Frequently Asked Questions</Text>
              {FREQUENTLY_ASKED_QUESTIONS[this.state.selectedPlanIdx].FAQs.map((faq, index) => {
                if (typeof faq.answer === 'object') {
                  return <TouchableOpacity onPress={() => {
                    if (this.state.FAQCollapsedIndices.includes(index)) {
                      this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                    } else {
                      this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                    }
                  }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                      <Text style={{ fontSize: 20, fontWeight: 600, fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                      <CustomImage
                        source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                    </View>
                    {this.state.FAQCollapsedIndices.includes(index) &&
                      <View>
                        <Text style={{ fontSize: 18, marginTop: 20, display: 'flex', flexDirection: 'column', rowGap: 10, lineHeight: '150%', fontFamily: 'HaasGrotesk-Light' }}>
                          {faq.answer.map((ans) => {
                            return this.renderFAQText(ans)
                          })}
                        </Text>
                      </View>
                    }
                    <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                  </TouchableOpacity>
                } else
                  return <TouchableOpacity onPress={() => {
                    if (this.state.FAQCollapsedIndices.includes(index)) {
                      this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                    } else {
                      this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                    }
                  }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                      <Text style={{ fontSize: 20, fontWeight: 600, fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                      <CustomImage
                        source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                    </View>
                    {this.state.FAQCollapsedIndices.includes(index) &&
                      <View>
                        <Text style={{ fontSize: 18, marginTop: 20, lineHeight: '150%', fontFamily: 'HaasGrotesk-Light' }}>
                          {faq.answer === 'Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out here.' ?
                            <Text>Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out{" "}
                              <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL(`${window.location.origin}/pricing`, '_blank')}>
                                here
                              </Text>
                            </Text>
                            :
                            this.renderFAQText(faq.answer)}
                        </Text>
                      </View>
                    }
                    <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                  </TouchableOpacity>
              })}
            </View>
          </View>
        </>
      );
    } else {
      return <>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <View
            style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', backgroundColor: '#1E1B4B', height: 50, borderRadius: '30px', paddingLeft: 5, position: 'sticky', top: 0, marginHorizontal: 30, marginTop: '5vh' }}>
            {PRICING_PLANS.map((plan, idx) =>
              <TouchableOpacity
                style={{ minWidth: 200, display: 'flex', flexDirection: 'row', cursor: 'pointer', backgroundColor: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? 'white' : 'transparent', borderRadius: 30, height: '85%', zIndex: 1, alignItems: 'center', justifyContent: 'center' }}
                onPress={() => {
                  this.setState({ selectedPlanIdx: idx })
                  this.setState({ FAQCollapsedIndices: [0] })
                }}
                onMouseEnter={() => {
                  this.setState({ hoverTabIdx: idx })
                  console.log("Focussed: ", idx)
                }}
                onMouseLeave={() => this.setState({ hoverTabIdx: -1 })}
              >
                {idx === 4 && <CustomImage
                  source={this.state.selectedPlanIdx === idx || this.state.hoverTabIdx === idx ? friendsOfTMCIconDark : friendsOfTMCIcon}
                  style={{ width: 10, height: 10, objectFit: 'contain' }}
                  webStyle={{ width: 24, height: 24, objectFit: 'contain' }}
                  className={'green-tick-icon'}
                  altText={name}
                />}
                <Text
                  style={{ color: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? '#1E1B4B' : 'white', textAlign: 'center', fontWeight: 600, zIndex: 1, fontSize: 16, paddingHorizontal: 5 }}
                >
                  {plan}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </ScrollView>
        <View style={[{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: this.state.selectedPlanIdx === 4 ? 100 : 100, marginBottom: 100 }, this.state.selectedPlanIdx === 4 ? { } : {}]}>
          <View style={{ display: 'flex', width: '90%', alignSelf: 'center', justifyContent: 'flex-start' }}>
            <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', fontWeight: 600, color: '#051462' }}>Frequently Asked Questions</Text>
            {FREQUENTLY_ASKED_QUESTIONS[this.state.selectedPlanIdx].FAQs.map((faq, index) => {
              if (typeof faq.answer === 'object') {
                return <TouchableOpacity onPress={() => {
                  if (this.state.FAQCollapsedIndices.includes(index)) {
                    this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                  } else {
                    this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                  }
                }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                    <Text style={{ fontSize: 20, fontWeight: 600, width: '90%', fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                    <CustomImage
                      source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {this.state.FAQCollapsedIndices.includes(index) &&
                    <View>
                      <Text style={{ fontSize: 18, marginTop: 20, display: 'flex', flexDirection: 'column', rowGap: 10, lineHeight: '150%', width: '90%', fontFamily: 'HaasGrotesk-Light' }}>
                        {faq.answer.map((ans) => {
                          return this.renderFAQText(ans)
                        })}
                      </Text>
                    </View>
                  }
                  <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                </TouchableOpacity>
              } else
                return <TouchableOpacity onPress={() => {
                  if (this.state.FAQCollapsedIndices.includes(index)) {
                    this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                  } else {
                    this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                  }
                }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                    <Text style={{ fontSize: 20, fontWeight: 600, width: '90%', fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                    <CustomImage
                      source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {this.state.FAQCollapsedIndices.includes(index) &&
                    <View>
                      <Text style={{ fontSize: 18, marginTop: 20, lineHeight: '150%', width: '90%', fontFamily: 'HaasGrotesk-Light' }}>
                        {faq.answer === 'Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out here.' ?
                          <Text>Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out{" "}
                            <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL(`${window.location.origin}/pricing`, '_blank')}>
                              here
                            </Text>
                          </Text>
                          :
                          this.renderFAQText(faq.answer)}
                      </Text>
                    </View>
                  }
                  <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                </TouchableOpacity>
            })}
          </View>
        </View>
      </>
    }

  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/faq",
      native: { redirect: "/faq", verifyRedirect: "/faq" },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let hideHeader = this.props?.route?.params?.hideHeader;

    let data = {
      pageName: "FAQ",
    };

    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/faq",
      native: { redirect: "/faq", verifyRedirect: "/faq" },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let hideHeader = this.props?.route?.params?.hideHeader;

    let data = {
      pageName: "FAQ",
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj.getDefaultSeo(data)}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/faq"
        />

        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          hideHomeIcon={hideHeader}
          hideAccountIcon={hideHeader}
          showFullHeader={true}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? "fixed" : "absolute",
                  bottom: 20,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{ paddingHorizontal: 10, paddingVertical: 10 }}
                >
                  <CustomImage
                    source={upArrowIcon}
                    require={true}
                    style={{ width: 60, height: 60 }}
                    webStyle={{ width: 60, height: 60 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

const ConnectedFaqComponent = connect(mapStateToProps, mapDispatchToProps)(FaqComponent);

// Use Next.js dynamic import to ensure client-side rendering
const ClientSideFaq = dynamic(() => Promise.resolve(ConnectedFaqComponent), {
  ssr: false,
});

function Faq() {
  return (
    <ClientSideFaq />
  );
}

export default Faq;

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "FAQ",
  },
];
