import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  postContentTags,
  postContentClasses,
} from "../../styles/appStyles";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { helpers } from "../../utils/helpers";

export class PostContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { data, darkMode, dynamicPage } = this.props;

    // let fristChar
    // if(firstLetter) {
    //     fristChar = `<span class="firstletter">${firstLetter}</span>`
    //     data = `${fristChar} <div class="cont-remaining">${data}</div>`
    // }

    // const postData = data;
    return (
      <View style={[Flex.row]}>
        <View style={{ flex: 1 }}>
          <div
            className={
              "postsingle-content postsingle-bodyfont" +
              (darkMode ? " dark-mode" : "") +
              (dynamicPage ? " postsingle-dynamic-page-content" : "")
            }
          >
            {ReactHtmlParser(data)}
          </div>
        </View>
      </View>
    );
  }
}

export default PostContent;
