import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  TextInput,
} from "react-native";
import {
  Colors,
  Flex,
  Padding,
  Margin,
  Helpers,
  FontFamily,
  appStyles,
  articleLists,
  article,
  articleItem,
  postActionBar,
  formStyle,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import PageCover from "../../components/page-cover";
import InfiniteScrollComponent from "../../components/infinite-scroll";
import ArticleItemHorizontal from "../../components/article-item-horizontal";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../../components/custom-image";
import { Mixpanel } from "../../utils/mixpanel";
import NewsletterCategory from "../../components/newsletter-category";
import Seo from "../../components/seo-meta";
import { refreshScreen } from "../../../redux/App/app.actions";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const checkBoxSelected = getImagePath("icons/checkedIconSq.png");
const checkBoxUnSelected = getImagePath("icons/unCheckedIconSq.png");
const searchBox = getImagePath("icons/searchbox.png");

const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconLight = getImagePath("icons/bookmark-light.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");

const noArticles = getImagePath("img/no-articles.png");
const category = getImagePath("img/category.png");
const allStories = getImagePath("img/all-stories.png");
const archive = getImagePath("img/archive.png");
const tag = getImagePath("img/tag.png");
const company = getImagePath("img/company.png");
const theme = getImagePath("img/theme.png");

const homeSeoImage = getImagePath("img/extras/app-seo.png");

const cookies = new Cookies();
let resetTimeout = 0;

export class Archive extends Component {
  controller;
  signal;
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    let archiveSlug = "",
      archiveType = "",
      search = "",
      excludeRead,
      redirectParams;
    if (webView) {
      archiveSlug = this.props.archiveSlug;
      archiveType = this.props.params.archiveType;
    } else if (!webView) {
      archiveSlug = this.props.route.params.archiveSlug;
      archiveType = this.props.archiveType;
    }

    if (webView && process.browser && window.location.search) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("search=");
      });
      excludeRead = searchArr.find((searchParam) => {
        return searchParam.includes("exclude_read=true");
      });
      if (foundSearch) {
        search = foundSearch.replace("search=", "");
        search = search.replace(/%20/g, " ");
      }
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (redirectParams) {
        redirectParams = redirectParams.replace("redirect-params=", "");
      }
    } else if (!webView && this.props.route.params) {
      search =
        this.props.route.params && this.props.route.params.search
          ? this.props.route.params.search
          : "";
    }

    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      archiveData: this.props.archiveData ? this.props.archiveData : "",
      archiveDataList: this.props.archiveData
        ? this.props.archiveData.story
        : "",
      archiveDataSSR: this.props.archiveData ? true : false,
      archiveSlug: archiveSlug ? archiveSlug : "",
      archiveType: archiveType ? archiveType : "",
      pageLoader: this.props.archiveData ? false : true,
      showErrorModal: false,
      searchText: search,
      checkBoxChecked: token ? (excludeRead ? false : true) : true,
      footerData: this.props.footerData ? this.props.footerData : false,
      token: token == undefined || token == "" ? false : token,
      userLoggedin: token == undefined || token == "" ? false : true,
      readingList: this.props.archiveData
        ? this.props.archiveData.readingList
          ? this.props.archiveData.readingList
          : ""
        : "",
      redirectSlugs: {
        web: this.props.history?.location?.pathname,
        native: {
          redirect: archiveType == "all" ? "/all-stories" : "/",
          verifyRedirect: archiveType == "all" ? "/all-stories" : "/",
        },
      },
      redirectParams: redirectParams,
      custom_user_id: false
    };

    if (webView && redirectParams) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    }
  }

  componentDidMount = () => {
    let { archiveDataSSR, readingList, redirectParams, token } = this.state;
    if (!archiveDataSSR) {
      this.getArticles(1);
    }
    if (token && !readingList) {
      this.getExcludeListForUser();
    }
    if (redirectParams && token) {
      this.splitParamsAndAppyAction(redirectParams);
    }

    this.setBreadcrumbs();
    if (!this.state.custom_user_id) {
      this.setAuthToken();
    }
  };

  componentDidUpdate = () => {
    let webView = helpers.isWeb();
    let archiveSlug = webView
      ? this.props.archiveSlug
      : this.props.route.params.archiveSlug;
    if (archiveSlug != this.state.archiveSlug) {
      this.setState(
        {
          archiveSlug: archiveSlug,
        },
        () => this.getArticles(1)
      );
    }
  };

  setBreadcrumbs = (data) => {
    let { archiveType, archiveSlug } = this.state;

    Breadcrumbs = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "All Stories",
      },
    ];

    if (archiveType == "category" || archiveType == "category-archive") {
      Breadcrumbs = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: archiveSlug,
          link: `/category/${archiveSlug}`,
        },
        {
          name: archiveType == "category" ? "All Stories" : "Archives",
        },
      ];
    } else if (archiveType == "company") {
      Breadcrumbs = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Company",
          link: `/company/${archiveSlug}`,
        },
        {
          name: "All Stories",
        },
      ];
    } else if (archiveType == "tag" || archiveType == "theme") {
      Breadcrumbs = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: archiveType.charAt(0).toUpperCase() + archiveType.slice(1),
          link: `/${archiveType}/${archiveSlug}`
        },
        {
          name: "All Stories",
        },
      ];
    } else if (archiveType == "archive") {
      Breadcrumbs = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Archives",
        },
      ];
    }
  };

  getArticles = (page, append) => {
    let {
      readingList,
      checkBoxChecked,
      userLoggedin,
      searchText,
      archiveDataList,
      archiveSlug,
      archiveType,
      token,
    } = this.state;
    let excludeArr = [];
    if (
      archiveSlug == "game-point" ||
      archiveSlug == "peoples-economics" ||
      archiveSlug == "legal" ||
      archiveSlug == "the-world-revealed" ||
      archiveSlug == "govt"
    ) {
      archiveType = "newsletter";
    }
    if (archiveSlug == "govt") {
      archiveSlug = "creative-destruction";
    }
    if (
      readingList &&
      readingList.length > 0 &&
      !checkBoxChecked &&
      userLoggedin
    ) {
      readingList.filter((post) => {
        excludeArr.push(post.id);
      });
    }

    let endpoint = `/stories`;
    let body = {
      limit: 10,
      page: page,
      sort_on: "date",
      sort_by: "desc",
      search_string: searchText,
      exclude: excludeArr,
      filters: [
        {
          key: "newsletter",
          value: ["include"],
        },
        { key: "type", value: ["short", "regular", "newsletter"] },
      ],
    };
    if (archiveType == "category" || archiveType == "category-archive") {
      body["filters"] = [
        {
          key: "category",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "newsletter") {
      body["filters"] = [
        {
          key: "newsletter",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "company") {
      body["filters"] = [
        {
          key: "company",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "tag") {
      let querySplit = this.props.searchQuery.split("&");
      let key_tag = [];
      if (querySplit.length == 2) {
        let slugNewsSplit = querySplit[1].split("=");
        let slugNameSplit = slugNewsSplit[1].split(",");
        if (slugNameSplit.length) {
          for (let i = 0; i < slugNameSplit.length; i++) {
            key_tag.push(slugNameSplit[i]);
          }
        }
      } else {
        key_tag.push("include");
      }
      // 01, 05, 18, 06,
      body["filters"] = [
        {
          key: "storyplay",
          value: [archiveSlug],
        },
        { key: "newsletter", value: key_tag }, // include ,
      ];
    } else if (archiveType == "theme") {
      body["filters"] = [
        {
          key: "storyplay",
          value: [archiveSlug],
        },
      ];
    }

    body["filters"].push({
      key: "type",
      value: ["short", "regular", "newsletter"],
    });

    if (archiveType == "category-archive" || archiveType == "archive") {
      body["is_archive"] = true;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let stories = res.data.story;
          let querySplit = this.props.searchQuery.split("&");
          if (querySplit[0] === "?newsletter=true") {
            stories = stories.filter((data) => data.newsletter !== false);
            if (querySplit.length == 2) {
              let slugNewsSplit = querySplit[1].split("=");
              let slugNameSplit = slugNewsSplit[1].split(",");
              stories = stories.filter(
                (data) =>
                  data.newsletter["slug"] == slugNameSplit[0] ||
                  data.newsletter["slug"] == slugNameSplit[1]
              );
            }
          } else if (querySplit[0] === "?longreads=true")
            stories = stories.filter((data) => data.newsletter === false);

          console.log("archive:=", stories);
          this.setState({
            archiveData: res.data,
            archiveDataList: append ? archiveDataList.concat(stories) : stories,
            pageLoader: false,
          });
        } else {
          this.setState({
            archiveDataList: append ? archiveDataList : [],
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.aborted) {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  splitParamsAndAppyAction = (redirectParams) => {
    let paramsArr = redirectParams.split("+");
    let id = paramsArr.find((searchParam) => {
      return searchParam.includes("id=");
    });
    let action = paramsArr.find((searchParam) => {
      return searchParam.includes("action=");
    });
    let value = paramsArr.find((searchParam) => {
      return searchParam.includes("value=");
    });
    let index = paramsArr.find((searchParam) => {
      return searchParam.includes("index=");
    });

    if (id && action && value && index) {
      id = id.replace("id=", "");
      id = parseInt(id);
      action = action.replace("action=", "");
      value = value.replace("value=", "");
      value = value === "true" ? true : false;
      index = index.replace("index=", "");
      this.applyStoryActions(id, action, value, index);
    }
  };

  getMoreArticles = () => {
    console.log("archive:", this.state.archiveData);
    if(this.state.archiveData.page.has_next){
      this.getArticles(this.state.archiveData.page.current + 1, true);
    }
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  navigateToNewsletter = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/newsletters/" + slug);
    } else {
      this.props.navigation.navigate("single-newsletter", {
        newsletterSlug: slug,
      });
    }
  };
  
  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false,
        });
      } else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id,
        });
      }
    });
  };

  handleInputBlur = () => {
    if (this.state.searchText.trim() !== '') {
      this.pushSearchInteractionEventToDataLayer(this.state.searchText)
    }
  }

  pushSearchInteractionEventToDataLayer = (searchText) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "search_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      search_text: searchText,
      category_type: "NA"
    });
  }

  searchFieldOnChange = (value) => {
    this.setState(
      {
        searchText: value,
      },
      () => {
        if (resetTimeout != 0) {
          clearTimeout(resetTimeout);
        }
        let th = this;
        resetTimeout = setTimeout(() => {
          this.getArticles(1);
          if (helpers.isWeb()) {
            let pushProp = JSON.parse(JSON.stringify(window.location));

            if (value != "")
              pushProp.search =
                "?search=" +
                value +
                (!this.state.checkBoxChecked && this.state.token
                  ? "&exclude_read=true"
                  : "");
            else
              pushProp.search =
                !this.state.checkBoxChecked && this.state.token
                  ? "?exclude_read=true"
                  : "";

            this.props.history.replace(pushProp);
          }
        }, 500);
      }
    );
  };

  setSearchText = (value) => {
    this.setState({ searchText: value }, () => {
      this.getArticles(1);
      this.scrollToTop();
      if (helpers.isWeb()) {
        let pushProp = JSON.parse(JSON.stringify(window.location));

        if (value != "") {
          pushProp.search =
            "?search=" +
            value +
            (!this.state.checkBoxChecked && this.state.token
              ? "&exclude_read=true"
              : "");
        } else {
          pushProp.search =
            !this.state.checkBoxChecked && this.state.token
              ? "?exclude_read=true"
              : "";
        }

        this.props.history.replace(pushProp);
      }
    });
  };

  scrollToTop = () => {
    if (helpers.isWeb()) {
      setTimeout(function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2);
    } else {
      //
    }
  };

  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  };

  applyStoryActions = (id, type, value, index) => {
    const { token } = this.state;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let archiveDataList = this.state.archiveDataList;
      archiveDataList[index].is_bookmarked =
        !archiveDataList[index].is_bookmarked;
      this.setState({ archiveDataList: archiveDataList });
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let archiveDataList = this.state.archiveDataList;
            archiveDataList[index].is_bookmarked = res.data.bookmark;
            this.setState({ archiveDataList: archiveDataList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }

            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: archiveDataList[index].title,
                  story_slug: archiveDataList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: archiveDataList[index].title,
                  story_slug: archiveDataList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }

            this.props.refreshScreen(!this.props.app.refreshScreen);
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let archiveDataList = this.state.archiveDataList;
            archiveDataList[index].is_bookmarked =
              !archiveDataList[index].is_bookmarked;
            this.setState({ archiveDataList: archiveDataList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let archiveDataList = this.state.archiveDataList;
            archiveDataList[index].is_bookmarked =
              !archiveDataList[index].is_bookmarked;
            this.setState({ archiveDataList: archiveDataList });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=${
            this.state.redirectSlugs.web
          }&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          ...this.state.redirectSlugs.native,
          verifyRedirect:
            this.state.redirectSlugs.native.verifyRedirect +
            `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}+index=${index}`,
        });
      }
    }
  };

  showPageLoader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[20]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 50,
          }}
          active={true}
          pHeight={[100]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 10, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderPageCoverRightSection = () => {
    const { archiveData, archiveDataList } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[article.countWrap, { flexDirection: "row" }]}>
        <Text
          style={[
            article.count,
            helpers.isDesktop() && article.countDesktop,
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          {archiveDataList && archiveDataList.length > 0
            ? archiveData.page.total_count
            : 0}
        </Text>
        <Text
          style={[
            article.countLabel,
            helpers.isDesktop() && article.countLabelDesktop,
            { paddingLeft: 5 },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          {archiveDataList && archiveDataList.length == 1
            ? "Article"
            : "Articles"}
        </Text>
      </View>
    );
  };

  getExcludeListForUser = () => {
    this.setState({
      showErrorModal: false,
    });
    const { token } = this.state;
    let endpoint = `/user-action-story-list`;
    let body = {
      type: "read",
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            readingList: res.data.story,
          });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getPageTitle = (lower, placeholder = false) => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    let title,
      pageTitle = placeholder ? "All" : "All Stories";
    if (archiveType == "category") {
      title = archiveData.filters?.category;
      pageTitle =
        title == undefined
          ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
          : title.name;
    } else if (archiveType == "company") {
      title = archiveData.filters?.company?.find(
        (company) => company.slug == archiveSlug
      );
      pageTitle =
        title == undefined
          ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
          : title.name;
    } else if (archiveType == "tag") {
      title = archiveData.filters?.tag?.find((tag) => tag.slug == archiveSlug);

      if (!title) {
        title = archiveData.filters?.storyplay;
      }

      pageTitle =
        title == undefined
          ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
          : title.name;
    } else if (archiveType == "theme") {
      title = archiveData.filters?.storyplay;
      pageTitle =
        title == undefined
          ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
          : title.name;
    } else if (archiveType == "category-archive") {
      title = archiveData.filters?.category;
      pageTitle =
        (title == undefined
          ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
          : title.name) + " Archive";
    } else if (archiveType == "archive") {
      pageTitle = "Archived";
    }

    let parsedTitle = new DOMParser().parseFromString(pageTitle, "text/html");
    pageTitle = parsedTitle.documentElement.textContent;
    return lower ? pageTitle.toLowerCase() : pageTitle;
  };

  getTitleIcon = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const { archiveType, archiveSlug, archiveData } = this.state;
    let title,
      titleIcon = darkMode
        ? getImagePath("img/all-stories-dark.png")
        : getImagePath("img/all-stories.png");
    if (archiveType == "category") {
      titleIcon = darkMode
        ? getImagePath("img/category-dark.png")
        : getImagePath("img/category.png");
    } else if (archiveType == "company") {
      titleIcon = darkMode
        ? getImagePath("img/company-dark.png")
        : getImagePath("img/company.png");
    } else if (archiveType == "tag") {
      titleIcon = darkMode
        ? getImagePath("img/tag-dark.png")
        : getImagePath("img/tag.png");
    } else if (archiveType == "theme") {
      titleIcon = darkMode
        ? getImagePath("img/theme-dark.png")
        : getImagePath("img/theme.png");
    } else if (archiveType == "category-archive") {
      titleIcon = darkMode
        ? getImagePath("img/archive-dark.png")
        : getImagePath("img/archive.png");
    } else if (archiveType == "archive") {
      titleIcon = darkMode
        ? getImagePath("img/archive-dark.png")
        : getImagePath("img/archive.png");
    }
    return titleIcon;
  };

  getTitleIconStyle = () => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    let title,
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 130,
        position: "absolute",
        left: helpers.isDesktop() ? "54%" : "53%",
        top: helpers.isDesktop() ? -64 : -45,
        zIndex: -1,
      };
    if (archiveType == "category") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "45%",
        top: -40,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "company") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "45%",
        top: helpers.isDesktop() ? -40 : -32,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "tag") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "45%",
        top: -40,
        zIndex: -1,
        marginBottom: 30,
        textAlign: "center",
      };
    } else if (archiveType == "theme") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "40%",
        top: helpers.isDesktop() ? -40 : 0,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "category-archive") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "45%",
        top: -40,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "archive") {
      titleIconStyle = {
        width: "auto",
        height: helpers.isDesktop() ? 200 : 110,
        position: "absolute",
        left: helpers.isDesktop() ? "44.7%" : "42%",
        top: helpers.isDesktop() ? -40 : -30,
        zIndex: -1,
        marginBottom: 30,
      };
    }
    return titleIconStyle;
  };

  getTitleIconStyleMweb = () => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    let title,
      titleIconStyleMweb = {
        width: 160,
        height: 150,
        position: "absolute",
        left: "52%",
        top: -45,
        zIndex: -1,
        marginBottom: 30,
      };
    if (archiveType == "category") {
      titleIconStyleMweb = {
        width: 160,
        height: 100,
        position: "absolute",
        left: "45%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "company") {
      titleIconStyleMweb = {
        width: 160,
        height: 100,
        position: "absolute",
        left: "45%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "tag") {
      titleIconStyleMweb = {
        width: 160,
        height: 120,
        position: "absolute",
        left: "38%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "theme") {
      titleIconStyleMweb = {
        width: 160,
        height: 100,
        position: "absolute",
        left: "45%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "category-archive") {
      titleIconStyleMweb = {
        width: 160,
        height: 100,
        position: "absolute",
        left: "45%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    } else if (archiveType == "archive") {
      titleIconStyleMweb = {
        width: 160,
        height: 100,
        position: "absolute",
        left: "45%",
        top: -15,
        zIndex: -1,
        marginBottom: 30,
      };
    }
    return titleIconStyleMweb;
  };

  getSeoPage = () => {
    const { archiveType } = this.state;
    let pageTitle = "/all-stories";
    if (archiveType == "category") {
      pageTitle = "/category/:archive_slug/all";
    } else if (archiveType == "company") {
      pageTitle = "/company/:archive_slug";
    } else if (archiveType == "tag") {
      pageTitle = "/tag/:archive_slug";
    } else if (archiveType == "theme") {
      pageTitle = "/theme/:archive_slug";
    } else if (archiveType == "category-archive") {
      pageTitle = "/category/:archive_slug/archive";
    } else if (archiveType == "archive") {
      pageTitle = "/archive";
    }
    return pageTitle;
  };

  getSeoObject = () => {
    const { archiveType, archiveSlug, archiveData } = this.state;
    if (archiveType == "all") {
      return seoObj["archive-all"];
    } else if (archiveData) {
      return seoObj.getListingPageSeo(archiveType, archiveSlug, archiveData);
    }
    return {};
  };

  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[article.headerWrapper, Margin.mt_4]}>
        <PageCover
          title={this.getPageTitle()}
          pageCoverOverlay={true}
          topRightContent={this.renderPageCoverRightSection()}
          darkMode={darkMode}
        />
        {this.renderSearchField()}
        <CustomImage
          source={this.getTitleIcon()}
          require={true}
          style={this.getTitleIconStyleMweb()}
          webStyle={this.getTitleIconStyle()}
          altText={this.getPageTitle()}
        />
      </View>
    );
  };

  renderSearchField = () => {
    let { searchText, checkBoxChecked, userLoggedin } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClickCheckBox;

    if (helpers.isWeb()) {
      btnClickCheckBox = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.setState(
            {
              checkBoxChecked: !checkBoxChecked,
            },
            () => {
              this.getArticles(1);
              if (helpers.isWeb()) {
                let pushProp = JSON.parse(JSON.stringify(window.location));
                let searchArr = window.location.search
                  .replace("?", "")
                  .split("&");
                if (
                  searchArr.length > 0 &&
                  searchArr[0] != "" &&
                  searchArr[0].startsWith("?search")
                ) {
                  pushProp.search += "&search=" + value;
                } else {
                  if (!this.state.checkBoxChecked) {
                    pushProp.search = this.state.searchText
                      ? "?search=" +
                        this.state.searchText +
                        "&exclude_read=true"
                      : "?exclude_read=true";
                  } else {
                    pushProp.search = this.state.searchText
                      ? "?search=" + this.state.searchText
                      : "";
                  }
                }
                this.props.history.replace(pushProp);
              }
            }
          );
        },
      };
    } else {
      btnClickCheckBox = {
        onPress: (event) => {
          event.stopPropagation();
          this.setState(
            {
              checkBoxChecked: !checkBoxChecked,
            },
            () => this.getArticles(1)
          );
        },
      };
    }

    return (
      <View
        style={[
          helpers.isDesktop() && Flex.row,
          !helpers.isDesktop() && Flex.column,
          Flex.justfiyCenter,
          helpers.isDesktop() && Margin.mb_7,
          !helpers.isDesktop() && Margin.mb_4,
          !helpers.isDesktop() && Margin.mt_3,
          { alignItems: helpers.isDesktop() ? "baseline" : "flex-start" },
          !helpers.isDesktop() && { paddingHorizontal: 18 },
        ]}
      >
        <View
          style={[
            { position: "relative" },
            !helpers.isDesktop() && { width: "100%" },
          ]}
        >
          <TextInput
            placeholder={`Search for ${this.getPageTitle(true, true)} stories`}
            placeholderTextColor={Colors.placeholderTextColor}
            value={searchText}
            onChangeText={(search) => {
              this.searchFieldOnChange(search);
            }}
            onBlur={this.handleInputBlur}
            style={[
              Padding.pl_2,
              {
                borderWidth: 1,
                borderColor: "#907CFF",
                height: helpers.isDesktop() ? 46 : 40,
                width: helpers.isDesktop() ? 500 : "100%",
                paddingRight: 60,
                fontSize: helpers.isDesktop() ? 16 : 14,
                fontFamily: FontFamily.regular,
                color: darkMode ? Colors.lightWhite : "#000",
              },
            ]}
          />
          <View style={[formStyle.searchBox]}>
            <CustomImage
              source={searchBox}
              require={true}
              style={{
                width: helpers.isDesktop() ? 50 : 45,
                height: helpers.isDesktop() ? 46 : 40,
                position: "absolute",
                right: 0,
                top: helpers.isDesktop() ? -46 : -40,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 50 : 45,
                height: helpers.isDesktop() ? 46 : 40,
                position: "absolute",
                right: 0,
                top: helpers.isDesktop() ? -46 : -40,
              }}
            />
          </View>
        </View>
        {userLoggedin && (
          <View
            style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Margin.mt_2]}
          >
            <TouchableOpacity
              {...btnClickCheckBox}
              style={[helpers.isDesktop() && Padding.pl_4, Padding.pr_1]}
            >
              <CustomImage
                source={checkBoxChecked ? checkBoxSelected : checkBoxUnSelected}
                require={true}
                style={{
                  width: helpers.isDesktop() ? 20 : 16,
                  height: helpers.isDesktop() ? 20 : 16,
                }}
                webStyle={{
                  width: helpers.isDesktop() ? 20 : 16,
                  height: helpers.isDesktop() ? 20 : 16,
                }}
              />
            </TouchableOpacity>
            <Text
              style={{
                color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                fontSize: helpers.isDesktop() ? 15 : 14,
                lineHeight: helpers.isDesktop() ? 21 : 17,
                fontFamily: FontFamily.regular,
              }}
            >
              {"Include Read Stories"}
            </Text>
          </View>
        )}
      </View>
    );
  };

  trackCateoryClick = (item) => {
    // try {
    //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: `${this.getPageTitle(true, false)} listing`, section_value: { title: item.name, slug: item.slug } });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  goToSignUp = (email) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign me up",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "NA",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        this.state.redirectSlugs.web +
        "&providerHint=tmc-passwordless" +
        (email ? "&emailHint=" + email : "")
    );
  };
  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with Google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        this.state.redirectSlugs.web +
        "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with Google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        this.state.redirectSlugs.web +
        "&providerHint=google"
    );
  };

  renderPageContent = () => {
    let { archiveDataList, archiveData, bookmarkChecked, data, searchText } =
      this.state;
    const { userStoryAction } = this.props;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClickCategory, btnClickBookmark;

    // console.log('archive:', archiveDataList);

    let items = archiveDataList.map((item, index) => {
      if (helpers.isWeb()) {
        btnClickCategory = {
          to: "/category/" + item.category.slug,
        };
        btnClickBookmark = {
          onPress: (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.applyStoryActions(
              Number(item.id),
              "bookmark",
              item.is_bookmarked ? false : true,
              index
            );
          },
        };
      } else {
        btnClickCategory = {
          onPress: () => this.viewCategory(item.category.slug),
        };
        btnClickBookmark = {
          onPress: (event) => {
            event.stopPropagation();
            this.applyStoryActions(
              Number(item.id),
              "bookmark",
              item.is_bookmarked ? false : true,
              index
            );
          },
        };
      }
      return (
        <View
          style={[
            articleItem.col6,
            Flex.row,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          <View style={Flex.fill}>
            {/* {item.newsletter
                            ?
                            <NewsletterCategory newsletter={item.newsletter} getNewsletterClick={(slug) => this.navigateToNewsletter(slug)} darkMode={darkMode} />
                            :
                            <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)} style={{ width: helpers.isWeb() ? 'fit-content' : '100%' }}>
                                <View>
                                    <Text style={[articleLists.badge, { width: 'auto', marginBottom: 8, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff', fontSize: helpers.isDesktop() ? 16 : 14 }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.darkBlue }]}>{item.category.name}</Text>
                                </View>
                            </TouchableOpacityLink>
                        } */}
            {item.newsletter ? (
              <NewsletterCategory
                newsletter={item.newsletter}
                darkMode={darkMode}
                title={"allstories"}
              />
            ) : (
              <></>
            )}
            <ArticleItemHorizontal
              viewArticle={(catSlug, slug, postData) =>
                this.viewArticle(catSlug, slug, postData)
              }
              viewAuthor={(slug) => this.viewAuthor(slug)}
              viewCategory={(slug) => this.viewCategory(slug)}
              getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
              data={item}
              single={index == 0 ? true : false}
              archive={true}
              listingStory={true}
              darkMode={darkMode}
              hideBookMark={helpers.isWeb() ? true : false}
              newsletter={item?.newsletter ? true : false}
              title={"allstories"}
              sectionName="All Stories"
            />
          </View>
          <View
            style={[
              Margin.mt_3,
              !helpers.isWeb() && { marginTop: 20, paddingTop: 10 },
              Platform.OS == "ios" && { marginTop: 15 },
              helpers.isWeb() && { marginLeft: -10, marginTop: -2 },
            ]}
          >
            <TouchableOpacity {...btnClickBookmark}>
              <View
                style={[
                  Platform.OS == "ios" && {
                    paddingHorizontal: 5,
                    paddingVertical: 5,
                  },
                ]}
              >
                <CustomImage
                  source={
                    item.is_bookmarked
                      ? bookmarkIconSelected
                      : darkMode
                      ? bookmarkIconLight
                      : bookmarkIcon
                  }
                  require={true}
                  style={{ width: 20, height: 20 }}
                  webStyle={{
                    width: helpers.isDesktop() ? 26 : 21,
                    height: helpers.isDesktop() ? 25 : "auto",
                    marginLeft: -5,
                    marginRight: -5,
                  }}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    });
    return (
      <View style={[Padding.ph_2, Margin.mb_3]}>
        {archiveDataList.length > 0 ? (
          <InfiniteScrollComponent
            pageStart={archiveData.page.current}
            loadMore={() => this.getMoreArticles()}
            hasMore={archiveData.page.has_next}
            items={items}
          />
        ) : (
          <View style={[Flex.alignCenter, Margin.mb_6]}>
            <CustomImage
              source={noArticles}
              require={true}
              style={{
                width: helpers.isDesktop() ? 360 : 200,
                height: helpers.isDesktop() ? 360 : 200,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 360 : 200,
                height: helpers.isDesktop() ? 360 : 200,
              }}
              altText={"Match not found"}
            />
            <Text
              style={{
                fontSize: helpers.isDesktop() ? 36 : 24,
                lineHeight: 43,
                marginTop: 20,
                marginBottom: 20,
                textAlign: "center",
                fontFamily: FontFamily.medium,
                color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.9)",
              }}
            >
              Whoops!
            </Text>
            <Text
              style={{
                fontSize: helpers.isDesktop() ? 24 : 16,
                lineHeight: helpers.isDesktop() ? 30 : 24,
                marginBottom: 10,
                textAlign: "center",
                fontFamily: FontFamily.regular,
                color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
              }}
            >
              We couldn’t find a match for "{this.state.searchText}"
            </Text>
            <Text
              style={{
                fontSize: helpers.isDesktop() ? 24 : 16,
                lineHeight: helpers.isDesktop() ? 30 : 24,
                marginBottom: 10,
                textAlign: "center",
                fontFamily: FontFamily.regular,
                color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
              }}
            >
              Please try another search.
            </Text>
          </View>
        )}
      </View>
    );
  };

  renderWebPage = () => {
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && Margin.mt_7,
        ]}
      >
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </View>
    );
  };

  renderAppPage = () => {
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </ScrollView>
    );
  };

  render() {
    const {
      pageLoader,
      showErrorModal,
      footerData,
      redirectSlugs,
      archiveData,
      archiveSlug,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    console.log("Breadcrumbs: ", Breadcrumbs)
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={this.getSeoObject()}
          page={this.getSeoPage()}
          pageData={{ pageTitle: this.getPageTitle(), pageSlug: archiveSlug }}
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          allStories={this.state.archiveType == "all" ? true : false}
          setSearchText={(text) => this.setSearchText(text)}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
              ? this.renderWebPage()
              : this.renderAppPage()}
          </>
        )}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken, refreshScreen }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Archive));

let Breadcrumbs = [];
